import React, { memo, useState, useContext, useEffect } from "react";
import { Box, Button, Divider } from "@mui/material";
import Tabla from "./components/Tabla";

import ModalB from "./components/ModalB";
import BarraTablas from "../../components/BarraTablas/BarraTablas";
import AuthContext from "../../Context/Auth/AuthContext";
import EncuestasEvaluacionesContext from "../../Context/EncuestasEvaluaciones/EncuestasEvaluacionesContext";
import { Add } from "@mui/icons-material";

const Registros = () => {
  const [perfil, setperfil] = useState("ENCUESTAS");
  const [subEstado, setSubEstado] = useState("GUARDIA");
  const [buscando, setbuscando] = useState(false);
  const [buscados, setbuscados] = useState([]);
  const [search, setsearch] = useState("");
  const [cargando, setcargando] = useState(false);
  const [agregar, setagregar] = useState(false);
  const [input, setinput] = useState({
    id: "encId",
    label: "ID",
    minWidth: 150,
    tipo: "string",
    profundidad: 1,
    campo1: "encId",
    campo2: "",
    campo3: "",
  });
  const [orden, setorden] = useState(false);
  const [openMB, setopenMB] = useState(false);
  const [fullBuscador, setfullBuscador] = useState({
    encFecReg1: "",
    encFecReg2: "",
  });
  const {
    usuario: { rucempresa },
  } = useContext(AuthContext);
  //pagina actual
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // input
  //
  const handleChange = (e) => {
    setfullBuscador({ ...fullBuscador, [e.target.name]: e.target.value });
  };
  const funcionB = async () => {
    setcargando(true);
    await remplazarRows(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      perfil,
      "padre",
      "",
      subEstado
    );
    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };
  const { rows, columns, remplazarRows } = useContext(
    EncuestasEvaluacionesContext
  );

  useEffect(() => {
    if (rows && buscando) {
      const _ids = buscados.map((item) => item._id);

      const res = rows.filter((item) => {
        return _ids.indexOf(item._id) >= 0;
      });
      setbuscados(res);
    } // eslint-disable-next-line
  }, [rows]);

  const handleSearch = async () => {
    setcargando(true);
    setPage(0);
    await remplazarRows(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      perfil,
      "padre",
      "",
      subEstado
    );
    setcargando(false);
  };
  const funcionDefault = async () => {
    setcargando(true);
    setfullBuscador({
      encFecReg1: "",
      encFecReg2: "",
    });
    await remplazarRows(
      rowsPerPage,
      0,
      "",
      input.id,
      orden,
      JSON.stringify({
        encFecReg1: "",
        encFecReg2: "",
      }),
      rucempresa,
      perfil,
      "padre",
      "",
      subEstado
    );
    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };
  return (
    <Box>
      <BarraTablas
        search={search}
        setsearch={setsearch}
        funcion={handleSearch}
        buscando={buscando}
        setbuscando={setbuscando}
        LabelBuscador="Buscar por todos los campos"
        funcionDefault={funcionDefault}
        botones={[
          {
            tooltip: "Agregar",
            texto: "Agregar",
            funcion: () => {
              setagregar(true);
            },
            disabled: false,
            Icon: Add,
            color: "primary",
            id: 2,
            ocultar: true,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
        ocultarMigasDePan={false}
      />
      <ModalB
        openMB={openMB}
        setopenMB={setopenMB}
        titulo={"Busqueda avanzada (Registros)"}
        columns={columns}
        fullBuscador={fullBuscador}
        setfullBuscador={setfullBuscador}
        handleChange={handleChange}
        funcionB={funcionB}
        buscando={buscando}
        setbuscando={setbuscando}
        funcionDefault={funcionDefault}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems=" stretch"
        width="100%"
      >
        <Box>
          <Button
            size="small"
            variant={perfil === "ENCUESTAS" ? "contained" : null}
            onClick={async () => {
              setcargando(true);
              await remplazarRows(
                rowsPerPage,
                0,
                "",
                input.id,
                orden,
                JSON.stringify({
                  encFecReg1: "",
                  encFecReg2: "",
                }),
                rucempresa,
                "ENCUESTAS",
                "padre",
                "",
                subEstado
              );
              setfullBuscador({
                encFecReg1: "",
                encFecReg2: "",
              });
              setPage(0);
              setperfil("ENCUESTAS");
              setcargando(false);
            }}
            disableElevation
            style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
            color="primary"
          >
            ENCUESTAS
          </Button>{" "}
          <Button
            size="small"
            variant={perfil === "EVALUACIONES" ? "contained" : null}
            onClick={async () => {
              setcargando(true);
              await remplazarRows(
                rowsPerPage,
                0,
                "",
                input.id,
                orden,
                JSON.stringify({
                  encFecReg1: "",
                  encFecReg2: "",
                }),
                rucempresa,
                "EVALUACIONES",
                "padre",
                "",
                subEstado
              );
              setfullBuscador({
                encFecReg1: "",
                encFecReg2: "",
              });
              setPage(0);
              setperfil("EVALUACIONES");
              setcargando(false);
            }}
            disableElevation
            style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
            color="primary"
          >
            EVALUACIONES
          </Button>
        </Box>{" "}
        <Box>
          <Button
            size="small"
            variant={subEstado === "GUARDIA" ? "contained" : null}
            onClick={async () => {
              setcargando(true);
              await remplazarRows(
                rowsPerPage,
                0,
                "",
                input.id,
                orden,
                JSON.stringify({
                  encFecReg1: "",
                  encFecReg2: "",
                }),
                rucempresa,
                perfil,
                "padre",
                "",
                "GUARDIA"
              );
              setfullBuscador({
                encFecReg1: "",
                encFecReg2: "",
              });
              setPage(0);
              setSubEstado("GUARDIA");
              setcargando(false);
            }}
            disableElevation
            style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
            color="secondary"
          >
            GUARDIA
          </Button>
          <Button
            size="small"
            variant={subEstado === "SUPERVISOR" ? "contained" : null}
            onClick={async () => {
              setcargando(true);
              await remplazarRows(
                rowsPerPage,
                0,
                "",
                input.id,
                orden,
                JSON.stringify({
                  encFecReg1: "",
                  encFecReg2: "",
                }),
                rucempresa,
                perfil,
                "padre",
                "",
                "SUPERVISOR"
              );
              setfullBuscador({
                encFecReg1: "",
                encFecReg2: "",
              });
              setPage(0);
              setSubEstado("SUPERVISOR");
              setcargando(false);
            }}
            disableElevation
            style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
            color="secondary"
          >
            SUPERVISOR
          </Button>
          <Button
            size="small"
            variant={subEstado === "ADMINISTRACION" ? "contained" : null}
            onClick={async () => {
              setcargando(true);
              await remplazarRows(
                rowsPerPage,
                0,
                "",
                input.id,
                orden,
                JSON.stringify({
                  encFecReg1: "",
                  encFecReg2: "",
                }),
                rucempresa,
                perfil,
                "padre",
                "",
                "ADMINISTRACION"
              );
              setfullBuscador({
                encFecReg1: "",
                encFecReg2: "",
              });
              setPage(0);
              setSubEstado("ADMINISTRACION");
              setcargando(false);
            }}
            disableElevation
            style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
            color="secondary"
          >
            ADMINISTRACION
          </Button>
          <Button
            size="small"
            variant={subEstado === "CLIENTE" ? "contained" : null}
            onClick={async () => {
              setcargando(true);
              await remplazarRows(
                rowsPerPage,
                0,
                "",
                input.id,
                orden,
                JSON.stringify({
                  encFecReg1: "",
                  encFecReg2: "",
                }),
                rucempresa,
                perfil,
                "padre",
                "",
                "CLIENTE"
              );
              setfullBuscador({
                encFecReg1: "",
                encFecReg2: "",
              });
              setPage(0);
              setSubEstado("CLIENTE");
              setcargando(false);
            }}
            disableElevation
            style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
            color="secondary"
          >
            CLIENTE
          </Button>
          <Button
            size="small"
            variant={subEstado === "PROVEEDOR" ? "contained" : null}
            onClick={async () => {
              setcargando(true);
              await remplazarRows(
                rowsPerPage,
                0,
                "",
                input.id,
                orden,
                JSON.stringify({
                  encFecReg1: "",
                  encFecReg2: "",
                }),
                rucempresa,
                perfil,
                "padre",
                "",
                "PROVEEDOR"
              );
              setfullBuscador({
                encFecReg1: "",
                encFecReg2: "",
              });
              setPage(0);
              setSubEstado("PROVEEDOR");
              setcargando(false);
            }}
            disableElevation
            style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
            color="secondary"
          >
            PROVEEDOR
          </Button>
        </Box>
      </Box>

      <Divider />
      <Tabla
        perfil={perfil}
        subEstado={subEstado}
        agregar={agregar}
        setagregar={setagregar}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rows={rows}
        search={search}
        input={input}
        orden={orden}
        setinput={setinput}
        setorden={setorden}
        cargando={cargando}
        setcargando={setcargando}
        fullBuscador={fullBuscador}
      />
    </Box>
  );
};

export default memo(Registros);

import React, { useState, useContext, useEffect, memo, useMemo } from "react";

import Draggable from "react-draggable";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import {
  FechaExacta,
  replaceCaracteres,
  trimPropsItem,
} from "../../../config/const";

// MATERIAL
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Divider,
  Button,
  TextField,
  Autocomplete,
  FormControl,
  Tooltip,
  MenuItem,
} from "@mui/material";
import {
  objectDefaultDistribucion,
  objectDefaultDevolucion,
  objectDefaultPedido,
  objectDefaultBienesCliente,
} from "../data";
import { useModalStyle } from "../../styles/styleModal";
import { AddCircle, Cancel, Create, Delete } from "@mui/icons-material";
import clienteAxios from "../../../config/axios";
import ListPersonas from "../../components/ListPersonas";
import AuthContext from "../../../Context/Auth/AuthContext";
import TablaVestimenta from "./RowsVestimenta/TablaVestimenta";
import TablaArmas from "./RowsArmas/TablaArmas";
import TablaMunicion from "./RowMunicion/TablaMunicion";
import CampoDoc from "../../components/CampoDoc";
import { tienePropiedadVerdadera } from "../../functions/objetos/validacionDePropiedades";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalDistribucion = (props) => {
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  //hook de estilos
  const clases = useModalStyle();
  const [modalStyle] = useState(getModalStyle);
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
    ver,
    setver,
    estadoRequest,
    seteditarAnticipo,
    setopenAnticipo,
    ObjectoActivoAnticipo,
    changeObjetoActivoStateAnticipo,
  } = props;
  const [item, setitem] = useState(ObjectoActivo);
  const {
    // distri_PuestoCliente,
    distri_TipoDotacion,
    // distri_Id: 192,
    // distri_Tipo,
    // distri_IdCliente,
    distri_DocuCliente,
    distri_NombreCliente,
    distri_Personas,
    disSupervisores,
    disAdministracion,
    distri_Entrega,
    distri_Observacion,
    disEstado,
    disFechaEnvio,
    disDetalle,
    disFechaRecibido,
    // disPedidos,
    // distri_User,
    // //disEmpresa,
    // distri_FecReg,
    // Todos,
    // disDocumento,
    distri_Fecha,
    disVestimenta,
    disArmas,
    disMuniciones,
    distri_UbicacionCliente,
    distri_PuestoCliente,
    distri_Responsable,
  } = item;
  // eslint-disable-next-line

  const [error, seterror] = useState({});
  const [cargando, setcargando] = useState(false);
  const [clientesData, setclientesData] = useState(null);
  const [puestosData, setpuestosData] = useState([]);
  const [personasData, setpersonasData] = useState([]);
  const [supervisoresData, setsupervisoresData] = useState(null);
  const [administracionData, setadministracionData] = useState(null);
  const [bodegaData, setbodegaData] = useState(null);

  const [subSeccion, setsubSeccion] = useState({
    vestimenta: true,
    armas: false,
    municion: false,
    guardias: false,
    devolucion: false,
    supervisores: false,
    administracion: false,
  });
  const {
    vestimenta,
    armas,
    municion,
    guardias,
    // devolucion,
    supervisores,
    administracion,
  } = subSeccion;
  const reducer = (previousValue, currentValue) =>
    Number(previousValue) + Number(currentValue);

  const valorTotal = useMemo(() => {
    console.log({ distri_productos: item });
    if (item.distri_productos.length === 0) {
      return 0;
    }
    const resultado = item.distri_productos
      .map((row) => {
        return Number(row.prodPrecioUnitario) * row.cantidad;
      })
      .reduce(reducer);
    return resultado.toFixed(2);
  }, [item.distri_productos]);
  const setitemsPersonas = (guardias) => {
    return guardias.map((itemGuardia) => {
      return {
        perFoto: itemGuardia.perFoto,
        perApellidos: itemGuardia.perApellidos,
        perNombres: itemGuardia.perNombres,
        perDocNumero: itemGuardia.perDocNumero,
        perId: itemGuardia.perId,
      };
    });
  };
  const defaultActive = (eliminar) => {
    setsubSeccion({
      vestimenta: true,
      armas: false,
      municion: false,
      guardias: false,
      devolucion: false,
      supervisores: false,
      administracion: false,
    });
    if (item.distri_Tipo === "PEDIDO") {
      changeObjetoActivoState(objectDefaultPedido);
    } else if (item.distri_Tipo === "DISTRIBUCION") {
      changeObjetoActivoState(objectDefaultDistribucion);
    } else if (item.distri_Tipo === "BIENES CLIENTE") {
      changeObjetoActivoState(objectDefaultBienesCliente);
    } else if (item.distri_Tipo === "DEVOLUCION") {
      changeObjetoActivoState(objectDefaultDevolucion);
    }
    setopen(false);
    seteditar(false);
    setver(false);
    seterror({});
    setcargando(false);
    // setclientesData([]);
    setpuestosData([]);
    setpersonasData([]);
    if (ItemsOtroLugar.length > 0 && eliminar && !editar) {
      try {
        socket.current.emit("client:eliminarDataSeleccionado", {
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "anticipo",
          lista: { sinPadre: ItemsOtroLugar.map((item) => item.antId) },
        });
      } catch (error) {}
    }
    setItemsOtroLugar([]);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //definiendo los estilos del modal
  const validacion = (sinDescuento) => {
    if (
      item.distri_Tipo === "DISTRIBUCION" ||
      item.distri_Tipo === "BIENES CLIENTE"
    ) {
      if (
        distri_DocuCliente.trim() === "" ||
        distri_Fecha.trim() === "" ||
        distri_Entrega.trim() === "" ||
        distri_Personas.length === 0 ||
        distri_PuestoCliente.trim() === "" ||
        (distri_Entrega === "DESCUENTO" &&
          ItemsOtroLugar.length === 0 &&
          !sinDescuento) ||
        item.distri_productos.length === 0
      ) {
        const error = {
          distri_DocuCliente: distri_DocuCliente.trim() === "" ? true : false,
          distri_Fecha: distri_Fecha.trim() === "" ? true : false,
          distri_Entrega:
            distri_Entrega.trim() === "" ||
            (distri_Entrega === "DESCUENTO" &&
              ItemsOtroLugar.length === 0 &&
              !sinDescuento)
              ? true
              : false,
          distri_Personas: distri_Personas.length === 0 ? true : false,

          distri_PuestoCliente: distri_PuestoCliente.trim() === "",
          distri_productos: item.distri_productos.length === 0,
        };
        seterror(error);
        return true;
      } else {
        return false;
      }
    }
    // if (item.distri_Tipo ==="PEDIDO") {
    //   const errorValidacion = {};

    //   errorValidacion.distri_Entrega = distri_Entrega.trim() === "";
    //   errorValidacion.distri_DocuCliente = distri_DocuCliente.trim() === "";
    //   errorValidacion.distri_Personas = distri_Personas.length === 0;

    //   errorValidacion.distri_PuestoCliente = distri_PuestoCliente.trim() === "";
    //   const tieneError = tienePropiedadVerdadera(errorValidacion);
    //   if (tieneError) {
    //     mostrarAlerta("Faltan campos obligatorios", "error");
    //     seterror(errorValidacion);
    //   }

    //   return tieneError;
    // }
    return true;
  };
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }

    try {
      setcargando(true);
      console.log(ItemsOtroLugar);

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          distri_Personas: distri_Personas.map((item) => ({
            ...item,
            asig_Id: 0,
          })),
          tabla: "distribuciones",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          distri_User: usuario.usuario,
          // distri_Empresa: usuario.rucempresa,
          distri_IdsDescuento: ItemsOtroLugar.map(
            (itemTurno) => itemTurno.antId
          ),
        })
      );
      return defaultActive();
    } catch (error) {
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }

    try {
      setcargando(true);

      return defaultActive();
    } catch (error) {
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  useEffect(() => {
    const getDataclientesData = async () => {
      const resclientesData = await clienteAxios.get(
        "/clientes/datos/principales-y-operativos"
      );

      setclientesData(resclientesData.data.data);
    };

    if (!clientesData) {
      getDataclientesData();
    }
    const getDataadministracionData = async () => {
      const resadministracionData = await clienteAxios.get(
        "/personas/datos-principales/byPerfil/ADMINISTRACION"
      );

      setadministracionData(setitemsPersonas(resadministracionData.data.data));
    };
    if (!administracionData) {
      getDataadministracionData();
    }
    const getDatasupervisoresData = async () => {
      const ressupervisoresData = await clienteAxios.get(
        "/personas/datos-principales/byPerfil/SUPERVISORES"
      );

      setsupervisoresData(setitemsPersonas(ressupervisoresData.data.data));
    };
    if (!supervisoresData) {
      getDatasupervisoresData();
    }
    const getbodegaData = async () => {
      const resbodegaData = await clienteAxios.get(
        `/bodegas/listarAllByEstado/ACTIVA`
      );

      setbodegaData(resbodegaData.data);
    };

    if (!bodegaData) {
      getbodegaData();
    }
    if (open && ObjectoActivo.distri_DocuCliente !== "") {
      const getresguardiasData = async () => {
        try {
          // const itemF = await clienteAxios.get(
          //   `/clientes/filtro/0?search=${ObjectoActivo.distri_DocuCliente}&estado=CLIENTES`
          // );
          const res = await clienteAxios.get(
            `/personas/listado/:codigo?docnumero=${ObjectoActivo.distri_DocuCliente}`
          );
          setpersonasData(setitemsPersonas(res.data.data));
        } catch (error) {
          setpersonasData([]);
        }
      };

      getresguardiasData();
      const getItemsOtroLugar = async () => {
        try {
          // const itemF = await clienteAxios.get(
          //   `/clientes/filtro/0?search=${ObjectoActivo.distri_DocuCliente}&estado=CLIENTES`
          // );
          const resItemsOtroLugar = await clienteAxios.get(
            `anticipos/byid?lista=${ObjectoActivo.disIdDescuento.join(",")}`
          );
          setItemsOtroLugar(resItemsOtroLugar.data.data);
        } catch (error) {
          setItemsOtroLugar([]);
        }
      };

      getItemsOtroLugar();
    }
    setitem({
      ...ObjectoActivo,
      distri_EstadoDevolucion: ObjectoActivo.distri_Tipo === "DEVOLUCION",
    });

    // eslint-disable-next-line
  }, [open, ObjectoActivo]);
  const [ItemsOtroLugar, setItemsOtroLugar] = useState([]);
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });

  useEffect(() => {
    socket.current.on("server:guardadoExitoso", (data) => {
      setitemSocket({
        tipo: "agregar",
        item: data,
      });
      socket.current.on("server:actualizadoExitoso", (data) => {
        setitemSocket({
          tipo: "editar",
          item: data,
        });
      });
    });

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const { item, tipo } = itemSocket;
    if (!open) {
      return;
    }
    if (tipo === "") {
      return;
    }
    if (usuario.usuario !== item.antUser) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }
    if (item.tabla !== "anticipo") {
      return;
    }
    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (tipo === "agregar") {
      setItemsOtroLugar(item.result);
    }
    if (tipo === "editar") {
      setItemsOtroLugar(
        ItemsOtroLugar.map((itemOtroLugar) => {
          if (itemOtroLugar.antId === item.antId) {
            return item;
          } else {
            return itemOtroLugar;
          }
        })
      );
    }
    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);
  if (!open) {
    return null;
  }
  if (!clientesData) {
    return null;
  }
  if (!supervisoresData) {
    return null;
  }
  if (!administracionData) {
    return null;
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#modalDistribucion">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="modalDistribucion"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {ver ? "Ver" : editar ? "Editar" : "Agregar"}{" "}
                  {item.distri_Tipo}.
                </Typography>
                {editar &&
                  (item.distri_Tipo === "DISTRIBUCION" ||
                    item.distri_Tipo === "BIENES CLIENTE") && (
                    <Typography
                      variant="body2"
                      color="error"
                      component={"span"}
                    >
                      No se puede EDITAR, tiene que eliminar y generar uno
                      nuevo.
                    </Typography>
                  )}
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive(true);
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" center"
                width="100%"
              >
                <Box></Box>
              </Box>
              <Divider />
              <Box className={clases.container}>
                {item.distri_Tipo === "DISTRIBUCION" ||
                item.distri_Tipo === "BIENES CLIENTE" ? (
                  <>
                    {ItemsOtroLugar.map((itemOtroLugar) => {
                      return (
                        <Box
                          display={"flex"}
                          justifyContent="space-between"
                          alignItems={"center"}
                          className={clases.formD}
                        >
                          <Typography
                            variant="body2"
                            color="secondary"
                            fontWeight={"bold"}
                          >
                            {itemOtroLugar.antPeriodo} {" - "}
                            <Typography
                              variant="body2"
                              color="primary"
                              component={"span"}
                            >
                              {itemOtroLugar.antNomPersona}
                              {" - "}
                              {itemOtroLugar.antValor}
                            </Typography>
                          </Typography>
                          <Box display={"flex"} alignItems="center">
                            {" "}
                            <IconButton
                              aria-label=""
                              style={{ margin: "0px" }}
                              onClick={() => {
                                changeObjetoActivoStateAnticipo(itemOtroLugar);
                                seteditarAnticipo(true);
                                setopenAnticipo(true);
                              }}
                            >
                              <Create color="secondary" />
                            </IconButton>
                            <IconButton
                              disabled={editar}
                              aria-label=""
                              style={{ margin: "0px" }}
                              onClick={() => {
                                socket.current.emit("client:eliminarData", {
                                  nombre: usuario.nombre,
                                  rucempresa: usuario.rucempresa,
                                  rol: usuario.rol,
                                  antUser: usuario.usuario,
                                  antEmpresa: usuario.rucempresa,
                                  antId: itemOtroLugar["antId"],
                                  antPeriodo: itemOtroLugar.antPeriodo,
                                  antDocuPersona: itemOtroLugar.antDocuPersona,
                                  antTipo: itemOtroLugar.antTipo,
                                  tabla: "anticipo",
                                });
                                setItemsOtroLugar(
                                  ItemsOtroLugar.filter(
                                    (itemEliminar) =>
                                      itemOtroLugar.antId !== itemEliminar.antId
                                  )
                                );
                              }}
                            >
                              <Delete color="error" />
                            </IconButton>
                          </Box>
                        </Box>
                      );
                    })}
                    <Box display="flex" width="100%"></Box>{" "}
                    <FormControl className={clases.formD}>
                      <Autocomplete
                        disabled={editar || item.distri_EstadoDevolucion}
                        // disabled={ver || item.distri_EstadoDevolucion}
                        disableClearable={true}
                        options={clientesData.map((item) => {
                          return (
                            item.cliRazonSocial +
                            "/+/" +
                            item.cliDocNumero +
                            "/+/" +
                            item.cliId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ").split("/+/")[0];
                        }}
                        value={(
                          distri_NombreCliente +
                          " " +
                          distri_DocuCliente
                        ).trim()}
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");

                            try {
                              const res = await clienteAxios.get(
                                `/personas/guardias/byCliente/${options[2]}`
                              );

                              const itemF = clientesData.find(
                                (itemCliente) =>
                                  itemCliente.cliId === Number(options[2])
                              );
                              setpuestosData(itemF.cliDatosOperativos);

                              if (res.data.data.length === 0) {
                                mostrarAlerta(
                                  "Este cliente no tiene guardias",
                                  "error"
                                );
                                seterror({ ...error, distri_Personas: true });
                              }

                              setpersonasData(setitemsPersonas(res.data.data));

                              setitem({
                                ...item,
                                distri_IdCliente: options[2],
                                distri_DocuCliente: options[1],
                                distri_NombreCliente: options[0],
                              });
                            } catch (error) {
                              return mostrarAlerta("No hay personas", "error");
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            fullWidth
                            label="Cliente"
                            error={error.distri_DocuCliente}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      type={"date"}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        min: FechaExacta,
                      }}
                      disabled={editar || item.distri_EstadoDevolucion}
                      // disabled={ver || item.distri_EstadoDevolucion}
                      className={clases.formD}
                      size="small"
                      label="Fecha"
                      value={distri_Fecha}
                      name="distri_Fecha"
                      error={error.distri_Fecha}
                      onChange={(e) => handleChange(e)}
                    />
                    {item.distri_Tipo === "DISTRIBUCION" && (
                      <TextField
                        disabled={editar || item.distri_EstadoDevolucion}
                        // disabled={ver || item.distri_EstadoDevolucion}
                        className={clases.formD}
                        size="small"
                        label="Entrega"
                        value={distri_Entrega}
                        name="distri_Entrega"
                        error={error.distri_Entrega}
                        onChange={(e) => {
                          setitem({
                            ...item,
                            [e.target.name]: e.target.value,
                            distri_Personas:
                              e.target.value === "DESCUENTO"
                                ? []
                                : distri_Personas,
                            // disSupervisores:
                            //   e.target.value === "DESCUENTO"
                            //     ? []
                            //     : disSupervisores,
                            // disAdministracion:
                            //   e.target.value === "DESCUENTO"
                            //     ? []
                            //     : disAdministracion,
                          });
                        }}
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ displayEmpty: true }}
                        InputProps={{
                          endAdornment: (
                            <React.Fragment>
                              {editar ||
                              distri_Entrega === "DOTACIÓN" ? null : (
                                <Tooltip title="Agregar Descuento Extra">
                                  <IconButton
                                    // disabled={
                                    //   validacion() || ItemsOtroLugar.antId
                                    // }
                                    size="small"
                                    onClick={async () => {
                                      try {
                                        // const res = await clienteAxios.get(
                                        //   `/clientes/filtro/0?search=${docuCliente}&estado=CLIENTES`
                                        // );
                                        // if (!res.data.data[0]) {
                                        //   return mostrarAlerta(
                                        //     "Esta persona no tiene cliente",
                                        //     "error"
                                        //   );
                                        // }

                                        // const itemF = res.data.data[0];
                                        if (validacion(true)) {
                                          return mostrarAlerta(
                                            "Necesitas llenar campos para crear un DESCUENTO",
                                            "error"
                                          );
                                        }
                                        const guardia = distri_Personas[0];

                                        changeObjetoActivoStateAnticipo({
                                          ...ObjectoActivoAnticipo,
                                          antIdPersona: guardia.perId,
                                          antDocuPersona: guardia.perDocNumero,
                                          antNomPersona:
                                            guardia.perApellidos +
                                            " " +
                                            guardia.perNombres,
                                          antValor: valorTotal,
                                          antDetalle: item.distri_productos
                                            .map(
                                              (itemInventario) =>
                                                itemInventario.prodNombre
                                            )
                                            .join(", "),
                                        });

                                        setopenAnticipo(true);
                                      } catch (error) {
                                        console.log({ error });

                                        return mostrarAlerta(
                                          "Hubo un error",
                                          "error"
                                        );
                                      }
                                    }}
                                  >
                                    <AddCircle
                                      fontSize="small"
                                      color={"secondary"}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </React.Fragment>
                          ),
                        }}
                      >
                        <MenuItem value="">Seleccione.</MenuItem>
                        {["DOTACIÓN", "DESCUENTO"].map((item, key) => {
                          return (
                            <MenuItem value={item} key={key}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    )}
                    <TextField
                      disabled={editar || item.distri_EstadoDevolucion}
                      // disabled={ver || item.distri_EstadoDevolucion}
                      className={clases.formD}
                      size="small"
                      label="Tipo"
                      value={distri_TipoDotacion}
                      name="distri_TipoDotacion"
                      error={error.distri_TipoDotacion}
                      onChange={(e) => {
                        setitem({
                          ...item,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem disabled value="">
                        Seleccione.
                      </MenuItem>
                      {["PUESTO", "PERSONAL"].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <FormControl className={clases.formD}>
                      <Autocomplete
                        disabled={editar || item.distri_EstadoDevolucion}
                        // disabled={ver || item.distri_EstadoDevolucion}
                        disableClearable={true}
                        options={puestosData.map((item) => {
                          return (
                            item.ubicacion +
                            "/+/" +
                            item.puesto +
                            "/+/" +
                            item.id
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " - ").split("/+/")[0];
                        }}
                        value={
                          `${
                            distri_UbicacionCliente
                              ? distri_UbicacionCliente
                              : ""
                          } ${distri_PuestoCliente}`.trim() === ""
                            ? ""
                            : `${
                                distri_UbicacionCliente
                                  ? distri_UbicacionCliente
                                  : ""
                              } - ${distri_PuestoCliente}`.trim()
                        }
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            try {
                              const [
                                distri_UbicacionCliente,
                                distri_PuestoCliente,
                                id,
                              ] = newValue.split("/+/");

                              setitem({
                                ...item,
                                distri_UbicacionCliente,
                                distri_PuestoCliente,
                                distri_idDatoOperativo: id,
                              });
                            } catch (error) {
                              return mostrarAlerta("No hay personas", "error");
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            label="Puesto"
                            error={error.distri_PuestoCliente}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    {item.distri_Tipo === "BIENES CLIENTE" && (
                      <TextField
                        disabled={editar || item.distri_EstadoDevolucion}
                        // disabled={ver || item.distri_EstadoDevolucion}
                        className={clases.formD}
                        size="small"
                        label="Responsable"
                        value={distri_Responsable}
                        name="distri_Responsable"
                        error={error.distri_Responsable}
                        onChange={(e) => handleChange(e)}
                      />
                    )}
                    <TextField
                      multiline
                      disabled={editar || item.distri_EstadoDevolucion}
                      // disabled={ver || item.distri_EstadoDevolucion}
                      className={clases.formD}
                      size="small"
                      label="Observación"
                      value={distri_Observacion}
                      name="distri_Observacion"
                      error={error.distri_Observacion}
                      onChange={(e) => handleChange(e)}
                    />
                    <>
                      <Box mt={2} width="100%">
                        {error.distri_productos ? (
                          <Typography
                            variant="caption"
                            color="error"
                            fontWeight="bold"
                          >
                            INGRESE AL MENOS UN REGISTRO DE: IMPLEMENTOS, ARMAS
                            O MUNICIÓN.
                          </Typography>
                        ) : null}
                      </Box>
                      <Box mt={1} width="100%">
                        {error.distri_Personas ? (
                          <Typography
                            variant="caption"
                            color="error"
                            fontWeight="bold"
                          >
                            {distri_Entrega === "DESCUENTO"
                              ? `SELECCIONE SOLO A UN GUARDIA, SUPERVISOR ${
                                  item.distri_Tipo !== "BIENES CLIENTE"
                                    ? "O ADMINISTRACION"
                                    : ""
                                }`
                              : `SELECCIONE AL MENOS A UN GUARDIA, SUPERVISOR ${
                                  item.distri_Tipo !== "BIENES CLIENTE"
                                    ? "O ADMINISTRACION"
                                    : ""
                                }`}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems=" center"
                        width="100%"
                      >
                        <Box>
                          <Button
                            size="small"
                            variant={vestimenta ? "contained" : null}
                            onClick={async () => {
                              setsubSeccion({ vestimenta: true });
                            }}
                            disableElevation
                            style={{
                              padding: ".5rem",
                              borderRadius: "15px 15px  0px 0px",
                            }}
                            color="primary"
                          >
                            Implementos
                          </Button>
                          {item.distri_Tipo === "DISTRIBUCION" && (
                            <>
                              {distri_Entrega === "DESCUENTO" ? null : (
                                <Button
                                  size="small"
                                  variant={armas ? "contained" : null}
                                  onClick={async () => {
                                    setsubSeccion({ armas: true });
                                  }}
                                  disableElevation
                                  style={{
                                    padding: ".5rem",
                                    borderRadius: "15px 15px  0px 0px",
                                  }}
                                  color="primary"
                                >
                                  ARMAS
                                </Button>
                              )}
                              <Button
                                size="small"
                                variant={municion ? "contained" : null}
                                onClick={async () => {
                                  setsubSeccion({ municion: true });
                                }}
                                disableElevation
                                style={{
                                  padding: ".5rem",
                                  borderRadius: "15px 15px  0px 0px",
                                }}
                                color="primary"
                              >
                                Munición
                              </Button>
                            </>
                          )}

                          <Button
                            size="small"
                            variant={"contained"}
                            disableFocusRipple={true}
                            disableTouchRipple={true}
                            disableElevation={true}
                            style={{
                              padding: ".5rem",
                              borderRadius: "15px 15px  0px 0px",
                            }}
                            color="success"
                          >
                            V. TOTAL: ${valorTotal}
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            DatosPrincipales
                            size="small"
                            variant={
                              guardias
                                ? "contained"
                                : error.distri_Personas
                                ? "outlined"
                                : null
                            }
                            onClick={async () => {
                              setsubSeccion({ guardias: true });
                            }}
                            disableElevation
                            style={{
                              padding: ".5rem",
                              borderRadius: "15px 15px  0px 0px",
                            }}
                            color={
                              error.distri_Personas ? "error" : "secondary"
                            }
                          >
                            Guardias
                          </Button>
                          <Button
                            size="small"
                            variant={
                              supervisores
                                ? "contained"
                                : error.disSupervisores
                                ? "outlined"
                                : null
                            }
                            onClick={async () => {
                              setsubSeccion({ supervisores: true });
                            }}
                            disableElevation
                            style={{
                              padding: ".5rem",
                              borderRadius: "15px 15px  0px 0px",
                            }}
                            color={
                              error.disSupervisores ? "error" : "secondary"
                            }
                          >
                            Supervisores
                          </Button>
                          {item.distri_Tipo !== "BIENES CLIENTE" && (
                            <Button
                              size="small"
                              variant={
                                administracion
                                  ? "contained"
                                  : error.disAdministracion
                                  ? "outlined"
                                  : null
                              }
                              onClick={async () => {
                                setsubSeccion({ administracion: true });
                              }}
                              disableElevation
                              style={{
                                padding: ".5rem",
                                borderRadius: "15px 15px  0px 0px",
                              }}
                              color={
                                error.disAdministracion ? "error" : "secondary"
                              }
                            >
                              Administracion
                            </Button>
                          )}
                        </Box>
                      </Box>
                      <Divider style={{ margin: "0", width: "100%" }} />{" "}
                      {vestimenta || armas || municion ? (
                        <TablaVestimenta
                          editar={editar}
                          devolucion={item.disDevolucion}
                          setitem={setitem}
                          item={item}
                          bodegaData={bodegaData}
                          vestimenta={vestimenta}
                          armas={armas}
                          municion={municion}
                        />
                      ) : null}
                      {/* {armas ? (
                        <TablaVestimenta
                          ver={ver}
                          devolucion={item.disDevolucion}
                          setitem={setitem}
                          item={item}
                          bodegaData={bodegaData}
                        />
                      ) : null}
                      {municion ? 
                        <TablaVestimenta
                          ver={ver}
                          devolucion={item.disDevolucion}
                          setitem={setitem}
                          item={item}
                          bodegaData={bodegaData}
                        />
                      ) : null} */}
                      {guardias ? (
                        <ListPersonas
                          readOnly={
                            ver || editar || item.distri_EstadoDevolucion
                          }
                          error={error}
                          item={item}
                          setitem={(e) => {
                            console.log(e.distri_Personas);

                            if (distri_Entrega === "DESCUENTO") {
                              if (e.distri_Personas.length === 2) {
                                seterror({
                                  ...error,
                                  distri_Personas: distri_Personas.length === 1,
                                  disSupervisores: distri_Personas.length === 1,
                                  disAdministracion:
                                    distri_Personas.length === 1,
                                });
                                return mostrarAlerta(
                                  "Maximo una persona para DESCUENTOS",
                                  "error"
                                );
                              }
                            }

                            setitem(e);
                          }}
                          data={personasData}
                          propiedad={distri_Personas}
                          propiedadString={"distri_Personas"}
                        />
                      ) : null}
                      {supervisores ? (
                        <ListPersonas
                          readOnly={
                            ver || editar || item.distri_EstadoDevolucion
                          }
                          error={error}
                          item={item}
                          setitem={(e) => {
                            if (distri_Entrega === "DESCUENTO") {
                              if (e.distri_Personas.length === 2) {
                                seterror({
                                  ...error,
                                  distri_Personas: distri_Personas.length === 1,
                                  disSupervisores: distri_Personas.length === 1,
                                  disAdministracion:
                                    distri_Personas.length === 1,
                                });
                                return mostrarAlerta(
                                  "Maximo una persona para DESCUENTOS",
                                  "error"
                                );
                              }
                            }
                            setitem(e);
                          }}
                          data={supervisoresData}
                          propiedad={distri_Personas}
                          propiedadString={"distri_Personas"}
                        />
                      ) : null}
                      {administracion ? (
                        <ListPersonas
                          readOnly={
                            ver || editar || item.distri_EstadoDevolucion
                          }
                          error={error}
                          item={item}
                          setitem={(e) => {
                            if (distri_Entrega === "DESCUENTO") {
                              if (e.distri_Personas.length === 2) {
                                seterror({
                                  ...error,
                                  distri_Personas: distri_Personas.length === 1,
                                  disSupervisores: distri_Personas.length === 1,
                                  disAdministracion:
                                    distri_Personas.length === 1,
                                });
                                return mostrarAlerta(
                                  "Maximo una persona para DESCUENTOS",
                                  "error"
                                );
                              }
                            }
                            setitem(e);
                          }}
                          data={administracionData}
                          propiedad={distri_Personas}
                          propiedadString={"distri_Personas"}
                        />
                      ) : null}
                    </>
                  </>
                ) : null}
              </Box>
              {/* <Divider /> */}
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  style={{ width: "30%" }}
                  variant="contained"
                  color="primary"
                  disabled={
                    cargando ||
                    (editar &&
                      (item.distri_Tipo === "DISTRIBUCION" ||
                        item.distri_Tipo === "BIENES CLIENTE")) ||
                    ver
                  }
                  onClick={() => {
                    if (editar || item.distri_EstadoDevolucion) {
                      handleEditar();
                    } else {
                      handleCrear();
                    }
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalDistribucion);

export const columns = [
  {
    id: "",
    label: "Tipo",
    minWidth: 150,
    tipo: "",
    orden: 1,
    propiedad: "",
    align: "center",
    ocultar: true,
  },
  {
    id: "",
    label: "Producto",
    minWidth: 300,
    tipo: "",
    orden: 1,
    propiedad: "",
    align: "center",
    ocultar: true,
  },
  {
    id: "",
    label: "Talla",
    minWidth: 100,
    tipo: "",
    orden: 1,
    propiedad: "",
    align: "center",
    ocultar: true,
  },
  // {
  //   id: "prodNombre",
  //   label: "Condición",
  //   minWidth: 150,
  //   tipo: "string",
  //   orden: 1,
  //   propiedad: "prodNombre",
  //   align: "center",
  //   ocultar: true,
  // },
  {
    id: "condicion_producto",
    label: "Condición",
    minWidth: 125,
    tipo: "string",
    orden: 1,
    propiedad: "condicion_producto",
    align: "center",
    ocultar: true,
  },
  {
    id: "stock_bodeId",
    label: "Bodega",
    minWidth: 125,
    tipo: "string",
    orden: 1,
    propiedad: "stock_bodeId",
    align: "center",
    ocultar: true,
  },

  {
    id: "stock_total_por_bodega",
    label: "STOCK INGRESO",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "stock_total_por_bodega",
    align: "center",
    ocultar: true,
  },
  // {
  //   id: "maximo",
  //   label: "Max",
  //   minWidth: 75,
  //   tipo: "string",
  //   orden: 1,
  //   propiedad: "maximo",
  //   align: "center",
  //   ocultar: true,
  // },
  // {
  //   id: "minimo",
  //   label: "Min",
  //   minWidth: 75,
  //   tipo: "string",
  //   orden: 1,
  //   propiedad: "minimo",
  //   align: "center",
  //   ocultar: true,
  // },
  {
    id: "stock_disponible",
    label: "Stock Disponible",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "stock_disponible",
    align: "center",
    ocultar: true,
  },
  {
    id: "user",
    label: "User",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "user",
    align: "center",
    ocultar: true,
  },
  {
    id: "fecha_upd",
    label: "Fec. Upd.",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "fecha_upd",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  // id
  prodTipo: "VESTIMENTAS",
  //   VESTIMENTAS
  // ARMAS
  // MUNICIONES
  // EQUIPOS
  talla: "0",
  prodId: 0,
  stock_bodeId: 0,
  condicion_producto: "NUEVO", //"NUEVO", "USADO", "OPERABLE", "BUEN ESTADO", "DEFECTUOSO"
  stock_disponible: 0,
  stock_total_por_bodega: 1,
  maximo: 50,
  minimo: 1,
  prodNombre: "",
  bodeNombre: "",
  fecha_upd: "",
  cod_empresa: "",
  user: "",
};

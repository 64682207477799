import React, { useContext, useState } from "react";
import clienteAxios from "../../../../config/axios";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import CalendarioPlus from "../../../components/CalendarioPlus";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  TextField,
  FormControl,
  InputAdornment,
  Autocomplete,
  Typography,
} from "@mui/material";
import {
  CalendarToday,
  Delete,
  // CloudDownload,
} from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  inputPadding: {
    padding: theme.spacing(0.25),
    margin: theme.spacing(0),
    fontSize: ".85rem",
  },

  form20PC: {
    flexGrow: 1,
    width: "17%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "23%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "30%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
}));
const Turno = (props) => {
  const { turno, item, setitem, index, estadoRequest } = props;
  const clases = useStyles();
  const { mostrarAlerta } = useContext(AlertaContext);
  const [clientesDataIngreso, setclientesDataIngreso] = useState([]);
  const [puestosDataIngreso, setpuestosDataIngreso] = useState([]);
  const [clientesDataSalida, setclientesDataSalida] = useState([]);
  const [puestosDataSalida, setpuestosDataSalida] = useState([]);
  const [openCalendario, setopenCalendario] = useState(false);
  const [focusedDate, setFocusedDate] = useState("");
  return (
    <>
      <Box
        display={"flex"}
        justifyContent="space-between"
        className={clases.formAllW}
        key={turno.id}
      >
        <Typography variant="button" color="primary" fontWeight={"700"}>
          {`Turno #${index + 1}`}
          <IconButton
            size="small"
            onClick={() => {
              setitem({
                ...item,
                perTurno: item.perTurno.filter((item) => {
                  return item.id !== turno.id;
                }),
              });
            }}
          >
            <Delete color="error" />
          </IconButton>
        </Typography>{" "}
      </Box>
      <>
        {/* INGRESO */}
        <FormControl
          inputProps={{ className: clases.inputPadding }}
          className={clases.form20PC}
        >
          <Autocomplete
            disableClearable={true}
            options={clientesDataIngreso.map((item) => {
              return (
                item.cliRazonSocial +
                "/+/" +
                item.cliDocNumero +
                "/+/" +
                item.cliId
              );
            })}
            getOptionLabel={(option) => {
              return option.replace("/+/", " ").split("/+/")[0];
            }}
            id="controlled-demo"
            value={
              (turno.clienteIngreso + " " + turno.docClienteIngreso).trim() ===
              ""
                ? ""
                : (turno.clienteIngreso + " " + turno.docClienteIngreso).trim()
            }
            paperprops
            onChange={async (event, newValue) => {
              if (newValue) {
                const options = newValue.split("/+/");

                const itemF = clientesDataIngreso.find(
                  (itemCliente) => itemCliente.cliId === Number(options[2])
                );
                const puestosRes = itemF.cliDatosOperativos.map(
                  (item) =>
                    item.puesto + "/+/" + item.ubicacion + "/+/" + item.id
                );
                setpuestosDataIngreso(puestosRes);
                if (
                  estadoRequest === "GUARDIAS" &&
                  item.perAreaDepartamento === "OPERATIVO-G"
                ) {
                  setitem({
                    ...item,
                    perTurno: item.perTurno.map((item) => {
                      if (item.id === turno.id) {
                        return {
                          ...item,
                          clienteIngreso: options[0],
                          docClienteIngreso: options[1],
                          idClienteIngreso: options[2],
                          clienteSalida: options[0],
                          docClienteSalida: options[1],
                          idClienteSalida: options[2],
                          puestoIngreso: "",
                          clienteUbicacionIngreso: "",
                        };
                      } else {
                        return item;
                      }
                    }),
                  });
                } else {
                  setitem({
                    ...item,
                    perTurno: item.perTurno.map((item) => {
                      if (item.id === turno.id) {
                        return {
                          ...item,
                          clienteIngreso: options[0],
                          docClienteIngreso: options[1],
                          idClienteIngreso: options[2],
                          puestoIngreso: "",
                          clienteUbicacionIngreso: "",
                        };
                      } else {
                        return item;
                      }
                    }),
                  });
                }
              }
            }}
            renderInput={(params) => (
              <TextField
                inputProps={{ className: clases.inputPadding }}
                {...params}
                size="small"
                required
                fullWidth
                label="Cliente Ingreso"
                onChange={async (e) => {
                  if (e.target.value === "") {
                    return mostrarAlerta("Ingrese datos", "warning");
                  }
                  try {
                    if (e.target.value.length < 3) {
                      return;
                    }
                    const res = await clienteAxios.get(
                      `/clientes/filtrobusqueda/0?search=${e.target.value}`
                    );

                    setclientesDataIngreso(res.data.data);
                  } catch (error) {
                    setitem({
                      ...item,
                    });
                    return mostrarAlerta("No hay clientes", "error");
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </FormControl>
        <FormControl
          inputProps={{ className: clases.inputPadding }}
          className={clases.form20PC}
        >
          <Autocomplete
            disableClearable={true}
            options={puestosDataIngreso.map((item) => {
              return item;
            })}
            getOptionLabel={(option) => {
              return option.replace("/+/", " ").split("/+/")[0];
            }}
            id="controlled-demo"
            value={turno.puestoIngreso}
            paperprops
            onChange={async (event, newValue) => {
              if (newValue) {
                const options = newValue.split("/+/");
                if (
                  estadoRequest === "GUARDIAS" &&
                  item.perAreaDepartamento === "OPERATIVO-G"
                ) {
                  // "idDatoOptIngreso": 1 y "idDatoOptSalida": 1
                  setitem({
                    ...item,
                    perTurno: item.perTurno.map((item, indexItem) => {
                      if (item.id === turno.id) {
                        return {
                          ...item,
                          puestoIngreso: options[0],
                          clienteUbicacionIngreso: options[1],
                          puestoSalida: options[0],
                          clienteUbicacionSalida: options[1],
                          idDatoOptIngreso: options[2],
                          idDatoOptSalida: options[2],
                        };
                      } else {
                        return item;
                      }
                    }),
                  });
                } else {
                  setitem({
                    ...item,
                    perTurno: item.perTurno.map((item, indexItem) => {
                      if (item.id === turno.id) {
                        return {
                          ...item,
                          puestoIngreso: options[0],
                          clienteUbicacionIngreso: options[1],
                          idDatoOptIngreso: options[2],
                        };
                      } else {
                        return item;
                      }
                    }),
                  });
                }
              }
            }}
            renderInput={(params) => (
              <TextField
                inputProps={{ className: clases.inputPadding }}
                {...params}
                size="small"
                required
                fullWidth
                label="Puesto Ingreso"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </FormControl>
        {/* SALIDA */}
        <FormControl
          inputProps={{ className: clases.inputPadding }}
          className={clases.form20PC}
        >
          <Autocomplete
            disabled={
              estadoRequest === "GUARDIAS" &&
              item.perAreaDepartamento === "OPERATIVO-G"
            }
            disableClearable={true}
            options={clientesDataSalida.map((item) => {
              return (
                item.cliRazonSocial +
                "/+/" +
                item.cliDocNumero +
                "/+/" +
                item.cliId
              );
            })}
            getOptionLabel={(option) => {
              return option.replace("/+/", " ").split("/+/")[0];
            }}
            id="controlled-demo"
            value={
              (turno.clienteSalida + " " + turno.docClienteSalida).trim() === ""
                ? ""
                : (turno.clienteSalida + " " + turno.docClienteSalida).trim()
            }
            paperprops
            onChange={async (event, newValue) => {
              if (newValue) {
                const options = newValue.split("/+/");

                const itemF = clientesDataSalida.find(
                  (itemCliente) => itemCliente.cliId === Number(options[2])
                );
                const puestosRes = itemF.cliDatosOperativos.map(
                  (item) =>
                    item.puesto + "/+/" + item.ubicacion + "/+/" + item.id
                );
                setpuestosDataSalida(puestosRes);

                setitem({
                  ...item,
                  perTurno: item.perTurno.map((item) => {
                    if (item.id === turno.id) {
                      return {
                        ...item,
                        clienteSalida: options[0],
                        docClienteSalida: options[1],
                        idClienteSalida: options[2],
                        puestoSalida: "",
                        clienteUbicacionSalida: "",
                      };
                    } else {
                      return item;
                    }
                  }),
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                inputProps={{ className: clases.inputPadding }}
                {...params}
                size="small"
                required
                fullWidth
                label="Cliente Salida"
                onChange={async (e) => {
                  if (e.target.value === "") {
                    return mostrarAlerta("Ingrese datos", "warning");
                  }
                  try {
                    if (e.target.value.length < 3) {
                      return;
                    }
                    const res = await clienteAxios.get(
                      `/clientes/filtrobusqueda/0?search=${e.target.value}`
                    );

                    setclientesDataSalida(res.data.data);
                  } catch (error) {
                    setitem({
                      ...item,
                    });
                    return mostrarAlerta("No hay clientes", "error");
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </FormControl>
        <FormControl
          inputProps={{ className: clases.inputPadding }}
          className={clases.form20PC}
        >
          <Autocomplete
            disabled={
              estadoRequest === "GUARDIAS" &&
              item.perAreaDepartamento === "OPERATIVO-G"
            }
            disableClearable={true}
            options={puestosDataSalida.map((item) => {
              return item;
            })}
            getOptionLabel={(option) => {
              return option.replace("/+/", " ").split("/+/")[0];
            }}
            id="controlled-demo"
            value={turno.puestoSalida}
            paperprops
            onChange={async (event, newValue) => {
              if (newValue) {
                const options = newValue.split("/+/");
                setitem({
                  ...item,
                  perTurno: item.perTurno.map((item) => {
                    if (item.id === turno.id) {
                      return {
                        ...item,
                        puestoSalida: options[0],
                        clienteUbicacionSalida: options[1],
                        idDatoOptSalida: options[2],
                      };
                    } else {
                      return item;
                    }
                  }),
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                inputProps={{ className: clases.inputPadding }}
                {...params}
                size="small"
                required
                fullWidth
                label="Puesto Salida"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </FormControl>
      </>
      {/* TURNO */}

      {/* <TextField
        type="time"
        className={clases.form20PC}
        size="small"
        label="Ingreso"
        value={turno.ingreso}
        name="ingreso"
        InputLabelProps={{ shrink: true }}
        onChange={(e) => {
          setitem({
            ...item,
            perTurno: item.perTurno.map((item) => {
              if (item.id === turno.id) {
                return {
                  ...item,
                  ingreso: e.target.value,
                };
              } else {
                return item;
              }
            }),
          });
        }}
      />
      <TextField
        type="time"
        className={clases.form20PC}
        size="small"
        label="Salida"
        value={turno.salida}
        name="salida"
        InputLabelProps={{ shrink: true }}
        onChange={(e) => {
          setitem({
            ...item,
            perTurno: item.perTurno.map((item) => {
              if (item.id === turno.id) {
                return {
                  ...item,
                  salida: e.target.value,
                };
              } else {
                return item;
              }
            }),
          });
        }}
      /> */}
      <CalendarioPlus
        mostrarDiasFranco
        propiedad={turno.fechas}
        propiedadDB={turno.fechasConsultaDB}
        focusedDate={focusedDate}
        setFocusedDate={setFocusedDate}
        item={turno}
        setitem={(e) => {
          setitem({
            ...item,
            perTurno: item.perTurno.map((item) => {
              if (item.id === turno.id) {
                return e;
              } else {
                return item;
              }
            }),
          });
        }}
        TextoPropiedad={"fechas"}
        textoPropiedadDB={"fechasConsultaDB"}
        openCalendario={openCalendario}
        setopenCalendario={setopenCalendario}
      />
      <TextField
        className={clases.form20PC}
        multiline
        rows={2}
        size="small"
        label="Fechas"
        value={turno.fechasConsultaDB
          .map(
            (itemFecha) =>
              `${itemFecha.desde
                .substr(0, 16)
                .replace("T", "-")}, ${itemFecha.hasta
                .substr(0, 16)
                .replace("T", "-")}`
          )
          .join(" || ")}
        // name="dia"
        inputProps={{ className: clases.inputPadding }}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton
                aria-label=""
                onClick={() => {
                  setopenCalendario(true);
                }}
              >
                <CalendarToday color="secondary" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default Turno;

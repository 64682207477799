import React from "react";

import {
  Box,
  IconButton,
  List,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemText,
  Tooltip,
  ListItemButton,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { useButonStyles } from "../styles/styleButon";

export default function DialogOpciones(props) {
  const clases = useButonStyles();
  const { open, setopen, botones, titulo } = props;

  const handleClose = () => {
    setopen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <>
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <DialogTitle id="alert-dialog-title">{titulo}</DialogTitle>{" "}
            <IconButton aria-label="" onClick={handleClose} color="secondary">
              <Cancel />
            </IconButton>
          </Box>

          <Box pr={1} pl={1} pb={1}>
            <DialogContent>
              <List component="nav">
                {botones.map((itemBoton) => {
                  const {
                    tooltip,
                    funcion,
                    Icon,
                    color,
                    id,
                    disabled,
                    ocultar,
                  } = itemBoton;
                  if (ocultar) {
                    return null;
                  }
                  return (
                    <Tooltip title={tooltip} key={id}>
                      <ListItemButton
                        disabled={disabled}
                        onClick={() => {
                          funcion();
                        }}
                      >
                        {Icon && (
                          <ListItemAvatar>
                            <Avatar className={clases[color]}>
                              <Icon />
                            </Avatar>
                          </ListItemAvatar>
                        )}
                        <ListItemText primary={tooltip} />
                      </ListItemButton>
                    </Tooltip>
                  );
                })}
              </List>
            </DialogContent>
            <DialogActions></DialogActions>
          </Box>
        </Box>
      </>
    </Dialog>
  );
}

import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { withRouter } from "react-router";
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  AppBar,
  Toolbar,
  Typography,
  Popper,
  Fade,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from "@mui/material";
import {
  ArrowBack,
  ArrowForward,
  ArrowRightRounded,
  Backspace,
  Close,
  FindInPage,
  Menu,
} from "@mui/icons-material";
import Botones from "../../views/components/Botones";
import AlertaContext from "../../Context/Alerta/AlertaContext";
import AuthContext from "../../Context/Auth/AuthContext";
import MenuContext from "../../Context/Menu/MenuContext";
import Grid from "../../views/ArchivosCards/GridCards/Grid";
import IconBreadcrumbs from "../../views/components/MigasDePan";
const useStyles = makeStyles((theme) => ({
  button: {
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      margin: "2px",
      width: "100%",
    },
    fontWeight: "bold",
    margin: theme.spacing(1),
  },
  margin: {
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "100%",
    },
    margin: theme.spacing(1),
    width: "40%",
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const id = "menu";
const BarraTablas = (props) => {
  const classes = useStyles();

  const {
    search,
    setsearch,
    funcion,
    buscando,
    LabelBuscador,
    funcionDefault,
    ocultarMigasDePan,
    botones,
    ocultarBuscador,
  } = props;
  // const { usuario } = useContext(AuthContext);

  const { mostrarAlerta } = useContext(AlertaContext);
  const handleSubmit = (e) => {
    e.preventDefault();

    if (search.trim() === "") {
      return mostrarAlerta("ingrese una busqueda", "error");
    }

    funcion();
  };

  const { rows } = useContext(MenuContext);
  const { usuario } = useContext(AuthContext);
  const path = useMemo(() => props.location.pathname.split("/"), [
    props.location.pathname,
  ]);
  const length = useMemo(() => props.location.pathname.split("/").length - 1, [
    props.location.pathname,
  ]);

  const categorias = useMemo(
    () => rows.filter((itemRows) => itemRows.sidInfo.tipo === "categoria"),
    [rows]
  );
  const subCategorias = useMemo(
    () => rows.filter((itemRows) => itemRows.sidInfo.tipo === "subCategoria"),
    [rows]
  );
  const subsubCategorias = useMemo(
    () =>
      rows.filter((itemRows) => itemRows.sidInfo.tipo === "subsubCategoria"),
    [rows]
  );

  const padreCategoria = useMemo(
    () => categorias.find((item) => item.sidInfo.path === "/" + path[2]),
    [categorias, path]
  );

  const hijosDataSub = useMemo(
    () =>
      length === 3
        ? subCategorias.filter(
            (item) => item.sidInfo.categoria === "/" + path[2]
          )
        : [],
    [length, subCategorias, path]
  );

  const hijosDataSubSub = useMemo(
    () =>
      length === 4
        ? subsubCategorias.filter(
            (item) => item.sidInfo.subcategoria === "/" + path[3]
          )
        : [],
    [length, subsubCategorias, path]
  );

  const itemSubPadre = useMemo(
    () => subCategorias.find((item) => item.sidInfo.path === "/" + path[3]),
    [subCategorias, path]
  );
  const [padreFinal] = useState(length === 3 ? padreCategoria : itemSubPadre);
  const hijosDataFinal = useMemo(() => {
    let positionItem = null;

    const resultado =
      path[2] === "Dashboard"
        ? categorias
        : length === 3
        ? hijosDataSub
        : hijosDataSubSub;
    const resultadoF = resultado.filter((item, index) => {
      if (item.sidInfo.bloquearAreaDepartamento === "SI") {
        return item.sidInfo.areasDepartamento.some(
          (itemArea) => itemArea === usuario.areadepartamento
        );
      }
    });
    resultadoF.forEach((element, index) => {
      if (element.sidInfo.path === `/${path[length]}`) {
        positionItem = index;
      }
    });
    return { compas: resultadoF, positionItem };
  }, [path, length, categorias, hijosDataSub, hijosDataSubSub]);

  const replaceLastSegment = useCallback((path, newSegment) => {
    const segments = path.split("/");
    segments[segments.length - 1] = newSegment;
    return segments.join("/");
  }, []);
  const [open, setopen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenu, setOpenMenu] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu((previousOpen) => !previousOpen);
  };
  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={() => setopen(false)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setopen(false)}
              aria-label="close"
            >
              <Close />
            </IconButton>
            {padreFinal && (
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {padreFinal.sidInfo.name}
              </Typography>
            )}
            <Button autoFocus color="inherit" onClick={() => setopen(false)}>
              Cerrar
            </Button>
          </Toolbar>
        </AppBar>
        <Grid menosLength={1} ocultarMigas />
      </Dialog>
      {ocultarMigasDePan ? null : <IconBreadcrumbs />}

      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
        flexWrap="wrap"
        flexGrow="1"
      >
        <Box
          display="flex"
          width="60%"
          alignItems="center"
          flexWrap="wrap"
          flexGrow="1"
        >
          {botones && <Botones botones={botones} />}

          {!ocultarBuscador && (
            <form onSubmit={handleSubmit} className={classes.margin}>
              <TextField
                size="small"
                label={LabelBuscador ? LabelBuscador : "Buscar"}
                variant="outlined"
                fullWidth
                color="primary"
                value={search}
                onChange={(e) => setsearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <Box display="flex" justifyContent="space-between">
                      {buscando ? (
                        <IconButton
                          aria-label=""
                          color="secondary"
                          onClick={async () => {
                            await funcionDefault();
                          }}
                        >
                          <Backspace color="error" />
                        </IconButton>
                      ) : null}
                      <IconButton aria-label="" color="secondary" type="submit">
                        <FindInPage />
                      </IconButton>
                    </Box>
                  ),
                  labelWidth: "600",
                }}
              />
            </form>
          )}
        </Box>
        {!ocultarMigasDePan && (
          <Box display={"flex"} alignItems={"center"}>
            {hijosDataFinal.positionItem !== null ? (
              hijosDataFinal.compas[hijosDataFinal.positionItem - 1] ? (
                <Button
                  variant="text"
                  color="secondary"
                  startIcon={<ArrowBack />}
                  onClick={() => {
                    props.history.push(
                      replaceLastSegment(
                        props.location.pathname,
                        hijosDataFinal.compas[
                          hijosDataFinal.positionItem - 1
                        ].sidInfo.path.replace("/", "")
                      )
                    );
                  }}
                >
                  {
                    hijosDataFinal.compas[hijosDataFinal.positionItem - 1]
                      .sidInfo.name
                  }
                </Button>
              ) : null
            ) : null}

            {hijosDataFinal.compas && (
              <IconButton
                aria-label=""
                color="secondary"
                aria-describedby={id}
                type="button"
                onClick={handleClick}
              >
                <Menu />
              </IconButton>
            )}

            <Popper
              sx={{ zIndex: "3500" }}
              id={id}
              open={openMenu}
              anchorEl={anchorEl}
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
                      <MenuList role="menu" color="primary">
                        {hijosDataFinal.compas.map((item) => {
                          return (
                            <MenuItem
                              disabled={
                                path[length] ===
                                item.sidInfo.path.replace("/", "")
                              }
                              onClick={() => {
                                props.history.push(
                                  replaceLastSegment(
                                    props.location.pathname,
                                    item.sidInfo.path.replace("/", "")
                                  )
                                );
                              }}
                            >
                              {item.sidInfo.name}
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Fade>
              )}
            </Popper>
            {hijosDataFinal.positionItem !== null ? (
              hijosDataFinal.compas[hijosDataFinal.positionItem + 1] ? (
                <Button
                  variant="text"
                  color="secondary"
                  endIcon={<ArrowForward />}
                  onClick={() => {
                    props.history.push(
                      replaceLastSegment(
                        props.location.pathname,
                        hijosDataFinal.compas[
                          hijosDataFinal.positionItem + 1
                        ].sidInfo.path.replace("/", "")
                      )
                    );
                  }}
                >
                  {
                    hijosDataFinal.compas[hijosDataFinal.positionItem + 1]
                      .sidInfo.name
                  }
                </Button>
              ) : null
            ) : null}
          </Box>
        )}
      </Box>
    </div>
  );
};

export default withRouter(memo(BarraTablas));

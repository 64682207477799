import React, { memo, useContext } from "react";

import {
  Autocomplete,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Popper,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { AddCircle, Cancel, Check } from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { trimPropsItem } from "../../../../config/const";
import { objectDefault } from "../data";
import clienteAxios from "../../../../config/axios";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();

  // PROPS
  const {
    setopen,
    socket,
    stocksData,
    desdeProductos,
    producto,
    id_compra,
    setopenDialogOpcionesProducto,
  } = props;
  const claseCelda = `${classes.celda}`;
  console.log(objectDefault);
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // COPIA DEL REGISTRO PARA EDITAR
  const [item, setitem] = useState(
    desdeProductos
      ? {
          ...objectDefault,
          // prodTipo: producto.prodTipo,
          // stock_prodId: producto.prodId,
          // prodNombre: producto.prodNombre,
          stock_id_compra: id_compra,
        }
      : objectDefault
  );

  const [productosData, setProductosData] = useState([]);
  //onChange
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (item.bodeNombre === "") {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const funcSubmit = () => {
    try {
      console.log(
        trimPropsItem({
          ...item,
          tabla: "stocks",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          user: usuario.usuario,
          cod_empresa: usuario.rucempresa,
        })
      );
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          tabla: "stocks",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          user: usuario.usuario,
          cod_empresa: usuario.rucempresa,
        })
      );
      setitem(objectDefault);
      setopen(false);
    } catch (error) {}
  };
  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root} component={"form"}>
        {/* boton */}
        <TableCell
          sx={{ paddingLeft: "5px" }}
          size="small"
          align="center"
          className={classes.celda}
        ></TableCell>
        <ButtonsAction
          botones={[
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                setopen(false);
                setitem(objectDefault);
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 1,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: botonDisabled(),
              Icon: Check,
              color: "success",
              id: 2,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
          ]}
        />
        <>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              value={item.prodTipo}
              name="prodTipo"
              onChange={(e) => {
                setProductosData([]);
                setitem({
                  ...item,
                  prodNombre: "",
                  prodId: 0,
                  [e.target.name]: e.target.value,
                });
              }}
              size="small"
              fullWidth
              select
              SelectProps={{
                displayEmpty: true,
              }}
            >
              <MenuItem value="">Seleccione.</MenuItem>
              {["VESTIMENTAS", "ARMAS", "MUNICIONES", "EQUIPOS"].map((item) => {
                return <MenuItem value={item}>{item} </MenuItem>;
              })}
            </TextField>
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            <FormControl sx={{ width: "100%" }}>
              <Autocomplete
                disableClearable
                PopperComponent={PopperMy}
                options={productosData.map((item) => {
                  return item.prodNombre + "/+/" + item.prodId;
                })}
                getOptionLabel={(option) => {
                  return option.split("/+/")[0];
                }}
                id="controlled-demo"
                value={item.prodNombre.trim() === "" ? "" : item.prodNombre}
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const [prodNombre, prodId] = newValue.split("/+/");
                    if (!newValue) return;
                    setitem((prev) => ({
                      ...prev,
                      prodNombre,
                      stock_prodId: prodId,
                    }));
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    required
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          <InputAdornment>
                            {params.InputProps.endAdornment}
                            <IconButton
                              size="small"
                              aria-label=""
                              onClick={async () => {
                                setopenDialogOpcionesProducto(true);
                              }}
                            >
                              <AddCircle color="primary" />
                            </IconButton>
                          </InputAdornment>
                        </React.Fragment>
                      ),
                    }}
                    onChange={async (e) => {
                      try {
                        const res = await clienteAxios.get(
                          `productos/getBySearch/serie_tipo_nombre?search=${e.target.value}&tipo=${item.prodTipo}`
                        );
                        console.log({ res });
                        setProductosData(res.data);
                      } catch (error) {}
                    }}
                  />
                )}
              />
            </FormControl>
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {item.prodTipo === "VESTIMENTAS" ? (
              <TextField
                value={item.talla}
                name="talla"
                onChange={(e) =>
                  setitem({
                    ...item,
                    [e.target.name]: e.target.value,
                  })
                }
                size="small"
                fullWidth
                select
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="">Seleccione.</MenuItem>
                {[
                  "0",
                  "36",
                  "37",
                  "38",
                  "39",
                  "40",
                  "41",
                  "42",
                  "43",
                  "44",
                  "46",
                  "48",
                ].map((item) => {
                  return <MenuItem value={item}>{item} </MenuItem>;
                })}
              </TextField>
            ) : (
              "-"
            )}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              value={item.condicion_producto}
              name="condicion_producto"
              onChange={(e) =>
                setitem({
                  ...item,
                  [e.target.name]: e.target.value,
                })
              }
              size="small"
              fullWidth
              select
              SelectProps={{
                displayEmpty: true,
              }}
            >
              {["NUEVO", "USADO", "OPERABLE", "BUEN ESTADO", "DEFECTUOSO"].map(
                (item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                }
              )}
            </TextField>
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            <FormControl sx={{ width: "100%" }}>
              <Autocomplete
                PopperComponent={PopperMy}
                options={stocksData.map((item) => {
                  return item.bodeNombre + "/+/" + item.bodeId;
                })}
                getOptionLabel={(option) => {
                  return option.replace("/+/", " ").split("/+/")[0];
                }}
                id="controlled-demo"
                value={item.bodeNombre.trim() === "" ? "" : item.bodeNombre}
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const [bodeNombre, stock_bodeId] = newValue.split("/+/");
                    if (!newValue) return;
                    setitem((prev) => ({
                      ...prev,
                      bodeNombre,
                      stock_bodeId,
                    }));
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" required fullWidth />
                )}
              />
            </FormControl>
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              type="number"
              value={item.stock_total_por_bodega}
              name="stock_total_por_bodega"
              onChange={(e) =>
                setitem({
                  ...item,
                  [e.target.name]: Math.max(1, e.target.value),
                })
              }
              size="small"
              fullWidth
            />
          </TableCell>
          {/* <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              type="number"
              value={item.maximo}
              name="maximo"
              onChange={(e) =>
                setitem({
                  ...item,
                  [e.target.name]: Math.max(0, e.target.value),
                })
              }
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              type="number"
              value={item.minimo}
              name="minimo"
              onChange={(e) =>
                setitem({
                  ...item,
                  [e.target.name]: Math.max(0, e.target.value),
                })
              }
              size="small"
              fullWidth
            />
          </TableCell>  */}
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              disabled
              value={item.stock_disponible}
              name="stock_disponible"
              onChange={(e) =>
                setitem({
                  ...item,
                  [e.target.name]: Math.max(0, e.target.value),
                })
              }
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            size="small"
            align="center"
            className={claseCelda}
          ></TableCell>
          <TableCell
            size="small"
            align="center"
            className={claseCelda}
          ></TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));

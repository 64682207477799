import React, { memo, useContext } from "react";

import { Box, Collapse, TableCell, TableRow, Typography } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../../components/ButtonsAction";
import {
  Cancel,
  DeleteForever,
  ExpandLess,
  ExpandMore,
  Refresh,
  SettingsSuggest,
} from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";
import clienteAxios from "../../../../../config/axios";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import SubHijo from "../../SubHijo/SubHijo";
import Cargando from "../../../../components/Cargando";
import { usePermiso } from "../../../../hooks/usePermiso";
import AuthContext from "../../../../../Context/Auth/AuthContext";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    getDataState,
    // HOOK DE PERMISO
    // tienePermiso,
    // alertaPermiso,
    // ROW
    index,
    rowHijo,
    rowPadre,
    // OPEN Y EDIT
    setopen,
    seteditar,
    // EXPORT
    campoExport,
    arrayExport,
    setarrayExport,
    // FUNCION STATE
    changeObjetoActivoState,
    eliminarState,
    // SOCKET
    socket,
    tabla,
    // ESTADO
    estadoRequest,
  } = props;
  const { tienePermiso, alertaPermiso } = usePermiso("Distribuciones");
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    rowHijo.editado === true
      ? classes.celdaEditado
      : rowHijo.nuevo === true
      ? classes.celdaNuevo
      : rowHijo.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;

  // CONTEXT
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialogEliminar, setopenConfirmDialogEliminar] = useState(
    false
  );

  const [expandir, setexpandir] = useState(false);
  const [cargado, setcargado] = useState(false);
  const [cargando, setcargando] = useState(false);
  // ELIMINAR EL REGISTRO

  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        rolpUser: usuario.usuario,
        rolpEmpresa: usuario.rucempresa,
        [campoExport]: rowHijo[campoExport],
        rolpPeriodo: rowHijo.rolpPeriodo,
        rolpDocNumCliente: rowHijo.rolpDocNumCliente,
        rolpIdCliente: rowHijo.rolpIdCliente,
      });
      setopenConfirmDialogEliminar(false);
    } catch (error) {
      console.log({ error });
    }
  };
  if (rowHijo.eliminado) {
    return null;
  }
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialogEliminar}
        setopen={setopenConfirmDialogEliminar}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialogEliminar(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={rowHijo}
          agrupado={true}
          rowPadre={rowPadre}
          botones={[
            {
              tooltip: "Expandir",
              texto: "",
              funcion: () => {
                setcargado(true);
                setexpandir(!expandir);
              },
              disabled: false,
              Icon: expandir ? ExpandLess : ExpandMore,
              color: "success",
              id: 3,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />
        <>
          <TableCell size="small" align="left" className={claseCelda}>
            {rowHijo.distri_DocuCliente + " " + rowHijo.distri_NombreCliente}
          </TableCell>
          <TableCell size="small" align="left" className={claseCelda}>
            {rowHijo.tregistros}
          </TableCell>
        </>
      </TableRow>
      <TableRow>
        <TableCell
          className={claseCelda}
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={21}
        >
          <Collapse in={expandir} timeout="auto">
            {cargado ? (
              <SubHijo
                // ROW
                rowHijo={rowHijo}
                rowPadre={rowPadre}
                // OPEN Y EDIT
                setopen={setopen}
                seteditar={seteditar}
                // EXPORT
                campoExport={campoExport}
                arrayExport={arrayExport}
                setarrayExport={setarrayExport}
                // DATA
                expandir={expandir}
                tabla={tabla}
                estadoRequest={estadoRequest}
                // FUNCION STATE
                getDataState={getDataState}
                changeObjetoActivoState={changeObjetoActivoState}
                // SOCKET
                socket={socket}
              />
            ) : null}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));

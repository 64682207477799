import React, { memo, useContext } from "react";

import {
  TableCell,
  TableRow, 
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  Dialog,
} from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import AuthContext from "../../../Context/Auth/AuthContext";
import {
  Cancel,
  DeleteForever,
  Description,
  Folder,
  PictureAsPdf,
} from "@mui/icons-material";
import { useRowStyles } from "../../styles/stylesRows";
import { Link } from "react-router-dom";
import ButtonsAction from "../../components/ButtonsAction";
import clienteAxios from "../../../config/axios";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const { row, index, campoExport, socket } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // HOOK DE PERMISO
  // const { tienePermiso, alertaPermiso } = usePermiso("DocumentosExpirados");
  // OPEN DIALOG
  const [openDialog, setopenDialog] = useState(false);
  // CONTEXT
  const { usuario } = useContext(AuthContext);

  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // COLUMNAS DE LA TABLA HIJA

  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "ciudad",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        ciuUser: usuario.usuario,
        ciuEmpresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <Dialog
        open={openDialog}
        maxWidth={"md"}
        onClose={() => {
          setopenDialog(false);
        }}
      >
        {row.notInformacion.datos.map((item) => {
          return (
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    window.open(item.url);
                  }}
                >
                  <ListItemIcon>
                    <Description color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={item.namearchivo} />
                </ListItemButton>
              </ListItem>
            </List>
          );
        })}
      </Dialog>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow
        className={classes.root}
        selected={index % 2 === 0}
        component={"form"}
      >
        {/* boton */}
        <>
          <ButtonsAction
            claseCelda={claseCelda}
            row={row}
            botones={[
              {
                tooltip: "Actualizar",
                texto: "",
                funcion: () => {
                  setopenDialog(true);
                },
                disabled: false,
                Icon: Folder,
                color: "primary",
                id: 3,
                ocultar: false,
                tipo: "icono",
                variante: "contained",
                size: "small",
                sx: {},
              },
              {
                tooltip: "PDF",
                texto: "",
                funcion: () => {
                  window.open(
                    `${clienteAxios.defaults.baseURL}reportes/documento_expirado_individual?notId=${row.notId}&rucempresa=${usuario.rucempresa}&usuario=${usuario.usuario}&nombre=${usuario.nombre}`
                  );
                },
                disabled: false,
                Icon: PictureAsPdf,
                color: "error",
                id: 3,
                ocultar: false,
                tipo: "icono",
                variante: "contained",
                size: "small",
                sx: {},
              },
            ]}
          />
          <TableCell fontSize="small" align="center" className={claseCelda}>
            {row.notDocuPersona}
          </TableCell>
          <TableCell fontSize="small" align="center" className={claseCelda}>
            <Link
              to={`/Seguridad/RRHH/Personas?documento=${row.notDocuPersona}&rol=${row.notRol}`}
            >
              {row.notNombrePersona}
            </Link>
          </TableCell>
          <TableCell fontSize="small" align="center" className={claseCelda}>
            {row.notRol.join(" - ")}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));

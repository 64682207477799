import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { withRouter } from "react-router-dom";
import { Box, Divider, Typography } from "@mui/material";
import MenuContext from "../../../Context/Menu/MenuContext";
import Card from "./Card";
import IconBreadcrumbs from "../../../components/Extra/MigasDePan";
import AuthContext from "../../../Context/Auth/AuthContext";
const Configuracion = (props) => {
  const { menosLength = 0, ocultarMigas } = props;
  const { rows } = useContext(MenuContext);
  const { usuario } = useContext(AuthContext);
  const [path] = useState(props.location.pathname.split("/"));
  const [length] = useState(
    props.location.pathname.split("/").length - menosLength
  );
  const [categorias] = useState(
    rows.filter((itemRows) => itemRows.sidInfo.tipo === "categoria")
  );
  const [subCategorias] = useState(
    rows.filter((itemRows) => itemRows.sidInfo.tipo === "subCategoria")
  );
  const [subsubCategorias] = useState(
    rows.filter((itemRows) => itemRows.sidInfo.tipo === "subsubCategoria")
  );
  const [padreCategoria] = useState(
    categorias.find((item) => item.sidInfo.path === "/" + path[2])
  );

  const [hijosDataSub] = useState(
    length === 3
      ? subCategorias.filter((item) => item.sidInfo.categoria === "/" + path[2])
      : []
  );

  const [hijosDataSubSub] = useState(
    length === 4
      ? subsubCategorias.filter(
          (item) => item.sidInfo.subcategoria === "/" + path[3]
        )
      : []
  );
  const [itemSubPadre] = useState(
    subCategorias.find((item) => item.sidInfo.path === "/" + path[3])
  );

  const [hijosDataFinal] = useState(
    path[2] === "Dashboard"
      ? categorias
      : length === 3
      ? hijosDataSub
      : hijosDataSubSub
  );
  const [padreFinal] = useState(length === 3 ? padreCategoria : itemSubPadre);

  const bloquearFuncion = (item) => {
    if (item.sidInfo.bloquearAreaDepartamento === "SI") {
      const bloquear = item.sidInfo.areasDepartamento.some(
        (itemArea) => itemArea === usuario.areadepartamento
      );

      if (bloquear) {
        return false;
      } else {
        return true;
      }
    }
  };
  return (
    <>
      {path[2] === "Dashboard" || ocultarMigas ? null : (
        <Typography
          variant="h4"
          color="InfText"
          align="center"
          textTransform={"uppercase"}
          fontWeight="bold"
          mb={2}
        >
          <IconBreadcrumbs />
          {/* {padreFinal.sidInfo.name} */}
        </Typography>
      )}

      <Divider sx={{ marginBottom: "2rem" }} />
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
          },
          gap: "2.5rem",
        }}
      >
        {hijosDataFinal.map((itemHijo) => {
          if (bloquearFuncion(itemHijo)) {
            return null;
          }
          return (
            <Card
              data={itemHijo.sidInfo}
              padreFinal={padreFinal}
              key={itemHijo.sidId}
            />
          );
        })}
      </Box>
    </>
  );
};

export default withRouter(Configuracion);

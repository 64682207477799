import { FechaExacta } from "../../config/const";

export const columns = [
  {
    id: "distri_FecUpd",
    label: "Periodo",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "distri_FecUpd",
    align: "center",
    ocultar: true,
  },
  {
    id: "tregistros",
    label: "CANTIDAD",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "tregistros",
    align: "center",
    ocultar: true,
  },
];

export const columnsHijosDevolucion = [
  {
    id: "distri_Personas",
    label: "Personas",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "distri_Personas",
    align: "center",
    ocultar: true,
  },

  {
    id: "distri_FecReg",
    label: "Fec. Reg",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "distri_FecReg",
    align: "center",
    ocultar: true,
  },

  {
    id: "disEstado",
    label: "Estado",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "disEstado",
    align: "center",
    ocultar: true,
  },

  {
    id: "disFechaEnvio",
    label: "Fec. Envio",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "disFechaEnvio",
    align: "center",
    ocultar: true,
  },

  {
    id: "distri_User",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "distri_User",
    align: "center",
    ocultar: true,
  },
];

export const columnsHijosPedido = [
  {
    id: "distri_Personas",
    label: "Personas",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "distri_Personas",
    align: "center",
    ocultar: true,
  },

  {
    id: "distri_FecReg",
    label: "Fec. Reg",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "distri_FecReg",
    align: "center",
    ocultar: true,
  },

  {
    id: "disEstado",
    label: "Estado",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "disEstado",
    align: "center",
    ocultar: true,
  },

  {
    id: "disFechaEnvio",
    label: "Fec. Envio",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "disFechaEnvio",
    align: "center",
    ocultar: true,
  },

  {
    id: "distri_User",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "distri_User",
    align: "center",
    ocultar: true,
  },
];
export const columnsHijos = [
  {
    id: "distri_DocuCliente",
    label: "Cliente",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "distri_DocuCliente",
    align: "left",
    ocultar: true,
  },
  {
    id: "tregistros",
    label: "CANTIDAD",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "tregistros",
    align: "left",
    ocultar: true,
  },
];
export const columnsSubHijosDistribucion = [
  {
    id: "distri_PuestoCliente",
    label: "Puesto",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "distri_PuestoCliente",
    align: "center",
    ocultar: true,
  },
  {
    id: "distri_Personas",
    label: "Personas",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "distri_Personas",
    align: "center",
    ocultar: true,
  },

  {
    id: "distri_Entrega",
    label: "Entrega",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "distri_Entrega",
    align: "center",
    ocultar: true,
  },

  {
    id: "distri_TipoDotacion",
    label: "Tipo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "distri_TipoDotacion",
    align: "center",
    ocultar: true,
  },

  {
    id: "distri_FecUpd",
    label: "Fec. Upd",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "distri_FecUpd",
    align: "center",
    ocultar: true,
  },

  {
    id: "distri_User",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "distri_User",
    align: "center",
    ocultar: true,
  },
];

export const objectDefaultDevolucion = {
  distri_PuestoCliente: "",
  distri_TipoDotacion: "",
  // distri_Id: 192,
  distri_Tipo: "DEVOLUCION",
  distri_EstadoDevolucion: false,
  distri_IdCliente: "",
  distri_DocuCliente: "",
  distri_NombreCliente: "",
  distri_Personas: [],
  disSupervisores: [],
  disAdministracion: [],
  distri_Entrega: "",
  distri_Observacion: "",
  disEstado: "",
  disFechaEnvio: "",
  disDetalle: "",
  disFechaRecibido: "",
  disPedidos: [
    // {
    //   id: 0,
    //   nombre: "",
    //   cantidad: "",
    //   tipo: "",
    //   serie: "",
    //   idProduct: "",
    //   cantidadDevolucion: "",
    //   estadoDevolucion: "",
    // },
  ],
  distri_User: "",
  //disEmpresa: "",
  distri_FecReg: "",
  Todos: "",
};
export const objectDefaultPedido = {
  distri_TipoDotacion: "",
  distri_Personas: [],
  disSupervisores: [],
  disAdministracion: [],
  distri_Tipo: "PEDIDO",
  distri_EstadoDevolucion: false,
  distri_IdCliente: "",
  distri_DocuCliente: "",
  distri_NombreCliente: "",
  distri_Entrega: "",
  distri_Observacion: "",
  disEstado: "PENDIENTE",
  disFechaEnvio: "",
  disDetalle: "",
  disDocumento: "",
  disFechaRecibido: "",
  disVestimenta: [
    // {
    //   tipo: "VESTIMENTAS",
    //   nombre: "UNIFORMES",
    //   serie: "JDK332",
    //   cantidad: "2",
    //   valor: "50.00",
    //   estadoEquipo: "NUEVO",
    //   marca: "OVIa",
    //   modelo: "OVIN JEAN",
    //   talla: "40",
    //   color: "NEGRO",
    // },
  ],

  disMuniciones: [],
  distri_User: "",
  //disEmpresa: "",
  distri_FecReg: "",
  distri_UbicacionCliente: "",
  distri_PuestoCliente: "",
};

export const objectDefaultDistribucion = {
  // distri_Id: 1,
  distri_Tipo: "DISTRIBUCION",
  distri_TipoDotacion: "PERSONAL",
  distri_Entrega: "DOTACIÓN",
  distri_IdCliente: 0,
  distri_idDatoOperativo: 0,
  distri_User: "admin",
  distri_Observacion: "",
  distri_EstadoDevolucion: "NO",
  total: 0,
  distri_FecReg: "",
  distri_FecUpd: "",
  distri_DocuCliente: "",
  distri_NombreCliente: "",
  distri_UbicacionCliente: "",
  distri_PuestoCliente: "",
  distri_Fecha: FechaExacta,
  distri_Responsable: "",
  distri_productos: [
    // {
    //   prod_distri_Id: 1,
    //   prodId: 1,
    //   cantidad: 1,
    //   fotos: [],
    //   prodTipo: "VESTIMENTAS",
    //   prodSerie: "VES1251",
    //   prodNombre: "UNIFORME COMPLETO TALLA 48 NV",
    //   prodMarca: "ND",
    //   prodModelo: "PARADA",
    //   prodTalla: 48,
    //   prodColor: "GRIS NEGRO",
    //   prodTipoArma: null,
    //   prodCalibre: null,
    //   prodMaterial: null,
    // },
  ],
  distri_Personas: [
    // {
    //   asig_Id: 1,
    //   perId: 1611,
    //   perDocNumero: "1752033637",
    //   perNombres: "VICTOR ESTUARDO",
    //   perApellidos: "MESTANZA SANCHEZ",
    //   perFoto:
    //     "https://documentos.neitor.com/seguridad/PRUEBA/documentos/e5e446da-28e0-456b-9b70-b1fe1d562212.png",
    //   perAreaDepartamento: "OPERATIVO-G",
    // },
  ],
  distri_fecFinContratoCliente: "",
};
export const objectDefaultBienesCliente = {
  // distri_Id: 1,
  distri_Tipo: "BIENES CLIENTE",
  distri_TipoDotacion: "PUESTO",
  distri_Entrega: "BIENES CLIENTE",
  distri_IdCliente: 0,
  distri_idDatoOperativo: 0,
  distri_User: "admin",
  distri_Observacion: "",
  distri_EstadoDevolucion: "NO",
  total: 0,
  distri_FecReg: "",
  distri_FecUpd: "",
  distri_DocuCliente: "",
  distri_NombreCliente: "",
  distri_UbicacionCliente: "",
  distri_PuestoCliente: "",
  distri_Fecha: FechaExacta,
  distri_Responsable: "",
  distri_productos: [
    // {
    //   prod_distri_Id: 1,
    //   prodId: 1,
    //   cantidad: 1,
    //   fotos: [],
    //   prodTipo: "VESTIMENTAS",
    //   prodSerie: "VES1251",
    //   prodNombre: "UNIFORME COMPLETO TALLA 48 NV",
    //   prodMarca: "ND",
    //   prodModelo: "PARADA",
    //   prodTalla: 48,
    //   prodColor: "GRIS NEGRO",
    //   prodTipoArma: null,
    //   prodCalibre: null,
    //   prodMaterial: null,
    // },
  ],
  distri_Personas: [
    // {
    //   asig_Id: 1,
    //   perId: 1611,
    //   perDocNumero: "1752033637",
    //   perNombres: "VICTOR ESTUARDO",
    //   perApellidos: "MESTANZA SANCHEZ",
    //   perFoto:
    //     "https://documentos.neitor.com/seguridad/PRUEBA/documentos/e5e446da-28e0-456b-9b70-b1fe1d562212.png",
    //   perAreaDepartamento: "OPERATIVO-G",
    // },
  ],
  distri_fecFinContratoCliente: "",
};

export const columnBienesClienteModal = [
  {
    id: "21",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "Foto",
  },
  // {
  //   id: "22",
  //   align: "center",
  //   minWidth: 50,
  //   colSpan: 1,
  //   label: "Bodega",
  // },
  {
    id: "23",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "Tipo",
  },
  {
    id: "24",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "Condición",
  },
  {
    id: "25",
    align: "center",
    minWidth: 250,
    colSpan: 1,
    label: "Nombre / Serie",
  },
  {
    id: "26",
    align: "cantidad",
    minWidth: 50,
    colSpan: 1,
    label: "cantidad",
  },
  // {
  //   id: "27",
  //   align: "center",
  //   minWidth: 50,
  //   colSpan: 1,
  //   label: "talla",
  // },
  {
    id: "28",
    align: "center",
    minWidth: 100,
    colSpan: 1,
    label: "color",
  },
  {
    id: "29",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "Valor",
  },
];
export const columnsVestimenta = [
  {
    id: "1",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "Foto",
  },
  {
    id: "2",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "Bodega",
  },
  {
    id: "3",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "Tipo",
  },
  {
    id: "18",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "Condición",
  },
  {
    id: "4",
    align: "center",
    minWidth: 250,
    colSpan: 1,
    label: "Nombre / Serie",
  },
  {
    id: "5",
    align: "cantidad",
    minWidth: 50,
    colSpan: 1,
    label: "cantidad",
  },
  {
    id: "6",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "talla",
  },
  {
    id: "7",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "color",
  },
  {
    id: "8",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "Valor",
  },
];

export const columnsArma = [
  {
    id: "9",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "Foto",
  },
  {
    id: "50",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "Bodega",
  },
  {
    id: "19",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "Condición",
  },
  {
    id: "11",
    align: "center",
    minWidth: 250,
    colSpan: 1,
    label: "nombre",
  },
  {
    id: "20",
    align: "cantidad",
    minWidth: 50,
    colSpan: 1,
    label: "cantidad",
  },
  {
    id: "12",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "color",
  },

  {
    id: "13",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "Valor",
  },
];
export const columnsMunicion = [
  {
    id: "14",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "Foto",
  },
  {
    id: "15",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "Bodega",
  },
  {
    id: "20",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "Condición",
  },
  {
    id: "16",
    align: "center",
    minWidth: 250,
    colSpan: 1,
    label: "nombre",
  },
  {
    id: "21",
    align: "cantidad",
    minWidth: 50,
    colSpan: 1,
    label: "cantidad",
  },
  {
    id: "17",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "Valor",
  },
];
export const columnsDevolucion = [
  {
    id: "18",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "C. Anterior",
  },

  {
    id: "19",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "C. Devolucion",
  },
];

export const columnsCantidades = [
  {
    id: "estado",
    align: "center",
    minWidth: 200,
    tipo: "string",
    label: "estado",
  },
  {
    id: "disponible",
    align: "center",
    minWidth: 200,
    tipo: "string",
    label: "disponible",
  },
  {
    id: "cantidad",
    align: "center",
    minWidth: 200,
    tipo: "string",
    label: "cantidad",
  },
];

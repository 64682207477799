import React, { memo, useContext } from "react";

import { Dialog, TableCell, TableRow, Typography } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import {
  AlarmOff,
  AlarmOn,
  Business,
  CalendarMonth,
  Cancel,
  Create,
  DeleteForever,
  Image,
  List,
  Man,
  Person,
  PictureAsPdf,
  RecentActors,
  Restore,
  Woman,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { usePermiso } from "../../../hooks/usePermiso";
import { trimPropsItem } from "../../../../config/const";
import clienteAxios from "../../../../config/axios";
import DialogOpciones from "./DialogOpciones";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { useGetNameUser } from "../../../hooks/useGetNameUser";
const Row = (props) => {
  const classes = useRowStyles();
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    seteditar,
    setopen,
    changeObjetoActivoState,
    socket,
    setopenDialogPerfil,
    setopenDialogEmpresas,
    setperfilData,
    fromHorarios,
  } = props;
  // PROPS
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  const { tienePermiso, alertaPermiso } = usePermiso("Personas");
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [openDialogOpciones, setopenDialogOpciones] = useState(false);
  const [cargandoDialogOpciones, setcargandoDialogOpciones] = useState(false);
  const [celComprimida, setcelComprimida] = useState({});
  const [finalizoTurnoTurno, setfinalizoTurnoTurno] = useState(false);
  const [openImagen, setopenImagen] = useState(false);
  const { handleGetNameUser } = useGetNameUser();
  const handleEliminar = async (_id) => {
    try {
      setcargandoDialogOpciones(true);
      socket.current.emit("client:eliminarData", {
        tabla: "persona",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        perUser: usuario.usuario,
        perEmpresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setcargandoDialogOpciones(false);
      setopenDialogOpciones(false);
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcionEdit = () => {
    setopen(true);
    seteditar(true);
    changeObjetoActivoState(row);
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...row,

          tabla: "persona",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          perUser: usuario.usuario + "/+/" + row[campoExport],
          perEmpresa: usuario.rucempresa,
          [campoExport]: row[campoExport],
        })
      );

      seteditar(false);
    } catch (error) {}
  };
  return (
    <React.Fragment>
      <Dialog
        open={openImagen}
        onClose={() => setopenImagen(false)}
        aria-labelledby="draggable-dialog-title"
      >
        <img width="100%" src={row.perFoto} alt="imagen" />
      </Dialog>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* DIALOG OPCIONES */}
        <DialogOpciones
          row={row}
          usuario={usuario}
          open={openDialogOpciones}
          setopen={setopenDialogOpciones}
          botones={[
            {
              tooltip: "Hoja de Vida Empresa",
              funcion: () => {
                window.open(
                  `${
                    clienteAxios.defaults.baseURL
                  }reportes/personaindividual?perId=${row.perId}&rucempresa=${
                    row.perEmpresa
                  }&nombre=${usuario.nombre}&usuario=${
                    usuario.usuario
                  }&hojavida=${"empresa"}`
                );
              },
              Icon: PictureAsPdf,
              color: "bg",
              id: 2,
              disabled: cargandoDialogOpciones,
            },
            {
              tooltip: "Hoja de Vida Cliente",
              funcion: () => {
                window.open(
                  `${
                    clienteAxios.defaults.baseURL
                  }reportes/personaindividual?perId=${row.perId}&rucempresa=${
                    row.perEmpresa
                  }&nombre=${usuario.nombre}&usuario=${
                    usuario.usuario
                  }&hojavida=${"cliente"}`
                );
              },
              Icon: PictureAsPdf,
              color: "bg",
              id: 2,
              disabled: cargandoDialogOpciones,
            },
            {
              tooltip:
                row.perInitTurno === "SI" && !finalizoTurnoTurno
                  ? "Finalizar turno"
                  : "No hay Turno",
              funcion: async () => {
                try {
                  const res = await clienteAxios.post(
                    `personas/finalizarturno/0`,
                    {
                      perId: row.perId,
                    }
                  );
                  mostrarAlerta(res.data.msg, "success");
                  setfinalizoTurnoTurno(true);
                } catch (error) {
                  mostrarAlerta("No se pudo finalizar el turno", "error");
                  setfinalizoTurnoTurno(false);
                }
              },
              Icon:
                row.perInitTurno === "SI" && !finalizoTurnoTurno
                  ? AlarmOn
                  : AlarmOff,
              color: "success",
              id: 2,
              disabled: row.perInitTurno !== "SI" || finalizoTurnoTurno,
            },
            {
              tooltip: "Carnet",
              texto: "",
              funcion: () => {
                window.open(
                  `${clienteAxios.defaults.baseURL}reportes/carnet?perId=${row.perId}&rucempresa=${row.perEmpresa}`
                );
              },
              disabled: false,
              Icon: RecentActors,
              color: "bg",
              id: 4,
              ocultar: row.eliminado || fromHorarios,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Puestos Servicio",
              texto: "",
              funcion: () => {
                window.open(
                  `${clienteAxios.defaults.baseURL}reportes/personaPuestoServicio?perId=${row.perId}&rucempresa=${usuario.rucempresa}&nombre=${usuario.nombre}&usuario=${usuario.usuario}`
                );
              },
              disabled: false,
              Icon: PictureAsPdf,
              color: "error",
              id: 5,
              ocultar: row.eliminado || fromHorarios,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Cambiar Perfil",
              texto: "",
              funcion: () => {
                setopenDialogPerfil(true);
                setopenDialogOpciones(false);
                setperfilData((prev) => ({
                  ...prev,
                  oldPerfil: row.perPerfil[0],
                  perId: row.perId,
                }));
              },
              disabled: false,
              Icon: Person,
              color: "primary",
              id: 6,
              ocultar: row.eliminado || fromHorarios,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Cambiar Empresas",
              texto: "",
              funcion: () => {
                setopenDialogEmpresas(true);
                setopenDialogOpciones(false);
                setperfilData((prev) => ({
                  ...prev,
                  oldPerfil: row.perPerfil[0],
                  perId: row.perId,
                  empresas: true,
                }));
              },
              disabled: false,
              Icon: Business,
              color: "primary",
              id: 6,
              ocultar: row.eliminado || fromHorarios,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
          titulo={"Acciones"}
        />

        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={fromHorarios ? null : campoExport}
          row={row}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar(true);
              },
              disabled: false,
              Icon: Restore,
              color: "success",
              id: 1,
              ocultar: !row.eliminado || fromHorarios,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                funcionEdit();
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 3,
              ocultar: row.eliminado || fromHorarios,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Mas acciones",
              texto: "",
              funcion: () => {
                setopenDialogOpciones(true);
              },
              disabled: false,
              Icon: List,
              color: "secondary",
              id: 6,
              ocultar: row.eliminado || fromHorarios,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Foto",
              texto: "",
              funcion: () => {
                setopenImagen(true);
              },
              disabled: row.perFoto === "",
              Icon: Image,
              color: "secondary",
              id: 6,
              ocultar: row.eliminado || fromHorarios,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },

            {
              texto: "",
              tooltip:
                row.perInitTurno === "SI" && !finalizoTurnoTurno
                  ? "Finalizar turno"
                  : "No hay Turno",
              funcion: async () => {
                try {
                  const res = await clienteAxios.post(
                    `personas/finalizarturno/0`,
                    {
                      perId: row.perId,
                    }
                  );
                  mostrarAlerta(res.data.msg, "success");
                  setfinalizoTurnoTurno(true);
                } catch (error) {
                  mostrarAlerta("No se pudo finalizar el turno", "error");
                  setfinalizoTurnoTurno(false);
                }
              },
              Icon: row.genero === "MASCULINO" ? Man : Woman,
              color: "success",
              disabled: row.perInitTurno !== "SI" || finalizoTurnoTurno,
              id: 6,
              ocultar: row.eliminado || fromHorarios,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              texto: "",
              tooltip: "Horario laboral",
              funcion: async () => {
                // setopenHorarios(true);
              },
              Icon: CalendarMonth,
              color: "success",
              disabled: false,
              id: 6,
              ocultar: !fromHorarios,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            // {
            //   texto: "",
            //   tooltip: row.genero,
            //   funcion: async () => {
            //     // setopenHorarios(true);
            //   },
            //   Icon: row.genero === "MASCULINO" ? Man : Woman,
            //   color: "success",
            //   disabled: false,
            //   id: 7,
            //   ocultar: false,
            //   tipo: "icono",
            //   variante: "contained",
            //   size: "small",
            //   sx: butonIconTransition,
            // },
          ]}
        />
        <>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.perId}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.perDocNumero}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.perApellidos + " " + row.perNombres}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {new Date(row.fecVinculacion).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })}
          </TableCell>
          {/* <CollapseCell
            celComprimida={celComprimida}
            setcelComprimida={setcelComprimida}
            row={row}
            propiedad={"perTurno"}
            claseCelda={claseCelda}
          /> */}
          <TableCell
            size="small"
            align="center"
            className={`${claseCelda} ${classes.hover}`}
            onDoubleClick={() =>
              setcelComprimida({
                ...celComprimida,
                perTurno: !celComprimida["perTurno"],
              })
            }
          >
            <>
              {!celComprimida["perTurno"] ? (
                <>
                  {row.perTurno.map((puesto, index) => {
                    if (index > 0) {
                      return null;
                    } else {
                      return (
                        puesto.puestoIngreso +
                        " - " +
                        puesto.puestoSalida
                      ).substr(0, 30);
                    }
                  })}
                  {row.perTurno.length === 0 ? null : <strong>...</strong>}
                </>
              ) : (
                <>
                  {row.perTurno.map((puesto, index) => {
                    return puesto.puestoIngreso + " - " + puesto.puestoSalida;
                  })}{" "}
                </>
              )}
            </>
          </TableCell>{" "}
          <TableCell size="small" align="center" className={`${claseCelda} `}>
            <>
              <Typography
                variant="body2"
                sx={{ fontSize: ".8rem" }}
                color="initial"
                fontWeight={"bold"}
              >
                TTHH:{" "}
                <Typography
                  variant="body2"
                  sx={{ fontSize: ".8rem" }}
                  color={row.procesos?.tthh === "PENDIENTE" ? "error" : "green"}
                  component={"span"}
                >
                  {row.procesos?.tthh}
                </Typography>
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: ".8rem" }}
                color="initial"
                fontWeight={"bold"}
              >
                Logistica:{" "}
                <Typography
                  variant="body2"
                  sx={{ fontSize: ".8rem" }}
                  color={
                    row.procesos?.logistica === "PENDIENTE" ? "error" : "green"
                  }
                  component={"span"}
                >
                  {row.procesos?.logistica}
                </Typography>
              </Typography>{" "}
              <Typography
                variant="body2"
                sx={{ fontSize: ".8rem" }}
                color="initial"
                fontWeight={"bold"}
              >
                Operación:{" "}
                <Typography
                  variant="body2"
                  sx={{ fontSize: ".8rem" }}
                  color={
                    row.procesos?.logistica === "PENDIENTE" ? "error" : "green"
                  }
                  component={"span"}
                >
                  {row.procesos?.logistica}
                </Typography>
              </Typography>
            </>
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.perAreaDepartamento}
          </TableCell>{" "}
          <TableCell size="small" align="center" className={claseCelda}>
            {row.perCiudad}
          </TableCell>{" "}
          <TableCell size="small" align="center" className={claseCelda}>
            {row.perCtaBancaria}
          </TableCell>{" "}
          <TableCell size="small" align="center" className={claseCelda}>
            {row.perEmail.join(" - ")}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.perTelefono.join(", ")}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {new Date(row.perFecReg).toLocaleString("es-US", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })}
            {/* 
          {" OTRA FECHA "}
          {FechaExacta} */}
          </TableCell>
          <TableCell
            size="small"
            align="center"
            className={claseCelda}
            onClick={() => handleGetNameUser(row.perUser.split("/+/")[0])}
          >
            {row.perUser.split("/+/")[0]}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));

import { Paper, Table, TableBody, TableCell, TableHead } from "@mui/material";
import React from "react";
import ToolBarTable from "../../../components/ToolBarTable";
import TablaCabecera from "../../../components/TablaCabecera";
import {
  columnsHijosDevolucion,
  columnsHijos,
  columnsHijosPedido,
} from "../../data";
import { useState } from "react";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { useContext } from "react";
import { useEffect } from "react";
import Cargando from "../../../components/Cargando";
import RowHijo from "./Componentes/RowHijo";
import { Cancel, ChangeCircle, Refresh } from "@mui/icons-material";
import { usePermiso } from "../../../hooks/usePermiso";
import { useCrud } from "../../../hooks/useCrud";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
const Hijo = (props) => {
  const {
    // ROW
    rowPadre,
    // OPEN Y EDIT
    setopen,
    seteditar,
    // EXPORT
    campoExport,
    arrayExport,
    setarrayExport,
    // DATA
    // expandir,
    tabla,
    estadoRequest,
    // FUNCION STATE
    getDataState,
    changeObjetoActivoState,
    // SOCKET
    socket,
    fromIOP,
  } = props;

  const fecha = rowPadre.distri_FecUpd;
  const cliente = "";
  // const cliente = rowPadre.distri_DocuCliente;
  const {
    usuario: { rucempresa },
    usuario,
  } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  // DATA DEL STATE
  // REGISTROS O FILAS
  const [rows, setrows] = useState(null);
  // BOOLEANO DE SI ESTA CARGANDO
  const [cargando, setcargando] = useState(false);
  // BOLLEANO DE SI ESTA ABIERTO EL DIALOG DE SINCRONIZACION
  const [
    openConfirmDialogSincronizar,
    setopenConfirmDialogSincronizar,
  ] = useState(false);

  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Distribuciones");
  // ORDEN ASCENDENTE O DESCENDENTE
  const [orden, setorden] = useState(false);
  // STRING DE BUSQUEDA
  const [search] = useState("");
  // FUNCION QUE CREA EL OBJETO BUSCADOR
  const defaultFullBuscador = () => {
    const json = {};
    columnsHijos.forEach((itemPropiedad) => {
      Object.entries({ itemPropiedad }).forEach((item) => {
        if (itemPropiedad.tipo === "string") {
          json[`${item[1].propiedad}`] = "";
        } else if (itemPropiedad.tipo === "numero") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        } else if (itemPropiedad.tipo === "fecha") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        }
      });
    });
    return json;
  };
  // OBJETO BUSCADOR
  const [fullBuscador] = useState(defaultFullBuscador());
  // PAGINACION
  const [pagination, setpagination] = useState(null);
  //pagina actual
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // ORDEN DE POR DEFECTO
  const [input, setinput] = useState({
    id: "distri_FecUpd",
  });

  // FUNCION QUE COLOCA LA DATA EN EL STATE
  const obtenerState = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado,
    grupo,
    cliente,
    fecha
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estado,
      grupo,
      cliente,
      fecha
    );

    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };

  // FUNCION PARA CAMBIAR PAGINA
  const handleChangePage = async (event, newPage) => {
    if (newPage * rowsPerPage + rowsPerPage > rows.length) {
      setcargando(true);
      const res = await getDataState(
        rowsPerPage,
        newPage,
        search,
        input.id,
        orden,
        JSON.stringify(fullBuscador),
        rucempresa,
        estadoRequest,
        "hijo",
        cliente,
        fecha
      );

      const data = res.data.data.results;

      setcargando(false);
      const resultado = data.filter((row) => {
        const existe = rows.some((item, index) => {
          return row[campoExport] === item[campoExport];
        });

        return !existe;
      });

      setrows(resultado);
    }
    setPage(newPage);
  };

  // eslint-disable-next-line
  // FUNCION QUE CAMBIA LOS REGISTROS O FILAS POR PAGINA
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    obtenerState(
      event.target.value,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      "hijo",
      cliente,
      fecha
    );
  };
  // FUNCION QUE ORDENA DESCENDENTE O ASCENDENTEMENTE
  const funcionOrdenar = async (column) => {
    setcargando(true);
    await obtenerState(
      rowsPerPage,
      0,
      search,
      column.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      "hijo",
      cliente,
      fecha
    );
    setPage(0);
    setinput(column);
    setorden(!orden);
    setcargando(false);
  };
  // REFRES SEARCH SIN ALTERAR
  const funcionReload = async (sincronizar) => {
    setcargando(true);
    setRowsPerPage(10);
    setPage(0);
    await obtenerState(
      10,
      0,
      search,
      input.id,
      orden,
      JSON.stringify({ ...fullBuscador, sincronizar }),
      rucempresa,
      estadoRequest,
      "hijo",
      cliente,
      fecha
    );
    setcargando(false);
  };

  useEffect(() => {
    //eslint-disable-next-line

    obtenerState(
      10,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      "hijo",
      cliente,
      fecha
    );

    // eslint-disable-next-line
  }, []);
  const {
    agregarState,
    editarState,
    eliminarState,
    eliminarSeleccionState,
  } = useCrud(rows, setrows, campoExport, "distri_User", usuario);
  useEffect(() => {
    if (!rowPadre.itemSocket) {
      return;
    }
    if (!rows) {
      return;
    }
    const { tipo, item } = rowPadre.itemSocket;
    const hijo = rows.find((itemS) => {
      return itemS.distri_IdCliente === item.distri_IdCliente;
    });
    console.log({ pagination });
    if (hijo) {
      console.log({ rows, hijo });
      editarState({ ...hijo, tregistros: hijo.tregistros + 1 });
      // agregarState({ item, tregistros: 1 });
    } else {
      setpagination({ ...pagination, count: pagination.numRows + 1 });
      agregarState({ item, tregistros: 1 });
    }
    // if (tipo === "agregar") {
    //   agregarState(item);
    // }
    // if (tipo === "editar") {
    //   editarState(item);
    // }

    // const { tipo, item } = rowPadre.itemSocket;
    // if (item.distri_FecUpd !== fecha) {
    //   return;
    // }
    // const hijo = rows.find((itemS) => {
    //   return itemS.distri_IdCliente === item.distri_IdCliente;
    // });
    // if (hijo) {
    //   console.log({ rows, hijo });
    //   editarState({ ...item, tregistros: hijo.tregistros + 1 });
    //   agregarState({ item, tregistros: 1 });
    // } else {
    //   agregarState({ item, tregistros: 1 });
    // }

    // if (tipo === "eliminar") {
    //   eliminarState(item[campoExport]);
    // }
    // if (tipo === "eliminarSeleccion") {
    //   eliminarSeleccionState(item.lista);
    // }
    // eslint-disable-next-line
  }, [rowPadre]);
  if (!rows) {
    return <Cargando titulo="Cargando" />;
  }
  if (!pagination) {
    return <Cargando titulo="Cargando" />;
  }

  return (
    <>
      <ToolBarTable
        pagination={pagination}
        rowsPerPage={rowsPerPage}
        rows={rows}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        ocultarPaginacion={false}
        botonesIzquierdos={[]}
        botonesDerechos={
          [
            // {
            //   tooltip: "Exportar",
            //   texto: "",
            //   funcion: () => {
            //     setopenDialogOpciones(true);
            //   },
            //   disabled: arrayExport.length === 0,
            //   Icon: ListRounded,
            //   color: "primary",
            //   id: 1,
            //   ocultar: false,
            //   tipo: "icono",
            //   variante: "text",
            //   size: "medium",
            //   sx: { margin: 0, padding: 0 },
            // },
          ]
        }
      />
      <Paper>
        <Table size="small" aria-label="purchases">
          <TableHead>
            <TablaCabecera
              rows={rows}
              setarrayExport={setarrayExport}
              campoExport={campoExport}
              columns={
                columnsHijos
                // estadoRequest === "DISTRIBUCION"
                //   ? columnsHijos
                //   : estadoRequest === "PEDIDO"
                //   ? columnsHijosPedido
                //   : estadoRequest === "DEVOLUCION"
                //   ? columnsHijosDevolucion
                //   : []
              }
              habilitarOrdenar={true}
              agrupado={true}
              rowPadre={rowPadre}
              //nuevos
              input={input}
              orden={orden}
              funcionOrdenar={funcionOrdenar}
              ocultarPrimeraColumna={false}
            />
          </TableHead>
          <TableBody component={"div"}>
            {cargando ? (
              <TableCell
                size="small"
                align="center"
                colSpan={
                  columnsHijos
                  // estadoRequest === "DISTRIBUCION"
                  //   ? columnsHijos.length + 2
                  //   : estadoRequest === "PEDIDO"
                  //   ? columnsHijosPedido.length + 2
                  //   : estadoRequest === "DEVOLUCION"
                  //   ? columnsHijos.length + 2
                  //   : []
                }
              >
                <Cargando titulo="Cargando..." />
              </TableCell>
            ) : (
              rows.map((rowHijo, index) => {
                return (
                  <RowHijo
                    key={rowHijo.distri_Id}
                    getDataState={getDataState}
                    // HOOK DE PERMISO
                    tienePermiso={tienePermiso}
                    alertaPermiso={alertaPermiso}
                    // ROW
                    index={index}
                    rowHijo={rowHijo}
                    rowPadre={rowPadre}
                    // OPEN Y EDIT
                    setopen={setopen}
                    seteditar={seteditar}
                    // EXPORT
                    campoExport={campoExport}
                    arrayExport={arrayExport}
                    setarrayExport={setarrayExport}
                    // FUNCION STATE
                    changeObjetoActivoState={changeObjetoActivoState}
                    eliminarState={eliminarState}
                    // SOCKET
                    socket={socket}
                    tabla={tabla}
                    fromIOP={fromIOP}
                    // ESTADO
                    estadoRequest={estadoRequest}
                  />
                );
              })
            )}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default Hijo;

import React, { memo, useState, useContext, useEffect } from "react";
import Tabla from "./components/Tabla";
import ModalB from "./components/ModalB";
import BarraTablas from "../../../components/BarraTablas/BarraTablas";
import AuthContext from "../../../Context/Auth/AuthContext";
import DocumentosContext from "../../../Context/Configuraciones/Documentos/DocumentosContext";

// MATERIAL
import { Box, Button, Divider } from "@mui/material";
import { Add } from "@mui/icons-material";
const Documentos = () => {
  const [estadoRequest, setestadoRequest] = useState("DOCUMENTOS");
  const [buscando, setbuscando] = useState(false);
  const [buscados, setbuscados] = useState([]);
  const [search, setsearch] = useState("");
  const [cargando, setcargando] = useState(false);
  const [agregar, setagregar] = useState(false);
  const [input, setinput] = useState({
    id: "docId",
    label: "ID",
    minWidth: 150,
    tipo: "string",
    profundidad: 1,
    campo1: "docId",
  });
  const [orden, setorden] = useState(false);
  const [openMB, setopenMB] = useState(false);
  const [fullBuscador, setfullBuscador] = useState({
    docFecReg1: "",
    docFecReg2: "",
  });
  const {
    usuario: { rucempresa },
  } = useContext(AuthContext);
  //pagina actual
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // input
  //
  const handleChange = (e) => {
    setfullBuscador({ ...fullBuscador, [e.target.name]: e.target.value });
  };
  const funcionB = async () => {
    setcargando(true);
    await remplazarRows(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );
    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };
  const { rows, columns: columnsDocs, remplazarRows } = useContext(
    DocumentosContext
  );
  const columns =
    estadoRequest === "DOCUMENTOS"
      ? columnsDocs
      : [
          {
            id: "docDestinatario",
            label: "Destinatario",
            minWidth: 150,
            tipo: "string",
            campo1: "docDestinatario",
          },
          {
            id: "docAlcance",
            label: "Alcance",
            minWidth: 150,
            tipo: "string",
            campo1: "docAlcance",
          },

          {
            id: "docTitulo",
            label: "Titulo",
            minWidth: 150,
            tipo: "string",
            campo1: "docTitulo",
          },

          {
            id: "docFechaEmision",
            label: "F. Emision",
            minWidth: 150,
            tipo: "string",
            campo1: "docFechaEmision",
          },
          {
            id: "docFechaFinalizacion",
            label: "F. Finalizacion",
            minWidth: 150,
            tipo: "string",
            campo1: "docFechaFinalizacion",
          },
          {
            id: "docNotIns",
            label: "Notificacion",
            minWidth: 150,
            tipo: "string",
            campo1: "docNotIns",
          },
          {
            id: "docObligatorio",
            label: "Obligatorio",
            minWidth: 150,
            tipo: "string",
            campo1: "docObligatorio",
          },
          {
            id: "docFecReg",
            label: "FecReg",
            minWidth: 150,
            tipo: "string",
            campo1: "docFecReg",
          },

          {
            id: "docUser",
            label: "User",
            minWidth: 150,
            tipo: "string",
            campo1: "docUser",
          },
        ];
  useEffect(() => {
    if (rows && buscando) {
      const _ids = buscados.map((item) => item._id);

      const res = rows.filter((item) => {
        return _ids.indexOf(item._id) >= 0;
      });
      setbuscados(res);
    } // eslint-disable-next-line
  }, [rows]);

  const handleSearch = async () => {
    setcargando(true);
    setPage(0);
    await remplazarRows(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );
    setcargando(false);
  };
  const funcionDefault = async () => {
    setcargando(true);
    setfullBuscador({
      docFecReg1: "",
      docFecReg2: "",
    });
    await remplazarRows(
      rowsPerPage,
      0,
      "",
      input.id,
      orden,
      JSON.stringify({
        docFecReg1: "",
        docFecReg2: "",
      }),
      rucempresa,
      estadoRequest
    );
    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };
  return (
    <Box>
      <BarraTablas
        search={search}
        setsearch={setsearch}
        funcion={handleSearch}
        buscando={buscando}
        setbuscando={setbuscando}
        LabelBuscador="Buscar por todos los campos"
        funcionDefault={funcionDefault}
        botones={[
          {
            tooltip: "Agregar",
            texto: "Agregar",
            funcion: () => {
              setagregar(true);
            },
            disabled: false,
            Icon: Add,
            color: "primary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
        ocultarMigasDePan={false}
      />
      <ModalB
        openMB={openMB}
        setopenMB={setopenMB}
        titulo={"Busqueda avanzada (Bancos)"}
        columns={columns}
        fullBuscador={fullBuscador}
        setfullBuscador={setfullBuscador}
        handleChange={handleChange}
        funcionB={funcionB}
        buscando={buscando}
        setbuscando={setbuscando}
        funcionDefault={funcionDefault}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems=" stretch"
        width="100%"
      >
        <Box>
          <Button
            size="small"
            variant={estadoRequest === "DOCUMENTOS" ? "contained" : null}
            onClick={async () => {
              setcargando(true);
              await remplazarRows(
                rowsPerPage,
                0,
                "",
                input.id,
                orden,
                JSON.stringify({
                  docFecReg1: "",
                  docFecReg2: "",
                }),
                rucempresa,
                "DOCUMENTOS"
              );

              setPage(0);
              setestadoRequest("DOCUMENTOS");
              setcargando(false);
            }}
            disableElevation
            style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
            color="primary"
          >
            DOCUMENTOS
          </Button>
        </Box>
      </Box>
      <Divider />
      <Tabla
        agregar={agregar}
        setagregar={setagregar}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rows={rows}
        search={search}
        input={input}
        orden={orden}
        setinput={setinput}
        setorden={setorden}
        cargando={cargando}
        setcargando={setcargando}
        fullBuscador={fullBuscador}
        estadoRequest={estadoRequest}
      />
    </Box>
  );
};

export default memo(Documentos);

import React, { memo, useContext } from "react";
import MuiImageSlider from "mui-image-slider";
import { useState } from "react";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../config/axios";
import { DropzoneDialog } from "react-mui-dropzone";
import AuthContext from "../../../../Context/Auth/AuthContext";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Dialog,
  MenuItem,
  Badge,
} from "@mui/material";
import { Cancel, Check, Visibility, Photo } from "@mui/icons-material";
import { FechaExacta } from "../../../../config/const";
import { uploadImagesHelper } from "../../../functions/uploads/subirImagenes";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));
const objetoDefault = {
  id: 0,
  // prodId: 1,
  tipoTransaccion: "EGRESO",
  cantidad: 0,
  responsable: "",
  detalle: "",
  fecha: FechaExacta,
  fotos: [],
};

const RowAgregarArma = (props) => {
  const [open, setOpen] = React.useState(false);
  const [imagenes, setimagenes] = useState([]);
  const [Openimagen, setOpenimagen] = useState(false);
  const [cargando, setcargando] = useState(false);
  const { usuario } = useContext(AuthContext);

  const { mostrarAlerta } = useContext(AlertaContext);
  const { setagregar, item, setmantenimientosData, mantenimientosData } = props;

  const [itemHijoNuevo, setitemHijoNuevo] = useState(objetoDefault);
  const handleChange = (e) => {
    setitemHijoNuevo({
      ...itemHijoNuevo,
      [e.target.name]: e.target.value,
    });
  };
  const botonDisabled = () => {
    if (
      itemHijoNuevo.fecha === "" ||
      itemHijoNuevo.responsable === "" ||
      itemHijoNuevo.detalle === "" ||
      itemHijoNuevo.cantidad <= 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  const classes = useRowStyles();

  const handleAgregar = async () => {
    try {
      setcargando(true);

      let resDocumentos = {
        data: {
          urls: itemHijoNuevo.fotos,
        },
      };

      if (imagenes.length > 0) {
        resDocumentos = await uploadImagesHelper(imagenes, []);
      }
      console.log({
        ...itemHijoNuevo,
        prodId: item.prodId,
        // hisUser: usuario.usuario,
        // hisEmpresa: usuario.rucempresa,
        fotos: resDocumentos.data.urls,
      });

      const resItem = await clienteAxios.post("/historial_productos", {
        ...itemHijoNuevo,
        prodId: item.prodId,
        // hisUser: usuario.usuario,
        // hisEmpresa: usuario.rucempresa,
        fotos: resDocumentos.data.urls,
      });

      const mantenimientosDataR = [resItem.data, ...mantenimientosData];
      mostrarAlerta("Historial Creado Correctamente", "success");
      setmantenimientosData(mantenimientosDataR);
      setimagenes([]);
      setcargando(false);
    } catch (error) {
      setcargando(false);
    }
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <DropzoneDialog
          dialogProps={{ maxWidth: "lg" }}
          dialogTitle={
            <Typography variant="h5" color="primary">
              Subir archivos
            </Typography>
          }
          dropzoneText={
            <>
              <Typography variant="h4" color="primary">
                Arrastra una imagen o da click aqui.
              </Typography>{" "}
            </>
          }
          acceptedFiles={["image/jpeg", "image/png", "image/bmp", "image/jpg"]}
          cancelButtonText={"Cancelar"}
          submitButtonText={"Aceptar"}
          maxFileSize={5000000}
          open={open}
          onClose={() => setOpen(false)}
          onSave={(files) => {
            setimagenes(files);
            setOpen(false);
          }}
          showPreviewsInDropzone={true}
          showPreviews={false}
          showAlerts={true}
          showFileNamesInPreview={false}
        />
        <TableCell padding="none" style={{ borderRight: "1px solid #ccc" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            ml={1}
            mr={1}
          >
            <IconButton
              className={classes.margin}
              disabled={cargando}
              color={cargando ? "inherit" : "secondary"}
              onClick={() => {
                setagregar(false);
                setitemHijoNuevo(objetoDefault);
              }}
            >
              <Cancel fontSize="small" />
            </IconButton>
            <IconButton
              className={classes.margin}
              disabled={botonDisabled() || cargando}
              color={botonDisabled() || cargando ? "inherit" : "secondary"}
              onClick={async () => {
                await handleAgregar();

                setagregar(false);
                setitemHijoNuevo(objetoDefault);
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
            <Box display={"flex"} alignItems={"center"}>
              <Badge
                badgeContent={imagenes.length}
                color="primary"
                sx={{ mx: 0.5 }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              ></Badge>
              <IconButton
                disabled={cargando}
                className={classes.margin}
                onClick={() => setOpen(true)}
              >
                <Photo fontSize="small" color="primary" />
              </IconButton>
            </Box>
          </Box>
        </TableCell>
        {/* las demas columnas */}

        <>
          {" "}
          <Dialog
            open={Openimagen}
            onClose={() => setOpenimagen(false)}
            aria-labelledby="draggable-dialog-title"
          >
            {itemHijoNuevo.fotos === 0 ? null : (
              <MuiImageSlider
                images={itemHijoNuevo.fotos.map((item) => item.url)}
                fitToImageHeight={true}
              />
            )}
          </Dialog>{" "}
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {itemHijoNuevo.fotos.length === 0 ? (
              "No hay imagenes"
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                padding="none"
                alignItems="center"
              >
                <IconButton
                  aria-label=""
                  size="small"
                  onClick={() => setOpenimagen(true)}
                  className={classes.margin}
                >
                  <Visibility size="small" color="secondary" />
                </IconButton>
              </Box>
            )}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              size="small"
              fullWidth
              value={itemHijoNuevo.tipoTransaccion}
              name="tipoTransaccion"
              onChange={(e) => handleChange(e)}
              select
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">Seleccione.</MenuItem>
              {["EGRESO", "INGRESO"].map((item) => {
                return <MenuItem value={item}>{item} </MenuItem>;
              })}
            </TextField>
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              type="number"
              size="small"
              fullWidth
              value={itemHijoNuevo.cantidad}
              name="cantidad"
              onChange={(e) =>
                setitemHijoNuevo({
                  ...itemHijoNuevo,
                  [e.target.name]: Math.max(0, e.target.value),
                })
              }
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              type="date"
              size="small"
              fullWidth
              value={itemHijoNuevo.fecha}
              name="fecha"
              onChange={(e) => handleChange(e)}
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              size="small"
              fullWidth
              value={itemHijoNuevo.responsable}
              name="responsable"
              onChange={(e) => handleChange(e)}
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              multiline
              rows={1}
              size="small"
              fullWidth
              value={itemHijoNuevo.detalle}
              name="detalle"
              onChange={(e) => handleChange(e)}
            />
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarArma);

import React, { memo, useContext } from "react";

import { TableCell, TableRow } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { Cancel, Create, DeleteForever, Man, Woman2 } from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { usePermiso } from "../../../hooks/usePermiso";
import { useGetNameUser } from "../../../hooks/useGetNameUser";

const Row = (props) => {
  const classes = useRowStyles();
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    seteditar,
    setopen,
    changeObjetoActivoState,
    socket,
  } = props;
  const { tienePermiso, alertaPermiso } = usePermiso("Proveedores");
  const { usuario } = useContext(AuthContext);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const { handleGetNameUser } = useGetNameUser();
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "proveedor",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        planUser: usuario.usuario,
        planEmpresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcionEdit = () => {
    setopen(true);
    seteditar(true);
    changeObjetoActivoState(row);
  };

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={row}
          botones={[
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: true,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                funcionEdit();
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 2,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              texto: "",
              tooltip: row.genero,
              funcion: async () => {},
              Icon: row.genero === "MASCULINO" ? Man : Woman2,
              color: "success",
              disabled: false,
              id: 6,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />
        <>
          <TableCell size="small" align="center" className={classes.celda}>
            {row.cliDocTipo}
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            {row.cliDocNumero}
          </TableCell>
          <TableCell size="small" align="left" className={classes.celda}>
            {row.cliRazonSocial}
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            {row.cliTelefono}
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            {row.cliCelular}
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            {row.perObligado}
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            {row.perEmail}
          </TableCell>{" "}
          <TableCell
            size="small"
            align="center"
            className={classes.celda}
            onClick={() => handleGetNameUser(row.cliUser.split("/+/")[0])}
          >
            {row.cliUser.split("/+/")[0]}
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            {new Date(row.cliFecReg).toLocaleString("es-US", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));

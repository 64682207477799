export const columns = [
  {
    id: "bodeNombre",
    label: "Bodega",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "bodeNombre",
    align: "center",
    ocultar: true,
  },
  {
    id: "bodeCiudad",
    label: "Ciudad  ",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "bodeCiudad",
    align: "center",
    ocultar: true,
  },
  {
    id: "bodeIdResponsable",
    label: "Responsable",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "bodeIdResponsable",
    align: "center",
    ocultar: true,
  },
  {
    id: "bodeDireccion",
    label: "Dirección",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "bodeDireccion",
    align: "center",
    ocultar: true,
  },
  {
    id: "bodeCelular",
    label: "Celular",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "bodeCelular",
    align: "center",
    ocultar: true,
  },
  {
    id: "bodeEmail",
    label: "Email",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "bodeEmail",
    align: "center",
    ocultar: true,
  },

  {
    id: "bodeFecReg",
    label: "Fec. Reg.",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "bodeFecReg",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  // bodeId: 2,
  bodeNombre: "",
  bodeDireccion: "",
  bodeCelular: "",
  bodeEmail: "",
  bodeIdResponsable: "",
  bodeEstado: "ACTIVA",
  bodeEmpresa: "",
  bodeUser: "",
  bodeFecReg: "",
  bodeFecUpd: "",
  bodeNombreResponsable: "",
  perApellidos: "",
  perNombres: "",
};

import React, { memo, useContext, useEffect } from "react";

import { useState } from "react";
import MuiImageSlider from "mui-image-slider";
// import ConfirmacionDialog from "../../Dialog/ConfirmacionDialog";

import { withRouter } from "react-router";

import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../config/axios";

// MATERIAL
import {
  TableCell,
  TableRow,
  TextField,
  FormControl,
  Popper,
  MenuItem,
  Dialog,
  Box,
  IconButton,
  Autocomplete,
  Typography,
  Badge,
  Divider,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputAdornment,
} from "@mui/material";
import {
  Cancel,
  Check,
  Create,
  DeleteForever,
  ExpandLess,
  ExpandMore,
  Photo,
  Restore,
  Visibility,
} from "@mui/icons-material";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import ButtonsAction from "../../../components/ButtonsAction";
import { DropzoneDialog } from "react-mui-dropzone";
import Cantidades from "../Cantidades/Cantidades";
import { uploadImagesHelper } from "../../../functions/uploads/subirImagenes";

const RowArma = (props) => {
  const classes = useRowStyles();
  const {
    bodegaData,
    ver,
    item,
    setitem,
    row,
    index,
    devolucion,
    vestimenta,
    armas,
    municion,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  }`;
  const [itemHijoEdit, setitemHijoEdit] = useState(row);
  const [cargado, setcargado] = useState(false);
  useEffect(() => {
    if (cargado) {
      return funcSubmit();
    }
    setcargado(true);
    //eslint-disable-next-line
  }, [itemHijoEdit.cantidades]);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [imagenes, setimagenes] = useState([]);
  const [Openimagen, setOpenimagen] = useState(false);
  const [openDevolucion, setOpenDevolucion] = useState(false);
  const [cantidadDevolver, setcantidadDevolver] = useState(0);
  const [cargando, setcargando] = useState(false);

  const [open, setOpen] = useState(false);
  const [productosData, setproductosData] = useState([]);
  const [expandido, setexpandido] = useState(false);
  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };

  const handleEliminar = async (_id) => {
    setitem({
      ...item,
      distri_productos: item.distri_productos.filter((item, itemIndex) => {
        return item.prod_distri_Id !== row.prod_distri_Id;
      }),
    });
  };

  const [editar, seteditar] = useState(false);

  const botonDisabled = () => {
    if (item.distri_Tipo === "BIENES CLIENTE") {
      console.log(itemHijoEdit.prodPrecioUnitario > 0);

      return (
        itemHijoEdit.prodPrecioUnitario <= 0 ||
        itemHijoEdit.prodNombre.trim() === ""
      );
    }
    if (
      itemHijoEdit.nombre === "" ||
      itemHijoEdit.marca === "" ||
      itemHijoEdit.modelo === "" ||
      itemHijoEdit.talla === "" ||
      itemHijoEdit.color === "" ||
      itemHijoEdit.prodId === 0
      // ||
      // itemHijoEdit.cantidades.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  const funcSubmit = async () => {
    const existe = item.distri_productos.some((productoS) => {
      console.log(
        productoS.prodId,
        itemHijoEdit.prodId,
        "---",
        itemHijoEdit.prodId,
        row.prodId,
        "---",
        productoS.talla,
        itemHijoEdit.talla
      );

      return (
        productoS.prodId === itemHijoEdit.prodId &&
        productoS.talla === itemHijoEdit.talla &&
        itemHijoEdit.prodId !== row.prodId &&
        itemHijoEdit.talla !== row.talla
      );
    });
    if (existe) {
      return mostrarAlerta("Este producto ya se encuentra registrado", "error");
    }
    setcargando(true);
    try {
      let resDocumentos = {
        data: {
          urls: itemHijoEdit.fotos,
        },
      };

      if (imagenes.length > 0) {
        resDocumentos = await uploadImagesHelper(imagenes, itemHijoEdit.fotos);
      }
      setitem({
        ...item,
        distri_productos: item.distri_productos.map((item, itemIndex) => {
          if (item.prod_distri_Id === row.prod_distri_Id) {
            return { ...itemHijoEdit, fotos: resDocumentos.data.urls };
          } else {
            return item;
          }
        }),
      });
      setimagenes([]);
      setcargando(false);
      seteditar(false);
    } catch (error) {
      mostrarAlerta("Hubo un error", "error");
      setcargando(false);
      seteditar(false);
    }
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  useEffect(() => {
    if (editar) {
      const getData = async () => {
        try {
          const res = await clienteAxios.get(
            `/productos/search/para/distribucion?tipo_producto=${itemHijoEdit.prodTipo}&id_bodega=${itemHijoEdit.idBodega}&search=${itemHijoEdit.prodNombre}`
          );

          setproductosData(res.data);
        } catch (error) {
          return mostrarAlerta("No hay clientes", "error");
        }
      };
      getData();
    } // eslint-disable-next-line
  }, [editar]);

  return (
    <React.Fragment>
      <DropzoneDialog
        dialogProps={{ maxWidth: "lg" }}
        dialogTitle={
          <Typography variant="h5" color="primary">
            Subir archivos
          </Typography>
        }
        dropzoneText={
          <>
            <Typography variant="h4" color="primary">
              Arrastra una imagen o da click aqui.
            </Typography>
            {editar ? (
              <Typography variant="h5" color="error">
                Subir nuevas imagenes eliminará las anteriores.
              </Typography>
            ) : null}
          </>
        }
        acceptedFiles={["image/jpeg", "image/png", "image/bmp", "image/jpg"]}
        cancelButtonText={"Cancelar"}
        submitButtonText={"Aceptar"}
        maxFileSize={5000000}
        open={open}
        onClose={() => setOpen(false)}
        onSave={(files) => {
          setimagenes(files);
          setOpen(false);
        }}
        showPreviewsInDropzone={true}
        showPreviews={false}
        showAlerts={false}
        showFileNamesInPreview={false}
      />
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          botones={[
            {
              tooltip: "Eliminar",
              texto: "",
              funcion: () => {
                setopenConfirmDialog(true);
              },
              disabled: ver || devolucion,
              Icon: DeleteForever,
              color: "error",
              ocultar: editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar",
              texto: "",
              funcion: () => {
                seteditar(true);
                setitemHijoEdit(row);
              },
              disabled: ver || devolucion,
              Icon: Create,
              color: "secondary",
              ocultar: editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },

            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                seteditar(false);
                setitemHijoEdit(row);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: cargando || botonDisabled(),
              Icon: Check,
              color: "success",
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Ver fotos",
              texto: editar ? imagenes.length : itemHijoEdit.fotos.length,
              funcion: async () => {
                setOpen(true);
              },
              disabled: false,
              Icon: Photo,
              color: "primary",
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
            {
              tooltip: "Expandir",
              texto: "",
              funcion: async () => {
                setexpandido((prev) => !prev);
              },
              disabled: false,
              Icon: expandido ? ExpandLess : ExpandMore,
              color: "primary",
              id: 2,
              ocultar: true,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
            {
              tooltip: "Devolución",
              texto: "",
              funcion: async () => {
                setOpenDevolucion(true);
              },
              disabled: false,
              Icon: Restore,
              color: "primary",
              id: 2,
              ocultar: !ver,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
          ]}
        />
        <Dialog
          open={openDevolucion}
          onClose={() => setOpenDevolucion(false)}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle id={"devolucion"}>Devolución</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ mb: 2 }}>
              Cantidad por devolver: {row.cantidad - row.cantidad_devuelta}
            </DialogContentText>
            <TextField
              label="Cantidad a devolver"
              type="number"
              value={cantidadDevolver}
              onChange={(e) => {
                setcantidadDevolver(
                  Math.max(
                    -row.cantidad_devuelta,
                    Math.min(
                      row.cantidad - row.cantidad_devuelta,
                      e.target.value
                    )
                  )
                );
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={async () => {
                if (cantidadDevolver === 0) {
                  return mostrarAlerta(
                    "La cantidad debe ser mayor a 0",
                    "error"
                  );
                }
                try {
                  const res = await clienteAxios.put(
                    `devoluciones/actualizarCantidadDevuelta/${row.prod_distri_Id}`,
                    {
                      cantidadDevuelta: cantidadDevolver,
                    }
                  );
                  console.log({ res });
                  setitem({
                    ...item,
                    distri_productos: item.distri_productos.map(
                      (item, itemIndex) => {
                        if (item.prod_distri_Id === row.prod_distri_Id) {
                          return {
                            ...item,
                            cantidad_devuelta:
                              row.cantidad_devuelta + cantidadDevolver,
                          };
                        } else {
                          return item;
                        }
                      }
                    ),
                  });
                  setcantidadDevolver(0);
                  setOpenDevolucion(false);
                  mostrarAlerta("Devolución realizada", "success");
                } catch (error) {
                  console.log({ error });

                  mostrarAlerta(
                    "Hubo un error al realizar la devolución",
                    "error"
                  );
                }
              }}
              color="success"
              variant="contained"
            >
              CONFIRMAR
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={Openimagen}
          onClose={() => setOpenimagen(false)}
          aria-labelledby="draggable-dialog-title"
        >
          {row.fotos.length === 0 ? null : (
            <MuiImageSlider
              images={row.fotos.map((item) => item.url)}
              fitToImageHeight={true}
            />
          )}
        </Dialog>
        {ver && (
          <TableCell size="small" align="center" className={claseCelda}>
            {row.cantidad_devuelta}
          </TableCell>
        )}
        <TableCell size="small" align="center" className={claseCelda}>
          {row.fotos.length === 0 ? (
            "No hay imagenes"
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              padding="none"
              alignItems="center"
            >
              <Badge
                badgeContent={itemHijoEdit.fotos.length}
                color={editar ? "warning" : "success"}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              ></Badge>
              <IconButton
                aria-label=""
                size="small"
                onClick={() => setOpenimagen(true)}
                className={classes.margin}
              >
                <Visibility size="small" color="secondary" />
              </IconButton>
            </Box>
          )}
        </TableCell>
        {editar ? (
          <>
            {item.distri_Tipo !== "BIENES CLIENTE" && (
              <TableCell size="small" align="left" className={claseCelda}>
                <FormControl fullWidth>
                  <Autocomplete
                    PopperComponent={PopperMy}
                    disableClearable={true}
                    options={bodegaData.map((item) => {
                      return item.bodeNombre + "/+/" + item.bodeId;
                    })}
                    getOptionLabel={(option) => {
                      return option.split("/+/")[0];
                    }}
                    id="controlled-demo"
                    value={itemHijoEdit.bodega}
                    paperprops
                    onChange={async (event, newValue) => {
                      if (newValue) {
                        const options = newValue.split("/+/");
                        setproductosData([]);
                        setitemHijoEdit({
                          ...itemHijoEdit,

                          prodId: 0,
                          prodTipo: "",
                          prodSerie: "",
                          prodNombre: "",
                          prodMarca: "",
                          prodModelo: "",
                          talla: "",
                          prodColor: "",
                          prodTipoArma: "",
                          prodCalibre: "",
                          prodMaterial: "",
                          prodPrecioUnitario: "",
                          cantidad: 1,
                          T_disponible: "",
                          prod_distCondicion: itemHijoEdit.prod_distCondicion,
                          bodega: options[0],
                          idBodega: options[1],
                          prodTipo: itemHijoEdit.prodTipo,
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </TableCell>
            )}
            {vestimenta && (
              <TableCell size="small" align="left" className={claseCelda}>
                <TextField
                  fullWidth
                  size="small"
                  id="prodTipo"
                  value={itemHijoEdit.prodTipo}
                  name="prodTipo"
                  onChange={async (e) => {
                    setproductosData([]);
                    setitemHijoEdit({
                      ...itemHijoEdit,
                      prodId: 0,
                      prodTipo: "",
                      prodSerie: "",
                      prodNombre: "",
                      prodMarca: "",
                      prodModelo: "",
                      talla: "",
                      prodColor: "",
                      prodTipoArma: "",
                      prodCalibre: "",
                      prodMaterial: "",
                      prodPrecioUnitario: "",
                      cantidad: 1,
                      T_disponible: "",
                      prod_distCondicion: itemHijoEdit.prod_distCondicion,
                      bodega: itemHijoEdit.bodega,
                      idBodega: itemHijoEdit.idBodega,
                      prodTipo: e.target.value,
                    });
                  }}
                  select
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ displayEmpty: true }}
                >
                  <MenuItem value="">Seleccione.</MenuItem>
                  {["VESTIMENTAS", "EQUIPOS"].map((item) => {
                    return <MenuItem value={item}>{item} </MenuItem>;
                  })}
                </TextField>
              </TableCell>
            )}
            <TableCell size="small" align="left" className={claseCelda}>
              <TextField
                fullWidth
                size="small"
                value={itemHijoEdit.prod_distCondicion}
                name="prod_distCondicion"
                onChange={async (e) => {
                  setproductosData([]);
                  setitemHijoEdit({
                    ...itemHijoEdit,
                    prodId: 0,
                    prodTipo: "",
                    prodSerie: "",
                    prodNombre: "",
                    prodMarca: "",
                    prodModelo: "",
                    talla: "",
                    prodColor: "",
                    prodTipoArma: "",
                    prodCalibre: "",
                    prodMaterial: "",
                    prodPrecioUnitario: "",
                    cantidad: 1,
                    T_disponible: "",
                    prod_distCondicion: e.target.value,
                    prodTipo: itemHijoEdit.prodTipo,
                    bodega: itemHijoEdit.bodega,
                    idBodega: itemHijoEdit.idBodega,
                  });
                }}
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="">Seleccione.</MenuItem>
                {[
                  "NUEVO",
                  "USADO",
                  "OPERABLE",
                  "BUEN ESTADO",
                  "DEFECTUOSO",
                ].map((item) => {
                  return <MenuItem value={item}>{item} </MenuItem>;
                })}
              </TextField>
            </TableCell>
            {item.distri_Tipo === "BIENES CLIENTE" ? (
              <>
                <TableCell size="small" align="center" className={claseCelda}>
                  <TextField
                    fullWidth
                    size="small"
                    value={itemHijoEdit.prodNombre}
                    onChange={(e) => {
                      setitemHijoEdit({
                        ...itemHijoEdit,
                        prodNombre: e.target.value,
                      });
                    }}
                  />
                </TableCell>
              </>
            ) : (
              <TableCell size="small" align="left" className={claseCelda}>
                <FormControl fullWidth>
                  <Autocomplete
                    disabled={
                      !itemHijoEdit.idBodega ||
                      (!itemHijoEdit.prodTipo && vestimenta)
                    }
                    PopperComponent={PopperMy}
                    disableClearable={true}
                    options={productosData.map((item) => {
                      return itemHijoEdit.prodTipo === "VESTIMENTAS"
                        ? item.prodNombre +
                            "/+/" +
                            item.talla +
                            "/+/" +
                            item.prodSerie +
                            "/+/" +
                            item.T_disponible +
                            "/+/" +
                            item.prodId
                        : item.prodNombre +
                            "/+/" +
                            item.prodSerie +
                            "/+/" +
                            item.T_disponible +
                            "/+/" +
                            item.prodId;
                    })}
                    getOptionLabel={(option) => {
                      return itemHijoEdit.prodTipo === "VESTIMENTAS"
                        ? option
                            .replace("/+/", " T: ")
                            .replace("/+/", "** ")
                            .replace("/+/", "** ")
                            .split("/+/")[0]
                        : option
                            .replace("/+/", "** ")
                            .replace("/+/", "** ")
                            .split("/+/")[0];
                    }}
                    id="controlled-demo"
                    value={
                      `${itemHijoEdit.prodNombre} / ${itemHijoEdit.prodSerie}`.trim() ===
                      "/"
                        ? ""
                        : `${itemHijoEdit.prodNombre} / ${itemHijoEdit.prodSerie}`
                    }
                    onChange={async (event, newValue) => {
                      if (newValue) {
                        if (itemHijoEdit.prodTipo === "VESTIMENTAS") {
                          const [, talla, , , prodId] = newValue.split("/+/");

                          const itemF = productosData.find((itemProducto) => {
                            return (
                              itemProducto.prodId === Number(prodId) &&
                              itemProducto.talla === Number(talla)
                            );
                          });
                          if (!itemF) {
                            return mostrarAlerta("Hubo un error");
                          }
                          setitemHijoEdit({
                            ...itemHijoEdit,
                            prodTipo: itemF.prodTipo,
                            prodSerie: itemF.prodSerie,
                            prodNombre: itemF.prodNombre,
                            prodMarca: itemF.prodMarca,
                            prodModelo: itemF.prodModelo,
                            talla: itemF.talla,
                            prodColor: itemF.prodColor,
                            prodTipoArma: itemF.prodTipoArma,
                            prodCalibre: itemF.prodCalibre,
                            prodMaterial: itemF.prodMaterial,
                            prodPrecioUnitario: itemF.prodPrecioUnitario,
                            cantidad: 1,
                            T_disponible: itemF.T_disponible,
                            prodId: itemF.prodId,
                          });
                        } else {
                          const [, , , prodId] = newValue.split("/+/");

                          const itemF = productosData.find(
                            (itemProducto) =>
                              itemProducto.prodId === Number(prodId)
                          );
                          if (!itemF) {
                            return mostrarAlerta("Hubo un error");
                          }
                          setitemHijoEdit({
                            ...itemHijoEdit,
                            prodTipo: itemF.prodTipo,
                            prodSerie: itemF.prodSerie,
                            prodNombre: itemF.prodNombre,
                            prodMarca: itemF.prodMarca,
                            prodModelo: itemF.prodModelo,
                            talla: itemF.talla,
                            prodColor: itemF.prodColor,
                            prodTipoArma: itemF.prodTipoArma,
                            prodCalibre: itemF.prodCalibre,
                            prodMaterial: itemF.prodMaterial,
                            prodPrecioUnitario: itemF.prodPrecioUnitario,
                            cantidad: 1,
                            T_disponible: itemF.T_disponible,
                            prodId: itemF.prodId,
                          });
                        }
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        required
                        fullWidth
                        onChange={async (e) => {
                          try {
                            const res = await clienteAxios.get(
                              `/productos/search/para/distribucion?tipo_producto=${itemHijoEdit.prodTipo}&id_bodega=${itemHijoEdit.idBodega}&search=${e.target.value}&condicion=${itemHijoEdit.prod_distCondicion}`
                            );
                            console.log({ res });

                            setproductosData(res.data);
                          } catch (error) {
                            console.log({ error });
                          }
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </TableCell>
            )}
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                fullWidth
                type="number"
                disabled={
                  !itemHijoEdit.idBodega &&
                  !itemHijoEdit.prodTipo &&
                  !itemHijoEdit.prodNombre
                }
                size="small"
                value={itemHijoEdit.cantidad}
                onChange={(e) => {
                  setitemHijoEdit({
                    ...itemHijoEdit,
                    cantidad: Math.max(
                      Math.min(
                        item.distri_Tipo === "BIENES CLIENTE"
                          ? 100
                          : itemHijoEdit.T_disponible,
                        e.target.valueAsNumber
                      ),
                      1
                    ).toFixed(2),
                  });
                }}
              />
            </TableCell>
            {item.distri_Tipo !== "BIENES CLIENTE" && vestimenta && (
              <TableCell size="small" align="center" className={claseCelda}>
                {itemHijoEdit.talla}
              </TableCell>
            )}
            {(armas || vestimenta) && (
              <TableCell size="small" align="center" className={claseCelda}>
                {item.distri_Tipo === "BIENES CLIENTE" ? (
                  <TextField
                    fullWidth
                    size="small"
                    value={itemHijoEdit.prodColor}
                    onChange={(e) => {
                      setitemHijoEdit({
                        ...itemHijoEdit,
                        prodColor: e.target.value,
                      });
                    }}
                  />
                ) : (
                  itemHijoEdit.prodColor
                )}
              </TableCell>
            )}
            <TableCell size="small" align="center" className={claseCelda}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                {item.distri_Tipo === "BIENES CLIENTE" ? (
                  <TextField
                    fullWidth
                    type="number"
                    size="small"
                    value={itemHijoEdit.prodPrecioUnitario}
                    onChange={(e) => {
                      setitemHijoEdit({
                        ...itemHijoEdit,
                        prodPrecioUnitario: Math.max(0, e.target.valueAsNumber),
                      });
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography
                            variant="inherit"
                            fontWeight={"bold"}
                            component={"span"}
                          >
                            $
                            {(
                              itemHijoEdit.prodPrecioUnitario *
                              itemHijoEdit.cantidad
                            ).toFixed(2)}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <>
                    <Typography variant="inherit" component={"span"}>
                      ${itemHijoEdit.prodPrecioUnitario}
                    </Typography>
                    <Divider sx={{ width: "100%" }} />
                    <Typography
                      variant="inherit"
                      fontWeight={"bold"}
                      component={"span"}
                    >
                      $
                      {(
                        itemHijoEdit.prodPrecioUnitario * itemHijoEdit.cantidad
                      ).toFixed(2)}
                    </Typography>
                  </>
                )}
              </Box>
            </TableCell>
          </>
        ) : (
          <>
            {item.distri_Tipo !== "BIENES CLIENTE" && (
              <TableCell size="small" align="center" className={claseCelda}>
                {row.bodega}
              </TableCell>
            )}
            {vestimenta && (
              <TableCell size="small" align="center" className={claseCelda}>
                {row.prodTipo}
              </TableCell>
            )}
            <TableCell size="small" align="center" className={claseCelda}>
              {row.prod_distCondicion}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {item.distri_Tipo === "BIENES CLIENTE"
                ? row.prodNombre
                : `${row.prodNombre} / ${row.prodSerie}`}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.cantidad}
            </TableCell>
            {item.distri_Tipo !== "BIENES CLIENTE" && vestimenta && (
              <TableCell size="small" align="center" className={claseCelda}>
                {row.talla}
              </TableCell>
            )}
            {(vestimenta || armas) && (
              <TableCell size="small" align="center" className={claseCelda}>
                {item.distri_Tipo === "BIENES CLIENTE" ? (
                  <Box p={1} sx={{ backgroundColor: row.prodColor }}></Box>
                ) : (
                  row.prodColor
                )}
              </TableCell>
            )}

            <TableCell size="small" align="center" className={claseCelda}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Typography variant="inherit" component={"span"}>
                  ${row.prodPrecioUnitario}
                </Typography>
                <Divider sx={{ width: "100%" }} />
                <Typography
                  variant="inherit"
                  fontWeight={"bold"}
                  component={"span"}
                >
                  ${(row.prodPrecioUnitario * row.cantidad).toFixed(2)}
                </Typography>
              </Box>
            </TableCell>
          </>
        )}
      </TableRow>
      {/* <Cantidades
        ver={ver}
        devolucion={devolucion}
        expandido={expandido}
        setexpandido={setexpandido}
        item={itemHijoEdit}
        setitem={setitemHijoEdit}
      /> */}
    </React.Fragment>
  );
};

export default withRouter(memo(RowArma));

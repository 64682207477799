import React, { useState, useContext, useEffect, memo } from "react";

import Draggable from "react-draggable";

// eslint-disable-next-line

import AlertaContext from "../../../Context/Alerta/AlertaContext";

import MenuContext from "../../../Context/Menu/MenuContext";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  TextField,
  FormControl,
  Divider,
  Typography,
  IconButton,
  InputLabel,
  Select,
  Input,
  MenuItem,
  useTheme,
  Icon,
  Chip,
  Autocomplete,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

import { Cancel, AddCircle, Delete } from "@mui/icons-material";
import * as iconosImport from "@mui/icons-material";
import AuthContext from "../../../Context/Auth/AuthContext";
import clienteAxios from "../../../config/axios";
import { v4 } from "uuid";
import CampoDoc from "../../../views/components/CampoDoc";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",

    overflow: "scroll",
    maxHeight: "80vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    width: 750,
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "8vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  ocultar: {
    display: "none",
  },
  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "40%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },

  form33: {
    flexGrow: 1,
    margin: theme.spacing(0.5),
    width: "32%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
}));

const ModalAsignatura = (props) => {
  const {
    categoriaActiva,
    changeCategoriaActiva, // eslint-disable-next-line
    editarCategoria,
    rows,
  } = useContext(MenuContext);
  const { usuario } = useContext(AuthContext);
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  //props
  const { open, setopen } = props;
  const [seccion, setseccion] = useState({
    principalSeccion: true,
    inicioSeccion: false,
    tutorialesSeccion: false,
  });
  const { principalSeccion, inicioSeccion, tutorialesSeccion } = seccion;
  const [categoria, setcategoria] = useState(categoriaActiva);
  const {
    name,
    // permisos,
    icon,
    orden,
    path,
    // bloquearAreaDepartamento,
    areasDepartamento = [],
    tutoriales,
  } = categoria.sidInfo;
  const bloquearFuncion = (item) => {
    if (item.sidInfo.bloquearAreaDepartamento === "SI") {
      const bloquear = item.sidInfo.areasDepartamento.some(
        (itemArea) => itemArea === usuario.areadepartamento
      );

      if (bloquear) {
        return false;
      } else {
        return true;
      }
    }
  };
  useEffect(() => {
    if (categoriaActiva.tipo === "Categoria") {
      setcategoria(categoriaActiva);
    }

    if (categoriaActiva.tipo === "subCategoria") {
      setcategoria(categoriaActiva);
      // categoria: "/Informes",
    }

    if (categoriaActiva.tipo === "subsubCategoria") {
      setcategoria(categoriaActiva);
      // subcategoria: "/Informes",
    }

    setcategoria(categoriaActiva);
  }, [categoriaActiva]);

  const defaultActive = () => {
    changeCategoriaActiva({
      sidId: 0,
      sidInfo: {
        orden: 1,
        mostrar: "NO",
        descripcion: "",
        path: "",
        name: "",
        icon: "",
        component: "",
        layout: "",
        tipo: "",
        ruta: false,
        expandible: true,
        permisos: ["USUARIO"],
        añadir: ["USUARIO"],
        editar: ["USUARIO"],
        ver: ["USUARIO"],
        eliminar: ["USUARIO"],
        impExp: ["USUARIO"],
        bloquearAreaDepartamento: "NO",
        tutoriales: [],
        areasDepartamento: [],
      },
    });
    setopen(false);
  };

  const handleChange = (e) => {
    setcategoria({
      ...categoria,
      sidInfo: {
        ...categoria.sidInfo,
        [e.target.name]: e.target.value,
      },
    });
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);
  function getStylesMenuItems(name, permisos, theme) {
    if (!permisos) {
      return {};
    }
    return {
      fontWeight:
        permisos.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
      backgroundColor: permisos.indexOf(name) === -1 ? "white" : "#31b2f7",
    };
  }
  // const handleEditar = async () => {
  //   // if (validarEmail(emaildepaypal)) {
  //   //   return mostrarAlerta("Email paypal no es valido ", "error");
  //   // }
  //   if (password !== "") {
  //
  //   } else {
  //
  //   }

  //   setopen(false);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStylesEspecialidades(name, permisos, theme) {
    return {
      fontWeight:
        permisos.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
      backgroundColor: permisos.indexOf(name) === -1 ? "white" : "#31b2f7",
    };
  }

  const theme = useTheme();

  const [iconos, seticonos] = useState(null);
  useEffect(() => {
    seticonos(Object.keys(iconosImport));
  }, []);

  const hacerIcono = (icono) => {
    if (icono === "" || !icono) {
      return;
    }
    const Icono = iconosImport[icono];

    return <Icono />;
  };
  const handleEditar = async () => {
    try {
      const existeVacio = tutorialRegistro.tutoContenido.some((item) => {
        return (
          item.nombreVideo === "" ||
          item.urlVideo === "" ||
          item.descVideo === "" ||
          item.orientacion === "" ||
          item.perfiles.length === 0
        );
      });

      if (existeVacio && usuario.rucempresa === "PRUEBA") {
        return mostrarAlerta(
          "Rellene los campos de todos los tutoriales",
          "error"
        );
      }
      editarCategoria(categoria);
      if (usuario.rucempresa === "PRUEBA") {
        await clienteAxios.put(`tutoriales/${tutorialRegistro.tutoId}`, {
          tutoContenido: tutorialRegistro.tutoContenido,
        });
      }
      defaultActive();
    } catch (error) {
      console.log({ error });

      mostrarAlerta("Hubo un error al actualizar");
    }
  };
  const [tutorialRegistro, settutorialRegistro] = useState({
    tutoContenido: [],
    tutoId: 0,
  });
  const handleChangeTutoriales = ({ index, name, value }) => {
    settutorialRegistro((prev) => ({
      tutoId: prev.tutoId,
      tutoContenido: prev.tutoContenido.map((item, indexItem) => {
        if (indexItem === index) {
          return {
            ...item,
            [name]: value,
          };
        } else {
          return item;
        }
      }),
    }));
  };
  const getTutoriales = async () => {
    try {
      console.log({ categoriaActiva, component: categoriaActiva.component });

      const res = await clienteAxios.get(
        `/tutoriales/byComponente?componente=${categoriaActiva.sidInfo.component}`
      );
      settutorialRegistro(res.data);
    } catch (error) {
      console.log({ error });
    }
  };
  useEffect(() => {
    getTutoriales();
  }, [categoriaActiva]);

  if (!iconos) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                style={{ cursor: "-webkit-grab" }}
              >
                <Typography id="handle" color="initial" align="center">
                  Editar categoria.
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>

              <Divider />

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  <Button
                    variant={principalSeccion ? "contained" : "text"}
                    disableElevation
                    style={{ padding: "8px" }}
                    color={"secondary"}
                    onClick={() => setseccion({ principalSeccion: true })}
                  >
                    Principal
                  </Button>
                  <Button
                    variant={inicioSeccion ? "contained" : "text"}
                    disableElevation
                    style={{ padding: "8px" }}
                    color={"secondary"}
                    onClick={() => setseccion({ inicioSeccion: true })}
                  >
                    Inicio
                  </Button>
                  {usuario.rucempresa === "PRUEBA" && (
                    <Button
                      variant={tutorialesSeccion ? "contained" : "text"}
                      disableElevation
                      style={{ padding: "8px" }}
                      color={"secondary"}
                      onClick={() => setseccion({ tutorialesSeccion: true })}
                    >
                      Tutoriales
                    </Button>
                  )}
                </Box>
              </Box>
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                style={{ maxHeight: "65vh", overflow: "scroll" }}
                p={1}
              >
                {principalSeccion ? (
                  <>
                    <>
                      <TextField
                        onChange={(e) => {
                          if (e.target.value === "categoria") {
                            setcategoria({
                              ...categoria,
                              sidInfo: {
                                ...categoria.sidInfo,
                                [e.target.name]: e.target.value,
                              },
                            });
                          }
                          if (e.target.value === "subCategoria") {
                            setcategoria({
                              ...categoria,
                              sidInfo: {
                                ...categoria.sidInfo,
                                categoria: "",
                                [e.target.name]: e.target.value,
                              },
                            });
                          }
                          if (e.target.value === "subsubCategoria") {
                            setcategoria({
                              ...categoria,
                              sidInfo: {
                                ...categoria.sidInfo,
                                subcategoria: "",
                                [e.target.name]: e.target.value,
                              },
                            });
                          }
                        }}
                        className={clases.formD}
                        name="tipo"
                        label="Tipo"
                        value={categoria.sidInfo.tipo}
                        required
                        fullWidth
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ displayEmpty: true }}
                      >
                        {["categoria", "subCategoria", "subsubCategoria"].map(
                          (item, index) => {
                            return (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            );
                          }
                        )}
                      </TextField>

                      {categoria.sidInfo.tipo === "subCategoria" ? (
                        <TextField
                          onChange={(e) => {
                            setcategoria({
                              ...categoria,
                              sidInfo: {
                                ...categoria.sidInfo,
                                [e.target.name]: e.target.value,
                              },
                            });
                          }}
                          className={clases.formD}
                          name="categoria"
                          label="Categoria"
                          value={categoria.sidInfo.categoria}
                          fullWidth
                          select
                          InputLabelProps={{ shrink: true }}
                        >
                          {rows
                            .filter(
                              (route) =>
                                (route.sidInfo.tipo === "categoria" ||
                                  !route.sidInfo.tipo) &&
                                route.sidInfo.path !== categoria.sidInfo.path &&
                                route.sidInfo.expandible
                            )
                            .map((prop, key) => {
                              if (bloquearFuncion(prop)) {
                                return null;
                              }

                              return (
                                <MenuItem key={key} value={prop.sidInfo.path}>
                                  {prop.sidInfo.path}
                                </MenuItem>
                              );
                            })}
                        </TextField>
                      ) : null}
                      {categoria.sidInfo.tipo === "subsubCategoria" ? (
                        <TextField
                          onChange={(e) => {
                            setcategoria({
                              ...categoria,
                              sidInfo: {
                                ...categoria.sidInfo,
                                [e.target.name]: e.target.value,
                              },
                            });
                          }}
                          className={clases.formD}
                          name="subcategoria"
                          label="Sub Categoria"
                          value={categoria.sidInfo.subcategoria}
                          fullWidth
                          select
                          InputLabelProps={{ shrink: true }}
                        >
                          {rows
                            .filter(
                              (route) =>
                                (route.sidInfo.tipo === "subCategoria" ||
                                  !route.sidInfo.tipo) &&
                                route.sidInfo.path !== categoria.sidInfo.path &&
                                route.sidInfo.expandible
                            )
                            .map((prop, key) => {
                              if (bloquearFuncion(prop)) {
                                return null;
                              }
                              return (
                                <MenuItem key={key} value={prop.sidInfo.path}>
                                  {prop.sidInfo.path}
                                </MenuItem>
                              );
                            })}
                        </TextField>
                      ) : null}
                    </>
                    <TextField
                      onChange={handleChange}
                      className={clases.formD}
                      name="name"
                      label="Nombre"
                      value={name}
                      required
                      fullWidth
                    />
                    <TextField
                      onChange={(e) => {
                        setcategoria({
                          ...categoria,
                          sidInfo: {
                            ...categoria.sidInfo,
                            [e.target.name]: e.target.value,
                          },
                        });
                      }}
                      className={clases.formD}
                      name="bloquearAreaDepartamento"
                      label="Bloquear Area Departamento"
                      value={categoria.sidInfo.bloquearAreaDepartamento}
                      required
                      fullWidth
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      {["SI", "NO"].map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <FormControl className={clases.formD}>
                      <InputLabel id="demo-mutiple-chip-label">
                        Areas Departamento
                      </InputLabel>
                      <Select
                        labelId="demo-mutiple-chip-label"
                        id="demo-mutiple-chip"
                        multiple
                        name="areasDepartamento"
                        value={areasDepartamento}
                        onChange={handleChange}
                        input={<Input id="select-multiple-chip" />}
                        MenuProps={MenuProps}
                        renderValue={(selected) => (
                          <div className={clases.chips}>
                            {selected.map((value, index) => {
                              return (
                                <>
                                  <Chip
                                    key={index}
                                    label={value}
                                    className={clases.chip}
                                  />
                                </>
                              );
                            })}
                          </div>
                        )}
                      >
                        {[
                          "ADMIN",
                          "CLIENTE",
                          "OPERATIVO-S",
                          "OPERATIVO-G",
                          "SACA FRANCO",
                          "ADMINISTRACION",
                          "LOGISTICA",
                          "GERENTE",
                          "ASISTENTE DE GERENCIA",
                          "JEFE DE OPERACIONES",
                          "COMPRAS PUBLICAS",
                          "TALENTO HUMANO",
                          "CONTADOR",
                          "SECRETARIA",
                          "SERVICIOS VARIOS",
                          "OTROS",
                        ].map((row, index) => (
                          <MenuItem
                            key={index}
                            value={row}
                            style={getStylesEspecialidades(
                              row,
                              areasDepartamento,
                              theme
                            )}
                          >
                            {row}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {/* <FormControl className={clases.formD}>
                <InputLabel id="demo-mutiple-chip-label">Permisos</InputLabel>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  name="permisos"
                  value={permisos}
                  onChange={handleChange}
                  input={<Input id="select-multiple-chip" />}
                  MenuProps={MenuProps}
                  renderValue={(selected) => (
                    <div className={clases.chips}>
                      {selected.map((value, index) => {
                        return (
                          <>
                            <Chip
                              key={index}
                              label={value}
                              className={clases.chip}
                            />
                          </>
                        );
                      })}
                    </div>
                  )}
                >
                  {[
                    "ADMIN",
                    "CLIENTE",
                    "GUARDIA",
                    "SUPERVISOR",
                    "ADMINISTRACION",
                  ].map((row, index) => (
                    <MenuItem
                      key={index}
                      value={row}
                      style={getStylesEspecialidades(row, permisos, theme)}
                    >
                      {row} 
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
                    <FormControl className={clases.formD} fullWidth>
                      <Autocomplete
                        options={iconos.map((node) => node)}
                        getOptionLabel={(option) => option}
                        id="controlled-demo"
                        value={icon}
                        name="a"
                        onChange={(event, newValue) => {
                          setcategoria({
                            ...categoria,
                            sidInfo: { ...categoria.sidInfo, icon: newValue },
                          });
                        }}
                        disableClearable
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label={
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                Icono Derecha <Icon> {hacerIcono(icon)}</Icon>
                              </Box>
                            }
                            fullWidth
                            name="a"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      onChange={(e) => {
                        setcategoria({
                          ...categoria,
                          sidInfo: {
                            ...categoria.sidInfo,
                            [e.target.name]: e.target.value,
                          },
                        });
                      }}
                      className={clases.formD}
                      type="number"
                      name="orden"
                      label="Orden"
                      value={orden}
                      required
                      fullWidth
                    />
                    {categoria.sidInfo.tipo === "subsubCategoria" ? (
                      <TextField
                        onChange={(e) => {
                          setcategoria({
                            ...categoria,
                            sidInfo: {
                              ...categoria.sidInfo,
                              [e.target.name]: e.target.value,
                            },
                          });
                        }}
                        className={clases.formD}
                        name="categoria"
                        label="SubCategoria"
                        value={categoria.sidInfo.categoria}
                        required
                        fullWidth
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ displayEmpty: true }}
                      >
                        {rows.map((item, index) => {
                          if (
                            item.sidInfo.tipo === "subCategoria" &&
                            item.sidInfo.expandible === true
                          ) {
                            return (
                              <MenuItem key={index} value={item.sidInfo.path}>
                                {item.sidInfo.path}
                              </MenuItem>
                            );
                          }
                          return null;
                        })}
                      </TextField>
                    ) : categoria.sidInfo.tipo === "subCategoria" ? (
                      <TextField
                        onChange={(e) => {
                          setcategoria({
                            ...categoria,
                            sidInfo: {
                              ...categoria.sidInfo,
                              [e.target.name]: e.target.value,
                            },
                          });
                        }}
                        className={clases.formD}
                        name="categoria"
                        label="Categoria"
                        value={categoria.sidInfo.subcategoria}
                        required
                        fullWidth
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ displayEmpty: true }}
                      >
                        {rows.map((item, index) => {
                          if (
                            item.sidInfo.tipo === "categoria" &&
                            item.sidInfo.expandible === true
                          ) {
                            return (
                              <MenuItem key={index} value={item.sidInfo.path}>
                                {item.sidInfo.path}
                              </MenuItem>
                            );
                          }
                          return null;
                        })}
                      </TextField>
                    ) : null}
                    <TextField
                      onChange={(e) => {
                        setcategoria({
                          ...categoria,
                          sidInfo: {
                            ...categoria.sidInfo,
                            [e.target.name]:
                              e.target.value.length < 2
                                ? categoria.sidInfo[e.target.name]
                                : e.target.value,
                          },
                        });
                      }}
                      className={clases.formD}
                      name="path"
                      label="Path"
                      value={path}
                      required
                      fullWidth
                    />
                    <Box mt={2} width="100%">
                      {/*  eslint-disable-next-line  */}
                      <a href="https://fonts.google.com/icons" target="_blank">
                        Ver Iconos
                      </a>
                    </Box>
                  </>
                ) : null}
                {inicioSeccion ? (
                  <>
                    <TextField
                      onChange={(e) => {
                        setcategoria({
                          ...categoria,
                          sidInfo: {
                            ...categoria.sidInfo,
                            [e.target.name]: e.target.value,
                          },
                        });
                      }}
                      className={clases.formD}
                      name="mostrar"
                      label="Mostrar"
                      value={categoria.sidInfo.mostrar}
                      required
                      fullWidth
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      {["SI", "NO"].map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <TextField
                      multiline
                      rows={3}
                      variant="outlined"
                      onChange={(e) => {
                        setcategoria({
                          ...categoria,
                          sidInfo: {
                            ...categoria.sidInfo,
                            [e.target.name]: e.target.value,
                          },
                        });
                      }}
                      className={clases.formAllW}
                      name="descripcion"
                      label="Descripción"
                      value={categoria.sidInfo.descripcion}
                      required
                      fullWidth
                    />
                  </>
                ) : null}
                {tutorialesSeccion ? (
                  <>
                    <>
                      <FormControl className={clases.formAllW}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent={"space-between"}
                        >
                          <Typography variant="subtitle1" color={"primary"}>
                            Tutorial:
                            <IconButton
                              aria-label=""
                              size="small"
                              onClick={async () => {
                                const existeVacio = tutorialRegistro.tutoContenido.some(
                                  (item) => {
                                    return (
                                      item.nombreVideo === "" ||
                                      item.urlVideo === "" ||
                                      item.descVideo === "" ||
                                      item.orientacion === "" ||
                                      item.perfiles.length === 0
                                    );
                                  }
                                );
                                if (existeVacio) {
                                  return mostrarAlerta(
                                    "Rellene los campos de todos los tutoriales antes de crear otro",
                                    "error"
                                  );
                                }
                                const nuevo = {
                                  nombreVideo: "",
                                  urlVideo: "",
                                  descVideo: "",
                                  orientacion: "WEB",
                                  perfiles: [],
                                  documento: "",
                                  id: v4(),
                                };

                                // const existeVacio = item.tutoriales.some(
                                //   (item) => {
                                //     if (item.tipo === "REFERENCIA") {
                                //       return (
                                //         item.tipo === "" ||
                                //         item.nombre === "" ||
                                //         item.telefono === ""
                                //       );
                                //     } else if (item.tipo === "INSTRUCCION") {
                                //       return (
                                //         item.tipo === "" ||
                                //         item.nombre === "" ||
                                //         item.nombreInstitucion === "" ||
                                //         item.desde === "" ||
                                //         item.hasta === ""
                                //       );
                                //     } else if (item.tipo === "EXPERIENCIA") {
                                //       return (
                                //         item.tipo === "" ||
                                //         item.nombre === "" ||
                                //         item.desde === "" ||
                                //         item.hasta === ""
                                //       );
                                //     } else {
                                //       return item.tipo === "";
                                //     }
                                //   }
                                // );
                                // if (existeVacio) {
                                //   return mostrarAlerta(
                                //     "Complete todos los campos.",
                                //     "error"
                                //   );
                                // }
                                // setcategoria({
                                //   ...categoria,
                                //   sidInfo: {
                                //     ...categoria.sidInfo,
                                //     tutoriales: [
                                //       ...tutoriales,
                                //       {
                                //         nombreVideo: "",
                                //         urlVideo: "",
                                //         descVideo: "",
                                //         orientacion: "WEB",
                                //         perfiles: [],
                                //       },
                                //     ],
                                //   },
                                // });
                                // const res = await clienteAxios.put(
                                //   `tutoriales/${tutorialRegistro.tutoId}`,
                                //   {
                                //     tutoContenido: [
                                //       nuevo,
                                //       ...tutorialRegistro.tutoContenido,
                                //     ],
                                //   }
                                // );
                                settutorialRegistro({
                                  ...tutorialRegistro,
                                  tutoContenido: [
                                    nuevo,
                                    ...tutorialRegistro.tutoContenido,
                                  ],
                                });
                              }}
                            >
                              <AddCircle fontSize="small" color="secondary" />
                            </IconButton>
                          </Typography>
                        </Box>
                      </FormControl>

                      {tutorialRegistro.tutoContenido
                        .sort((a, b) => a.orden - b.orden)
                        .map((dato, index) => {
                          return (
                            <>
                              <Box
                                display={"flex"}
                                justifyContent="space-between"
                                className={clases.formAllW}
                                key={index}
                              >
                                <Typography
                                  key={index}
                                  variant="button"
                                  color="primary"
                                  fontWeight={"700"}
                                >
                                  {`Tutorial #${index + 1}`}
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      settutorialRegistro((prev) => ({
                                        tutoId: prev.tutoId,
                                        tutoContenido: prev.tutoContenido.filter(
                                          (item, indexItem) =>
                                            indexItem !== index
                                        ),
                                      }));
                                    }}
                                  >
                                    <Delete color="error" fontSize="small" />
                                  </IconButton>
                                </Typography>
                              </Box>
                              {/* DATO */}
                              <TextField
                                className={clases.form33}
                                size="small"
                                label="Nombre"
                                value={dato.nombreVideo}
                                onChange={(e) => {
                                  handleChangeTutoriales({
                                    index,
                                    name: "nombreVideo",
                                    value: e.target.value,
                                  });
                                }}
                              />
                              <TextField
                                className={clases.form33}
                                size="small"
                                label="URL"
                                value={dato.urlVideo}
                                onChange={(e) => {
                                  handleChangeTutoriales({
                                    index,
                                    name: "urlVideo",
                                    value: e.target.value,
                                  });
                                }}
                              />
                              <TextField
                                className={clases.form33}
                                size="small"
                                label="Perfil"
                                name="perfiles"
                                value={dato.perfiles || []}
                                onChange={(e) => {
                                  handleChangeTutoriales({
                                    index,
                                    name: "perfiles",
                                    value: e.target.value,
                                  });
                                }}
                                select
                                MenuProps={MenuProps}
                                SelectProps={{
                                  displayEmpty: true,
                                  multiple: true,
                                  renderValue: (selected) => {
                                    return selected
                                      .map((value, index) => {
                                        return value;
                                      })
                                      .join(", ");
                                  },
                                }}
                              >
                                {[
                                  "GUARDIA",
                                  "SUPERVISOR",
                                  "ADMINISTRACION",
                                ].map((item) => {
                                  return (
                                    <MenuItem
                                      style={getStylesMenuItems(
                                        item,
                                        dato.perfiles,
                                        theme
                                      )}
                                      value={item}
                                    >
                                      {item}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                              <CampoDoc
                                setcargando={() => {}}
                                label="Documento"
                                propiedad={"documento"}
                                item={dato}
                                setitem={(e) => {
                                  settutorialRegistro((prev) => ({
                                    tutoId: prev.tutoId,
                                    tutoContenido: prev.tutoContenido.map(
                                      (item, indexItem) => {
                                        if (indexItem === index) {
                                          return e;
                                        } else {
                                          return item;
                                        }
                                      }
                                    ),
                                  }));
                                }}
                                celda={false}
                              />
                              <FormControl
                                component="fieldset"
                                inputProps={{ className: clases.inputPadding }}
                                className={clases.form33}
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <FormLabel
                                    component="span"
                                    color="primary"
                                    style={{
                                      marginRight: ".5rem",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Orientación
                                  </FormLabel>
                                  <RadioGroup
                                    onChange={(e) => {
                                      handleChangeTutoriales({
                                        index,
                                        name: "orientacion",
                                        value: e.target.value,
                                      });
                                    }}
                                    row
                                    aria-label="gender"
                                    value={dato.orientacion}
                                    name="orientacion"
                                  >
                                    <FormControlLabel
                                      value="MOVIL"
                                      control={<Radio />}
                                      label="MOVIL"
                                    />
                                    <FormControlLabel
                                      value="WEB"
                                      control={<Radio />}
                                      label="WEB"
                                    />
                                  </RadioGroup>
                                </Box>
                              </FormControl>{" "}
                              <TextField
                                multiline
                                variant="outlined"
                                minRows={1}
                                maxRows={3}
                                className={clases.formAllW}
                                size="small"
                                label="Descripción"
                                value={dato.descVideo}
                                onChange={(e) => {
                                  handleChangeTutoriales({
                                    index,
                                    name: "descVideo",
                                    value: e.target.value,
                                  });
                                }}
                              />
                              {/* DELETE */}
                            </>
                          );
                        })}
                    </>
                  </>
                ) : null}
              </Box>
              {/* <Divider /> */}

              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleEditar();
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsignatura);

import React, {
  useState,
  useContext,
  useEffect,
  memo,
  createRef,
  useMemo,
} from "react";
import Draggable from "react-draggable";
// eslint-disable-next-line
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";
import clienteAxios from "../../../../config/axios";
import {
  validarDocumento,
  // validarDocumento,
  validarEmail,
} from "../../../../config/validaciones";

import { Check, Download, Map, Search } from "@mui/icons-material";

import RowPuesto from "./RowsPuesto/RowPuesto";
import RowAgregarPuesto from "./RowsPuesto/RowAgregarPuesto";
import RowBono from "./RowBono/RowBono";
import RowAgregarBono from "./RowBono/RowAgregarBono";
import {
  getMonths,
  FechaExacta,
  navegadorCompatible,
  replaceCaracteres,
  trimPropsItem,
} from "../../../../config/const";
import {
  Typography,
  Button,
  LinearProgress,
  linearProgressClasses,
  RadioGroup,
  Radio,
} from "@mui/material";
import Turnos from "./Turnos";
import Dropzone from "../../../components/Dropzone";
// MATERIAL
import {
  Modal,
  Box,
  Divider,
  IconButton,
  FormControlLabel,
  Switch,
  TextField,
  MenuItem,
  FormControl,
  InputAdornment,
  InputLabel,
  Select,
  Input,
  Chip,
  useTheme,
  Avatar,
  Tooltip,
  Paper,
  Table,
  TableContainer,
  TablePagination,
  TableBody,
  TableHead,
  // Slide,
  // Grow,
  Autocomplete,
} from "@mui/material";
import {
  AddCircle,
  Cancel,
  Delete,
  CloudUpload,
  // CloudDownload,
} from "@mui/icons-material";
import DialogMap from "../../../components/DialogMap";
import TablaCabecera from "../../../components/TablaCabecera";
import ToolBarTable from "../../../components/ToolBarTable";
import { useModalStyle } from "../../../styles/styleModal";
import { objectDefault } from "../data";
import useMultimediasUpload from "../../../hooks/useMultimediasUpload";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import RowAgregarDescuento from "./Descuento/RowAgregarDescuento";
import RowDescuento from "./Descuento/RowDescuento";
import CamposAcumulables from "../../../components/Campos/CamposAcumulables";
import Vinculacion from "./Vinculacion/Vinculacion";

import iess from "../../../../assets/img/iess.jpg";
import sicosep from "../../../../assets/img/sicosep.png";
import sut from "../../../../assets/img/sut.png";
import { FormLabel } from "@material-ui/core";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

export const ModalPersonas = (props) => {
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
    estadoRequest,
    fromAvisoSalida,
  } = props;
  const [openMap, setopenMap] = useState(false);
  const [clientesDataLaborales, setclientesDataLaborales] = useState([]);
  const [clientesData, setclientesData] = useState([]);
  const [clienteProveedor, setclienteProveedor] = useState("GUARDIAS");
  const [bancos, setbancos] = useState(null);
  const [lugaresTrabajoData, setlugaresTrabajoData] = useState(null);
  const [ciudadesData, setciudadesData] = useState(null);
  const [documentosArray, setdocumentosArray] = useState([]);
  const [documentosObject, setdocumentosObject] = useState({});
  const [objetoReferencias, setobjetoReferencias] = useState({});
  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  const [agregandoBodega, setagregandoBodega] = useState(false);
  const [agregandoDescuento, setagregandoDescuento] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [archivoFoto, setarchivoFoto] = useState("");
  const [imagenMostradaFoto, setimagenMostradaFoto] = useState(null);
  const [archivoCarnet, setarchivoCarnet] = useState("");
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload();
  const [error, seterror] = useState({});
  const [modalStyle] = useState(getModalStyle);
  const [seccionActive, setseccionActive] = useState({
    DatosGenerales: true,
    PuestoServicio: false,

    DatosLaborales: false,
    HorarioIncapaciad: false,
    Bonos: false,
    TurnosSeccion: false,
    FotografiasSeccion: false,
  });

  const SumaTExperiencia = () => {
    return otrosDatosLaborales.filter((item) => item.tipo === "EXPERIENCIA")
      .length === 0
      ? "0 "
      : otrosDatosLaborales
          .filter((item) => item.tipo === "EXPERIENCIA")
          .map((item) => {
            if (item.desde === "" || item.hasta === "") {
              return 0;
            }
            const optionsDesde = item.desde.split("-");
            const añoDesde = Number(optionsDesde[0]) * 12;
            const mesesDesde = Number(optionsDesde[1]);
            const TotalDesde = añoDesde + mesesDesde;

            const optionsHasta = item.hasta.split("-");
            const añoHasta = Number(optionsHasta[0]) * 12;
            const mesesHasta = Number(optionsHasta[1]) + 1;
            const TotalHasta = añoHasta + mesesHasta;

            return Number(TotalHasta - TotalDesde);
          })
          .reduce(reducer) / 12;
  };
  const defaultActive = () => {
    setdocSearch("");
    changeObjetoActivoState(objectDefault);
    setopen(false);
    seteditar(false);
    seterror({});
    setseccionActive({
      DatosGenerales: true,
      PuestoServicio: false,
      DatosLaborales: false,
      HorarioIncapaciad: false,
      Bonos: false,
      TurnosSeccion: false,
    });
    setseccionActiveDatosL({
      DatosPrincipales: true,
      Turnos: false,
    });
    setcargando(false);
    setarchivoCarnet("");
    setarchivoFoto("");
    setimagenMostradaFoto(null);
    setobjetoReferencias(null);
    setdocumentosObject(null);
    setclientesData([]);
    setimagenes([]);
    setmodificoImagenes(false);
    setopenConfirmDialog(false);
  };
  const submit = () => {
    if (true) {
      return false;
    } else {
      return true;
    }
  };
  const sinValidacion = useMemo(
    () =>
      editar &&
      (ObjectoActivo.trabaja !== item.trabaja ||
        ObjectoActivo.perEstado !== item.perEstado),
    [item, ObjectoActivo, editar]
  );
  const validacion = () => {
    if (sinValidacion) {
      return false;
    }

    if (
      perCiudad.trim() === "" ||
      perDocNumero.trim() === "" ||
      perFecNacimiento.trim() === "" ||
      perNombres.trim() === "" ||
      perApellidos.trim() === "" ||
      perTipoSangre.trim() === "" ||
      perEstadoCivil.trim() === "" ||
      perEmail.length === 0 ||
      item.perEmail.some((email) => {
        return validarEmail(email);
      }) ||
      perTelefono.length === 0 ||
      perDireccion.trim() === "" ||
      (!perFecVinculacion && !editar) ||
      perFormaPago.trim() === "" ||
      perLugarTrabajo.trim() === "" ||
      perTurno.length === 0 ||
      item.perTurno.some((item) => {
        return (
          item.clienteIngreso === "" ||
          item.idClienteIngreso === "" ||
          item.docClienteIngreso === "" ||
          item.puestoIngreso === "" ||
          item.clienteSalida === "" ||
          item.idClienteSalida === "" ||
          item.docClienteSalida === "" ||
          item.puestoSalida === "" ||
          item.turno === "" ||
          item.ingreso === "" ||
          item.salida === "" ||
          item.fechas.length === 0 ||
          item.fechasConsultaDB.length === 0
        );
      }) ||
      item.otrosDatosLaborales.length === 0 ||
      item.otrosDatosLaborales.some((item) => {
        if (item.tipo === "REFERENCIA") {
          return item.tipo === "" || item.nombre === "" || item.telefono === "";
        } else if (item.tipo === "INSTRUCCION") {
          return (
            item.tipo === "" ||
            item.nombre === "" ||
            item.nombreInstitucion === "" ||
            item.desde === "" ||
            item.hasta === ""
          );
        } else if (item.tipo === "EXPERIENCIA") {
          return (
            item.tipo === "" ||
            item.nombre === "" ||
            item.desde === "" ||
            item.hasta === ""
          );
        } else {
          return item.tipo === "";
        }
      }) ||
      perAreaDepartamento.trim() === "" ||
      perCargo.trim() === "" ||
      // perPuestoServicio.trim() === "" ||
      perTipoContrato.trim() === "" ||
      perTipoCuenta.trim() === "" ||
      perLicencias.length === 0 ||
      perBancoRemuneracion.trim() === "" ||
      ((estadoRequest === "GUARDIAS" ||
        estadoRequest === "SUPERVISORES" ||
        estadoRequest === "ADMINISTRACIÓN") &&
        perDocuCliente.trim() === "")
    ) {
      mostrarAlerta("Faltan datos obligatorios por llenar", "error");
      seterror({
        perCiudad: perCiudad.trim() === "" ? true : false,
        perDocNumero: perDocNumero.trim() === "" ? true : false,
        perFecNacimiento: perFecNacimiento.trim() === "" ? true : false,
        perNombres: perNombres.trim() === "" ? true : false,
        perApellidos: perApellidos.trim() === "" ? true : false,
        perTipoSangre: perTipoSangre.trim() === "" ? true : false,
        perEstadoCivil: perEstadoCivil.trim() === "" ? true : false,
        perTelefono: perTelefono.length === 0 ? true : false,
        perDireccion: perDireccion.trim() === "" ? true : false,
        perFecVinculacion: !perFecVinculacion && !editar ? true : false,
        perFormaPago: perFormaPago.trim() === "" ? true : false,
        perLugarTrabajo: perLugarTrabajo.trim() === "" ? true : false,
        otrosDatosLaborales:
          item.otrosDatosLaborales.length === 0 ||
          item.otrosDatosLaborales.some((item) => {
            if (item.tipo === "REFERENCIA") {
              return (
                item.tipo === "" || item.nombre === "" || item.telefono === ""
              );
            } else if (item.tipo === "INSTRUCCION") {
              return (
                item.tipo === "" ||
                item.nombre === "" ||
                item.nombreInstitucion === "" ||
                item.desde === "" ||
                item.hasta === ""
              );
            } else if (item.tipo === "EXPERIENCIA") {
              return (
                item.tipo === "" ||
                item.nombre === "" ||
                item.desde === "" ||
                item.hasta === ""
              );
            } else {
              return item.tipo === "";
            }
          })
            ? true
            : false,
        perTurno:
          perTurno.length === 0 ||
          item.perTurno.some((item) => {
            return (
              item.clienteIngreso === "" ||
              item.idClienteIngreso === "" ||
              item.docClienteIngreso === "" ||
              item.puestoIngreso === "" ||
              item.clienteSalida === "" ||
              item.idClienteSalida === "" ||
              item.docClienteSalida === "" ||
              item.puestoSalida === "" ||
              item.turno === "" ||
              item.ingreso === "" ||
              item.salida === "" ||
              item.fechas.length === 0 ||
              item.fechasConsultaDB.length === 0
            );
          })
            ? true
            : false,
        perAreaDepartamento: perAreaDepartamento.trim() === "" ? true : false,
        perCargo: perCargo.trim() === "" ? true : false,
        // perPuestoServicio: perPuestoServicio.trim() === "" ? true : false,
        perTipoContrato: perTipoContrato.trim() === "" ? true : false,
        perTipoCuenta: perTipoCuenta.trim() === "" ? true : false,
        perBancoRemuneracion: perBancoRemuneracion.trim() === "" ? true : false,
        perEmail:
          item.perEmail.length === 0 ||
          item.perEmail.some((email) => {
            return validarEmail(email);
          })
            ? true
            : false,
        perLicencias: perLicencias.length === 0 ? true : false,
        perDocuCliente:
          (estadoRequest === "GUARDIAS" ||
            estadoRequest === "SUPERVISORES" ||
            estadoRequest === "ADMINISTRACIÓN") &&
          perDocuCliente.trim() === "",
      });
      return true;
    } else {
      return false;
    }
  };

  const reducer = (previousValue, currentValue) =>
    Number(previousValue) + Number(currentValue);

  const focusInputDocumento = (itemPropiedad) => {
    objetoReferencias[itemPropiedad.propiedad].current.click();
  };
  const onChangeDocumento = async (e, itempropiedad) => {
    if (!e.target.files[0]) {
      return;
    }
    if (itempropiedad.extension === "PDF") {
      if (
        !["pdf"].some((item) => item === e.target.files[0].type.split("/")[1])
      ) {
        return mostrarAlerta("El archivo debe ser PDF", "error");
      }
    }
    if (itempropiedad.extension === "IMAGEN") {
      if (
        !["png", "jpg", "jpeg"].some(
          (item) => item === e.target.files[0].type.split("/")[1]
        )
      ) {
        return mostrarAlerta("Solo archivos png, jpg o jpeg", "error");
      }
    }
    try {
      setcargando(true);
      const formDataLogos = new FormData();
      let arrayEliminar = [];

      if (documentos[itempropiedad.propiedad] === "") {
        await formDataLogos.append("archivo", e.target.files[0]);
      } else {
        arrayEliminar.push(documentos[itempropiedad.propiedad]);
        await formDataLogos.append("archivo", e.target.files[0]);
      }

      formDataLogos.append("carpeta", "documentos");

      const resDocumentos = await clienteAxios.post(
        "/subir_eliminar_archivo/subir",
        formDataLogos
      );

      const resEliminados = await clienteAxios.post(
        "/subir_eliminar_archivo/eliminar",
        { urls: arrayEliminar.filter((itemE) => itemE !== "") }
      );
      setitem({
        ...item,
        documentos: {
          ...item.documentos,
          [itempropiedad.propiedad]: resDocumentos.data.url,
        },
      });

      setdocumentosObject({
        ...documentosObject,
        [itempropiedad.propiedad]: e.target.files[0],
      });
      setcargando(false);
    } catch (error) {
      console.log({ error });

      setcargando(false);
      setdocumentosObject({
        ...documentosObject,
        [itempropiedad.propiedad]: documentosObject[itempropiedad.propiedad],
      });

      return mostrarAlerta("Hubo un error", "error");
    }
  };
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);
  //props

  // eslint-disable-next-line

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  // eslint-disable-next-line

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // eslint-disable-next-line

  //foto

  const onChangeFileFoto = (e) => {
    if (!e.target.files[0]) {
      setimagenMostradaFoto(null);
      return setarchivoFoto("");
    }

    if (
      !["png", "jpg", "jpeg"].some(
        (item) => item === e.target.files[0].type.split("/")[1]
      )
    ) {
      setimagenMostradaFoto(null);
      setarchivoFoto("");
      return mostrarAlerta("Solo archivos png, jpg o jpeg", "error");
    }

    setarchivoFoto(e.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (e) => {
      e.preventDefault();
      setimagenMostradaFoto(e.target.result);
    };
  };
  //Carnet

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStylesEspecialidades(name, permisos, theme) {
    return {
      fontWeight:
        permisos.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
      backgroundColor: permisos.indexOf(name) === -1 ? "white" : "#31b2f7",
    };
  }

  const theme = useTheme();
  const [docSearch, setdocSearch] = useState("");
  const [cargandoDoc, setcargandoDoc] = useState(false);
  useEffect(() => {
    setclienteProveedor(estadoRequest);

    const getData = async () => {
      try {
        const res = await clienteAxios.get(
          `/documentos/filtro/0?perfil=${
            estadoRequest === "SUPERVISORES"
              ? "SUPERVISOR"
              : estadoRequest === "ADMINISTRACIÓN"
              ? "ADMINISTRACION"
              : "GUARDIA"
          }`
        );

        const itemRes = res?.data?.data[0];

        // itemRes.docDocumentos;
        setdocumentosArray(itemRes.docDocumentos);

        if (editar) {
          const documentos = {};
          itemRes.docDocumentos.forEach((itemDocumento) => {
            if (
              !ObjectoActivo.documentos[itemDocumento.propiedad] &&
              ObjectoActivo.documentos[itemDocumento.propiedad] !== ""
            ) {
              documentos[itemDocumento.propiedad] = "";
              documentos[itemDocumento.propiedad + "expira"] = "";
              documentos[itemDocumento.propiedad + "_Tiene"] = "";
            }
          });

          //DOCUMENTOS OBJECT
          const documentosObject = {};
          itemRes.docDocumentos.forEach((itemDocumento) => {
            documentosObject[itemDocumento.propiedad] = "";
          });

          //DOCUMENTOS OBJECT
          const objetoReferencias = {};
          itemRes.docDocumentos.forEach((itemDocumento) => {
            objetoReferencias[itemDocumento.propiedad] = createRef();
          });

          setobjetoReferencias(objetoReferencias);
          setdocumentosObject(documentosObject);
          setdocSearch(ObjectoActivo.perDocNumero);

          setitem({
            ...ObjectoActivo,
            documentos: { ...ObjectoActivo.documentos, ...documentos },
          });
        } else {
          const documentos = {};
          itemRes.docDocumentos.forEach((itemDocumento) => {
            documentos[itemDocumento.propiedad] = "";
            documentos[itemDocumento.propiedad + "expira"] = "";
            documentos[itemDocumento.propiedad + "_Tiene"] = "";
          });

          //DOCUMENTOS OBJECT
          const documentosObject = {};
          itemRes.docDocumentos.forEach((itemDocumento) => {
            documentosObject[itemDocumento.propiedad] = "";
          });

          //DOCUMENTOS OBJECT
          const objetoReferencias = {};
          itemRes.docDocumentos.forEach((itemDocumento) => {
            objetoReferencias[itemDocumento.propiedad] = createRef();
          });

          setobjetoReferencias(objetoReferencias);
          setdocumentosObject(documentosObject);

          if (estadoRequest === "SUPERVISORES") {
            setdocSearch(ObjectoActivo.perDocNumero);
            setitem({
              ...ObjectoActivo,
              perCargo: "SUPERVISOR",
              perAreaDepartamento: "OPERATIVO-S",
              documentos,
            });
          } else if (estadoRequest === "ADMINISTRACIÓN") {
            console.log(ObjectoActivo.perDocNumero);
            setdocSearch(ObjectoActivo.perDocNumero);
            setitem({
              ...ObjectoActivo,
              perCargo: "ADMINISTRACION",
              documentos,
            });
          } else if (estadoRequest === "GUARDIAS") {
            setdocSearch(ObjectoActivo.perDocNumero);
            setitem({
              ...ObjectoActivo,
              perCargo: "GUARDIA",
              perAreaDepartamento: "OPERATIVO-G",
              documentos,
            });
          }
        }
      } catch (error) {
        if (open) {
          mostrarAlerta(
            "NO HAY ARCHIVOS DINÁMICOS, REVISAR LA CONFIGURACIÓN DE DOCUMENTOS",
            "error"
          );
        }
        setdocumentosArray([]);
        setdocumentosObject({});
        setobjetoReferencias({});
        setdocSearch(ObjectoActivo.perDocNumero);
        if (estadoRequest === "SUPERVISORES") {
          setitem({
            ...ObjectoActivo,
            perCargo: "SUPERVISOR",
            perAreaDepartamento: "OPERATIVO-S",
          });
        } else if (estadoRequest === "ADMINISTRACIÓN") {
          setitem({
            ...ObjectoActivo,
            perCargo: "ADMINISTRACION",
          });
        } else if (estadoRequest === "GUARDIAS") {
          setitem({
            ...ObjectoActivo,
            perCargo: "GUARDIA",
            perAreaDepartamento: "OPERATIVO-G",
          });
        } else {
          setitem({
            ...ObjectoActivo,
          });
        }
      }
    };
    getData();
    // eslint-disable-next-line
  }, [ObjectoActivo, open]);

  useEffect(() => {
    if (!bancos || lugaresTrabajoData) {
      try {
        const getData = async () => {
          const resBancos = await clienteAxios.get(`/bancos/filtro/0`);

          setbancos(resBancos.data.data);

          const resLugaresTrabajo = await clienteAxios.get(
            `/lugartrabajo/filtro/0`
          );

          setlugaresTrabajoData(resLugaresTrabajo.data.data);
          const resCiudades = await clienteAxios.get(`/ciudades/filtro/0`);

          setciudadesData(resCiudades.data.data);
        };
        getData();
      } catch (error) {}
    } // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line
  const {
    trabaja,
    perCargaFamiliar,
    // perPerfil,
    // perDocTipo,
    perDocNumero,
    perNombres,
    perApellidos,
    perEstadoCivil,
    perTelefono,
    // perCelular,

    perDireccion,
    perFecNacimiento,
    // perNumContrato,
    perTipoSangre,
    perEmail,
    perFoto,
    otrosDatosLaborales,
    perFecVinculacion,
    perFormaPago,
    perRemuneracion,
    perLugarTrabajo,
    perAreaDepartamento,
    perPuestoServicio,
    perTipoCuenta,
    perBancoRemuneracion,
    perFecRetiro,
    perBonos,
    descuentos,
    perEstado,
    perTurno,
    perCargo,
    perTipoContrato,
    perLicencias,
    perCtaBancaria,
    // perEmpresa,
    // perPais,
    // perProvincia,
    // perCanton,
    perObservacion,
    // perUser,
    // perFecReg,
    // errorAutorizacion,
    //foto
    perFotoCarnet,
    //docs
    documentos,
    perCiudad,
    // perIdCliente,
    perDocuCliente,
    perNombreCliente,
    perGps,
    perFotografiasInstalaciones,
    // GOP
    // perIdClienteGop,
    perDocuClienteGop,
    perNombreClienteGop,
    perReferencia,
    genero,
  } = item;
  // eslint-disable-next-line

  // eslint-disable-next-line
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);

    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useModalStyle();
  //definiendo los estilos del modal

  const {
    DatosGenerales,
    DatosLaborales,
    PuestoServicio,
    HorarioIncapaciad,
    Bonos,
    FotografiasSeccion,
  } = seccionActive;

  const [seccionActiveDatosL, setseccionActiveDatosL] = useState({
    DatosPrincipalesLaborales: true,
    TurnosLaborales: false,
    OtrosLaborales: false,
    VinculacionSeccion: false,
  });
  const {
    DatosPrincipalesLaborales,
    TurnosLaborales,
    OtrosLaborales,
    VinculacionSeccion,
  } = seccionActiveDatosL;

  // eslint-disable-next-line
  const handleCrear = async () => {
    try {
      await validarDocumento(perDocNumero);

      // mostrarAlerta("Identificación válida", "success");
    } catch (error) {
      console.log({ error });
      mostrarAlerta(error, "error");
      return seterror({
        ...error,
        perDocNumero: true,
      });
    }
    console.log("hola");

    if (validacion()) {
      return;
    }

    try {
      setcargando(true);
      const obligatorios = documentosArray.filter(
        (documentoItem) => documentoItem.obligatorio === "SI"
      );
      obligatorios.map((obligatorioItem) => {
        if (documentos[obligatorioItem.propiedad] === "") {
          return {
            ...obligatorioItem,
            error: true,
          };
        } else {
          return {
            ...obligatorioItem,
            error: false,
          };
        }
      });
      const existeSinLlenar = obligatorios.some((obligatorioItem) => {
        return documentos[obligatorioItem.propiedad] === "";
      });
      if (existeSinLlenar) {
        const llenos = documentosArray.map((obligatorioItem) => {
          if (
            documentos[obligatorioItem.propiedad] === "" &&
            obligatorioItem.obligatorio === "SI"
          ) {
            return {
              ...obligatorioItem,
              error: true,
            };
          } else {
            return {
              ...obligatorioItem,
              error: false,
            };
          }
        });

        setcargando(false);
        return setdocumentosArray(llenos);
      }

      const formDataLogosFoto = new FormData();
      let arrayEliminarFoto = item.perFoto && archivoFoto ? [perFoto] : [];

      await formDataLogosFoto.append("archivo", archivoFoto);
      formDataLogosFoto.append("carpeta", "documentos");

      const resDocumentosFoto = archivoFoto
        ? await clienteAxios.post(
            "/subir_eliminar_archivo/subir",
            formDataLogosFoto
          )
        : null;

      await clienteAxios.post("/subir_eliminar_archivo/eliminar", {
        urls: arrayEliminarFoto.filter((itemE) => itemE !== ""),
      });

      const formDataCarnet = new FormData();
      let arrayEliminarCarnet = item.perFotoCarnet ? [perFotoCarnet] : [];

      await formDataCarnet.append("archivo", archivoCarnet);
      formDataCarnet.append("carpeta", "documentos");

      const resDocumentosCarnet = archivoCarnet
        ? await clienteAxios.post(
            "/subir_eliminar_archivo/subir",
            formDataCarnet
          )
        : null;

      await clienteAxios.post("/subir_eliminar_archivo/eliminar", {
        urls: arrayEliminarCarnet.filter((itemE) => itemE !== ""),
      });

      const fotos = {
        perFoto: resDocumentosFoto?.data?.url
          ? resDocumentosFoto.data.url
          : perFoto,
        perFotoCarnet: resDocumentosCarnet?.data?.url
          ? resDocumentosCarnet.data.url
          : perFotoCarnet,
      };
      const resFotografias = await subirImagenes(
        imagenes,
        perFotografiasInstalaciones
      );
      if (item.perId) {
        if (!fotos.perFoto) {
          setcargando(false);

          setopenConfirmDialog(false);
          setseccionActive({ FotografiasSeccion: true });
          return mostrarAlerta("FOTO DE PERFIL REQUERIDA", "error");
        }
        if (resFotografias.length === 0) {
          setcargando(false);

          setopenConfirmDialog(false);
          setseccionActive({ FotografiasSeccion: true });
          return mostrarAlerta("FOTO DE INSTALACIONES REQUERIDA", "error");
        }
        const itemEnv = trimPropsItem({
          avisoSalida: fromAvisoSalida,
          dataAntiguo: {
            perIdCliente: ObjectoActivo.perIdCliente,
            perDocuCliente: ObjectoActivo.perDocuCliente,
            perNombreCliente: ObjectoActivo.perNombreCliente,
            perEstado: ObjectoActivo.perEstado,
          },
          ObjectoAntiguo: ObjectoActivo.perTurno,
          ...item,
          perFotografiasInstalaciones: resFotografias,
          //fotos
          ...fotos,
          //params
          perPerfil:
            clienteProveedor === "GUARDIAS"
              ? [...new Set([...item.perPerfil, "GUARDIA"])]
              : clienteProveedor === "SUPERVISORES"
              ? [...new Set([...item.perPerfil, "SUPERVISOR"])]
              : [...new Set([...item.perPerfil, "ADMINISTRACION"])],
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          perUser: usuario.usuario,
          perEmpresa: [...new Set([...item.perEmpresa, usuario.rucempresa])],
          tabla: "persona",
        });
        socket.current.emit("client:actualizarData", itemEnv);
      } else {
        if (!fotos.perFoto) {
          setcargando(false);

          setopenConfirmDialog(false);
          setseccionActive({ FotografiasSeccion: true });
          return mostrarAlerta("FOTO DE PERFIL REQUERIDA", "error");
        }
        if (resFotografias.length === 0) {
          setcargando(false);

          setopenConfirmDialog(false);
          setseccionActive({ FotografiasSeccion: true });
          return mostrarAlerta("FOTO DE INSTALACIONES REQUERIDA", "error");
        }
        const itemEnv = trimPropsItem({
          ...item,
          avisoSalida: fromAvisoSalida,
          //fotos
          perFotografiasInstalaciones: resFotografias,
          ...fotos,
          //params
          perPerfil:
            clienteProveedor === "GUARDIAS"
              ? [...new Set([...item.perPerfil, "GUARDIA"])]
              : clienteProveedor === "SUPERVISORES"
              ? [...new Set([...item.perPerfil, "SUPERVISOR"])]
              : [...new Set([...item.perPerfil, "ADMINISTRACION"])],
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          perUser: usuario.usuario,
          perEmpresa: [...new Set([...item.perEmpresa, usuario.rucempresa])],
          tabla: "persona",
        });

        socket.current.emit("client:guardarData", itemEnv);
      }

      setcargando(false);
      return defaultActive();
    } catch (error) {
      console.log({ error });
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    try {
      await validarDocumento(perDocNumero);
      // mostrarAlerta("Identificación válida", "success");
    } catch (error) {
      mostrarAlerta(error, "error");
      return seterror({
        ...error,
        perDocNumero: true,
      });
    }
    console.log("hola");

    if (validacion()) {
      return;
    }

    try {
      setcargando(true);
      const obligatorios = documentosArray.filter(
        (documentoItem) => documentoItem.obligatorio === "SI"
      );

      obligatorios.map((obligatorioItem) => {
        if (documentos[obligatorioItem.propiedad] === "") {
          return {
            ...obligatorioItem,
            error: true,
          };
        } else {
          return {
            ...obligatorioItem,
            error: false,
          };
        }
      });

      const existeSinLlenar = obligatorios.some((obligatorioItem) => {
        return documentos[obligatorioItem.propiedad] === "";
      });

      if (existeSinLlenar) {
        const llenos = documentosArray.map((obligatorioItem) => {
          if (
            documentos[obligatorioItem.propiedad] === "" &&
            obligatorioItem.obligatorio === "SI"
          ) {
            return {
              ...obligatorioItem,
              error: true,
            };
          } else {
            return {
              ...obligatorioItem,
              error: false,
            };
          }
        });

        mostrarAlerta("Faltan documentos por subir.", "error");
        setcargando(false);
        return setdocumentosArray(llenos);
      }

      const formDataLogosFoto = new FormData();
      let arrayEliminarFoto = item.perFoto && archivoFoto ? [perFoto] : [];

      await formDataLogosFoto.append("archivo", archivoFoto);
      formDataLogosFoto.append("carpeta", "documentos");

      const resDocumentosFoto = archivoFoto
        ? await clienteAxios.post(
            "/subir_eliminar_archivo/subir",
            formDataLogosFoto
          )
        : null;

      await clienteAxios.post("/subir_eliminar_archivo/eliminar", {
        urls: arrayEliminarFoto.filter((itemE) => itemE !== ""),
      });

      const formDataCarnet = new FormData();
      let arrayEliminarCarnet = item.perFotoCarnet ? [perFotoCarnet] : [];

      await formDataCarnet.append("archivo", archivoCarnet);
      formDataCarnet.append("carpeta", "documentos");

      const resDocumentosCarnet = archivoCarnet
        ? await clienteAxios.post(
            "/subir_eliminar_archivo/subir",
            formDataCarnet
          )
        : null;

      await clienteAxios.post("/subir_eliminar_archivo/eliminar", {
        urls: arrayEliminarCarnet.filter((itemE) => itemE !== ""),
      });

      const fotos = {
        perFoto: resDocumentosFoto?.data?.url
          ? resDocumentosFoto.data.url
          : perFoto,
        perFotoCarnet: resDocumentosCarnet?.data?.url
          ? resDocumentosCarnet.data.url
          : perFotoCarnet,
      };
      console.log({ fotos });

      const resFotografias = await subirImagenes(
        imagenes,
        perFotografiasInstalaciones
      );

      if (!fotos.perFoto && !sinValidacion) {
        setcargando(false);

        setopenConfirmDialog(false);
        setseccionActive({ FotografiasSeccion: true });
        return mostrarAlerta("FOTO DE PERFIL REQUERIDA", "error");
      }
      if (resFotografias.length === 0 && !sinValidacion) {
        setcargando(false);

        setopenConfirmDialog(false);
        setseccionActive({ FotografiasSeccion: true });
        return mostrarAlerta("FOTO DE INSTALACIONES REQUERIDA", "error");
      }
      const itemEnv = trimPropsItem({
        avisoSalida: fromAvisoSalida,
        dataAntiguo: {
          perIdCliente: ObjectoActivo.perIdCliente,
          perDocuCliente: ObjectoActivo.perDocuCliente,
          perNombreCliente: ObjectoActivo.perNombreCliente,
          perEstado: ObjectoActivo.perEstado,
        },
        ObjectoAntiguo: ObjectoActivo.perTurno,
        ...item,
        //fotos
        perFotografiasInstalaciones: resFotografias,
        ...fotos,
        //params
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        perUser: usuario.usuario,
        perEmpresa: [...new Set([...item.perEmpresa, usuario.rucempresa])],
        tabla: "persona",
      });

      socket.current.emit("client:actualizarData", itemEnv);
      setcargando(false);
      return defaultActive();
    } catch (error) {
      console.log({ error });
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };

  // eslint-disable-next-line

  if (!open) {
    return null;
  }

  if (!bancos) {
    return null;
  }
  if (!ciudadesData) {
    return null;
  }
  if (!lugaresTrabajoData) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <ConfirmacionDialog
          open={openConfirmDialog}
          setopen={setopenConfirmDialog}
          categoria="warning"
          titulo={`${
            cargando
              ? "Cargando: "
              : `¿Esta seguro de ${
                  editar ? "editar" : "crear"
                } un usuario con este rol: `
          } ${
            clienteProveedor === "GUARDIAS"
              ? "Guardia"
              : clienteProveedor === "SUPERVISORES"
              ? "Supervisor"
              : clienteProveedor === "ADMINISTRACIÓN"
              ? "Administración"
              : ""
          } ${cargando ? "" : "?"}`}
          botones={[
            {
              tooltip: `Se  ${editar ? "editara" : "creara"} el registro`,
              texto: "SI",
              funcion: () => {
                if (editar) {
                  handleEditar();
                } else {
                  handleCrear();
                }
              },
              disabled: cargando,
              Icon: Check,
              color: "success",
              id: 1,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: { marginRight: ".3rem" },
            },
            {
              tooltip: "Cancelar",
              texto: "NO",
              funcion: () => {
                setopenConfirmDialog(false);
              },
              disabled: cargando,
              Icon: Cancel,
              color: "error",
              id: 2,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: {},
            },
          ]}
        />
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handle"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar " : "Agregar "}
                  {clienteProveedor === "GUARDIAS"
                    ? "Guardia"
                    : clienteProveedor === "SUPERVISORES"
                    ? "Supervisor"
                    : clienteProveedor === "ADMINISTRACIÓN"
                    ? "Administración"
                    : ""}
                  .
                </Typography>
                <Typography variant="h5" color="initial" align="center">
                  {item.perId}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />{" "}
              <DialogMap
                open={openMap}
                setopen={setopenMap}
                setitem={setitem}
                item={item}
                editar={editar}
                propiedad={perGps}
                nombrePropiedad={"perGps"}
                solover={false}
              />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box display="flex" flexWrap={"wrap"}>
                  <Button
                    className={clases.buttonTab}
                    variant={DatosGenerales ? "contained" : "outlined"}
                    disableElevation
                    color={
                      error.perCiudad ||
                      error.perDocNumero ||
                      error.perFecNacimiento ||
                      error.perNombres ||
                      error.perApellidos ||
                      error.perTipoSangre ||
                      error.perEstadoCivil ||
                      error.perTelefono ||
                      error.perDireccion ||
                      error.perFecVinculacion ||
                      error.perFormaPago ||
                      error.perLugarTrabajo
                        ? "error"
                        : "secondary"
                    }
                    onClick={() => setseccionActive({ DatosGenerales: true })}
                  >
                    Datos Generales
                  </Button>
                  <Button
                    className={clases.buttonTab}
                    variant={DatosLaborales ? "contained" : "outlined"}
                    disableElevation
                    color={
                      error.perFecVinculacion ||
                      error.perLugarTrabajo ||
                      error.perBancoRemuneracion ||
                      error.perTurno ||
                      error.otrosDatosLaborales
                        ? "error"
                        : "secondary"
                    }
                    onClick={() => setseccionActive({ DatosLaborales: true })}
                  >
                    Datos Laborales
                  </Button>
                  <Button
                    className={clases.buttonTab}
                    variant={Bonos ? "contained" : "outlined"}
                    disableElevation
                    color="secondary"
                    onClick={() => setseccionActive({ Bonos: true })}
                  >
                    Bonos
                  </Button>
                  <Button
                    className={clases.buttonTab}
                    variant={HorarioIncapaciad ? "contained" : "outlined"}
                    disableElevation
                    color="secondary"
                    onClick={() => {
                      console.log("hola");
                      if (validacion()) {
                        return;
                      }
                      setseccionActive({ HorarioIncapaciad: true });
                    }}
                  >
                    Documentos
                  </Button>
                  <Button
                    className={clases.buttonTab}
                    variant={FotografiasSeccion ? "contained" : "outlined"}
                    disableElevation
                    color="secondary"
                    onClick={() =>
                      setseccionActive({ FotografiasSeccion: true })
                    }
                  >
                    Fotografias
                  </Button>
                </Box>
                <Box>
                  <FormControlLabel
                    sx={{ mr: 2 }}
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={trabaja === 1 ? true : false}
                        onChange={(e) => {
                          setitem({
                            ...item,
                            trabaja: e.target.checked ? 1 : 0,
                          });
                        }}
                        name="antoine"
                        color="secondary"
                      />
                    }
                    label="Trabaja"
                  />
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={
                          perEstado === "ACTIVO" || perEstado === "ACTIVA"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setitem({
                            ...item,
                            perEstado: e.target.checked ? "ACTIVO" : "INACTIVO",
                          });
                        }}
                        name="antoine"
                        color="secondary"
                      />
                    }
                    label="Estado"
                  />
                </Box>
              </Box>
              <Divider />
              <Box className={clases.container}>
                {DatosGenerales ? (
                  <>
                    <Box
                      className={clases.formD}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      <TextField
                        disabled={
                          !(
                            usuario.rol.includes("ADMIN") ||
                            usuario.rol.includes("ADMINISTRACION")
                          )
                        }
                        fullWidth
                        size="small"
                        required
                        label={"Número Doc."}
                        error={error.perDocNumero}
                        value={docSearch}
                        onChange={async (e) => {
                          setdocSearch(e.target.value);
                          setitem({ ...item, perDocNumero: e.target.value });
                        }}
                        helperText={`Presione "Enter" para buscar`}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <IconButton
                                aria-label=""
                                onClick={async (e) => {
                                  if (docSearch === "") {
                                    return mostrarAlerta(
                                      "Ingrese un documento",
                                      "error"
                                    );
                                  }
                                  try {
                                    setcargandoDoc(true);
                                    const res = await clienteAxios.get(
                                      `personas/searchByCedulaOfRuc/0?search=${docSearch}&perfil=${
                                        estadoRequest === "SUPERVISORES"
                                          ? "SUPERVISOR"
                                          : estadoRequest === "ADMINISTRACIÓN"
                                          ? "ADMINISTRACION"
                                          : "GUARDIA"
                                      }`
                                    );
                                    if (res?.data) {
                                      changeObjetoActivoState({
                                        ...item,
                                        ...res.data,
                                        perOtros: res.data.perOtros
                                          ? res.data.perOtros
                                          : [],
                                      });
                                      setitem({
                                        ...item,
                                        ...res.data,
                                        perOtros: res.data.perOtros
                                          ? res.data.perOtros
                                          : [],
                                      });
                                    }
                                    setcargandoDoc(false);
                                  } catch (error) {
                                    setcargandoDoc(false);
                                    mostrarAlerta(
                                      error?.response?.data?.msg,
                                      "error"
                                    );
                                    setitem({
                                      ...ObjectoActivo,
                                      perDocNumero: docSearch,
                                    });
                                  }
                                }}
                              >
                                <Search color="secondary" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onKeyDown={async (e) => {
                          if (e.key !== "Enter") {
                            return;
                          }
                          if (e.target.value === "") {
                            return mostrarAlerta(
                              "Ingrese un documento",
                              "error"
                            );
                          }
                          try {
                            setcargandoDoc(true);
                            setitem({
                              ...item,
                              perDocNumero: e.target.value,
                            });
                            const res = await clienteAxios.get(
                              `personas/searchByCedulaOfRuc/0?search=${
                                e.target.value
                              }&perfil=${
                                estadoRequest === "SUPERVISORES"
                                  ? "SUPERVISOR"
                                  : estadoRequest === "ADMINISTRACIÓN"
                                  ? "ADMINISTRACION"
                                  : "GUARDIA"
                              }`
                            );

                            if (res?.data) {
                              changeObjetoActivoState({
                                ...item,
                                ...res.data,
                                perOtros: res.data.perOtros
                                  ? res.data.perOtros
                                  : [],
                              });
                              setitem({
                                ...item,
                                ...res.data,
                                perOtros: res.data.perOtros
                                  ? res.data.perOtros
                                  : [],
                              });
                            }
                            setcargandoDoc(false);
                          } catch (error) {
                            console.log({ error });
                            setcargandoDoc(false);
                            mostrarAlerta(error?.response?.data?.msg, "error");
                            setitem({
                              ...ObjectoActivo,
                              perDocNumero: docSearch,
                            });
                          }
                        }}
                      />
                      {cargandoDoc && (
                        <LinearProgress
                          color="primary"
                          fourColor
                          variant="query"
                        />
                      )}
                    </Box>
                    {/* <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        options={clientesData.map((item) => {
                          return (
                            item.perDocNumero +
                            "/+/" +
                            item.perApellidos +
                            " " +
                            item.perNombres
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ").replace("/+/", " ");
                        }}
                        id="controlled-demo"
                        value={perDocNumero}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");

                            const findItem = clientesData.find(
                              (clienteData) =>
                                clienteData.perDocNumero === options[0]
                            );

                            if (findItem) {
                              setitem({
                                ...findItem,
                                perOtros: findItem.perOtros
                                  ? findItem.perOtros
                                  : [],
                              });
                            } else {
                              if (editar) {
                                setitem({
                                  ...ObjectoActivo,
                                });
                              } else {
                                setitem({
                                  ...ObjectoActivo,
                                  perDocTipo:
                                    clienteProveedor === "USUARIOS"
                                      ? "PASAPORTE"
                                      : "RUC",
                                });
                              }
                            }
                          } else {
                            if (editar) {
                              setitem({
                                ...ObjectoActivo,
                              });
                            } else {
                              setitem({
                                ...ObjectoActivo,
                                perDocTipo:
                                  clienteProveedor === "USUARIOS"
                                    ? "PASAPORTE"
                                    : "RUC",
                              });
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            disabled={
                              !(
                                usuario.rol.includes("ADMIN") ||
                                usuario.rol.includes("ADMINISTRACION")
                              )
                            }
                            label={"N. Documento"}
                            fullWidth
                            error={error.perDocNumero}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta(
                                  "Ingrese un documento",
                                  "error"
                                );
                              }
                              try {
                                setitem({
                                  ...item,
                                  perDocNumero: e.target.value,
                                });
                                if (e.target.value.length < 3) {
                                  return;
                                }
                                const res = await clienteAxios.get(
                                  `/personas/filtro/0?search=${
                                    e.target.value
                                  }&estado=${
                                    estadoRequest === "ADMINISTRACIÓN"
                                      ? "ADMINISTRACIÓN"
                                      : estadoRequest
                                  }`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }

                                setclientesData(res.data.data);
                              } catch (error) {}
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {!editar ? <> </> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl> */}
                    <TextField
                      helperText="Fecha de nacimiento"
                      InputLabelProps={{ shrink: true }}
                      type={"date"}
                      inputProps={{}}
                      className={clases.formD}
                      size="small"
                      id="perFecNacimiento"
                      label="Fecha Nacimiento"
                      value={perFecNacimiento}
                      name="perFecNacimiento"
                      error={error.perFecNacimiento}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      size="small"
                      id="perNombres"
                      label="Nombres"
                      value={perNombres}
                      name="perNombres"
                      error={error.perNombres}
                      onChange={(e) => handleChange(e)}
                    />
                    {/* <TextField
                        type="number"
                         
                        className={clases.formD}
                        size="small"
                        id="perCelular"
                        label="Celular"
                        value={perCelular}
                        name="perCelular"
                        error={error.perCelular}
                        onChange={(e) => handleChange(e)}
                      /> */}
                    <TextField
                      className={clases.formD}
                      size="small"
                      id="perApellidos"
                      label="Apellidos"
                      value={perApellidos}
                      name="perApellidos"
                      error={error.perApellidos}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      size="small"
                      id="perTipoSangre"
                      label="Tipo De sangre"
                      value={perTipoSangre}
                      name="perTipoSangre"
                      error={error.perTipoSangre}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione.</MenuItem>
                      {[
                        "O POSITIVO",
                        "O NEGATIVO",
                        "A POSITIVO",
                        "A NEGATIVO",
                        "B POSITIVO",
                        "B NEGATIVO",
                        "AB POSITIVO",
                        "AB NEGATIVO",
                      ].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      className={clases.formD}
                      size="small"
                      id="perEstadoCivil"
                      label="Estado Civil"
                      value={perEstadoCivil}
                      name="perEstadoCivil"
                      error={error.perEstadoCivil}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione.</MenuItem>
                      {[
                        "SOLTERO(a)",
                        "CASADO(a)",
                        "DIVORCIADO(a)",
                        "UNION LIBRE",
                        "VIUDO(a)",
                      ].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      type={"Number"}
                      className={clases.formD}
                      size="small"
                      id="perCargaFamiliar"
                      label="Carga Familiar"
                      value={perCargaFamiliar}
                      name="perCargaFamiliar"
                      error={error.perCargaFamiliar}
                      onChange={(e) => handleChange(e)}
                    />{" "}
                    <FormControl
                      component="fieldset"
                      inputProps={{
                        className: clases.inputPadding,
                      }}
                      className={clases.formD}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                          className={error.genero ? clases.error : ""}
                        >
                          Genero
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={genero}
                          name="genero"
                        >
                          <FormControlLabel
                            value="MASCULINO"
                            control={<Radio />}
                            label="MASCULINO"
                          />
                          <FormControlLabel
                            value="FEMENINO"
                            control={<Radio />}
                            label="FEMENINO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <FormControl className={clases.formAllW}>
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="subtitle1"
                          color={error.perEmail ? "error" : "primary"}
                        >
                          Correos:
                        </Typography>
                        <IconButton
                          aria-label=""
                          size="small"
                          onClick={async () => {
                            setitem({
                              ...item,
                              perEmail: [...item.perEmail, ""],
                            });
                          }}
                        >
                          <AddCircle fontSize="small" color="secondary" />
                        </IconButton>
                      </Box>
                    </FormControl>{" "}
                    {perEmail?.map((precio, index) => {
                      return (
                        <TextField
                          key={index}
                          className={clases.formD}
                          id={index + "email"}
                          value={precio}
                          onChange={(e) => {
                            setitem({
                              ...item,
                              perEmail: perEmail.map((item, indexItem) => {
                                if (indexItem === index) {
                                  return e.target.value;
                                } else {
                                  return item;
                                }
                              }),
                            });
                          }}
                          label={`Correo #${index + 1}`}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                <IconButton
                                  aria-label=""
                                  onClick={() => {
                                    setitem({
                                      ...item,
                                      perEmail: perEmail.filter(
                                        (item, indexItem) => {
                                          return indexItem !== index;
                                        }
                                      ),
                                    });
                                  }}
                                >
                                  <Delete color="error" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      );
                    })}
                    <CamposAcumulables
                      error={error}
                      propiedadTexto={"perTelefono"}
                      item={item}
                      setitem={setitem}
                      tipoCampos="telefono"
                      etiqueta={"Teléfonos"}
                      etiquetaCampo={"Teléfono"}
                    />
                    {/* <TextField
                     
                    className={clases.formD}
                    size="small"
                    id="perDocTipo"
                    label="Empresa 2"
                    value={perDocTipo}
                    name="perDocTipo"
                    error={error.perDocTipo}
                    onChange={(e) => handleChange(e)}
                  /> */}
                    <TextField
                      className={clases.formD}
                      size="small"
                      id="perCiudad"
                      label="Ciudad"
                      value={perCiudad}
                      name="perCiudad"
                      error={error.perCiudad}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione.</MenuItem>
                      {ciudadesData.map((item) => {
                        return (
                          <MenuItem key={item.ciuId} value={item.ciuNombre}>
                            {item.ciuNombre}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <TextField
                      className={clases.formD}
                      size="small"
                      id="perDireccion"
                      label="Dirección"
                      value={perDireccion}
                      name="perDireccion"
                      error={error.perDireccion}
                      onChange={(e) => handleChange(e)}
                    />{" "}
                    <TextField
                      className={clases.formD}
                      size="small"
                      id="latitud"
                      label="Ubicación GPS"
                      value={perGps.latitud + ", " + perGps.longitud}
                      name="latitud"
                      error={error.latitud}
                      // onChange={(e) => {
                      //   setitem({
                      //     ...item,
                      //     perGps: {
                      //       ...item.perGps,
                      //       [e.target.name]: e.target.value,
                      //     },
                      //   });
                      // }}
                      InputProps={{
                        endAdornment: (
                          <Box htmlFor="contained-button-documento" fullWidth>
                            <InputAdornment>
                              <Tooltip title="Mostrar Mapa" arrow>
                                <Box>
                                  <IconButton
                                    aria-label=""
                                    onClick={() => setopenMap(true)}
                                  >
                                    <Map color="secondary" />
                                  </IconButton>
                                </Box>
                              </Tooltip>
                            </InputAdornment>
                          </Box>
                        ),
                      }}
                    />
                  </>
                ) : null}

                {DatosLaborales ? (
                  <>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems=" stretch"
                      width="100%"
                    >
                      <Box>
                        <Button
                          variant={
                            DatosPrincipalesLaborales ? "contained" : "outlined"
                          }
                          disableElevation
                          style={{
                            padding: "8px",
                            borderRadius: "20px 20px 0px 0px",
                          }}
                          color={
                            error.perFecVinculacion ||
                            error.perLugarTrabajo ||
                            error.perBancoRemuneracion
                              ? "error"
                              : "primary"
                          }
                          onClick={() =>
                            setseccionActiveDatosL({
                              DatosPrincipalesLaborales: true,
                            })
                          }
                        >
                          Principal
                        </Button>
                        {editar && (
                          <Button
                            variant={
                              VinculacionSeccion ? "contained" : "outlined"
                            }
                            disableElevation
                            style={{
                              padding: "8px",
                              borderRadius: "20px 20px 0px 0px",
                            }}
                            perTurno
                            color={error.perTurno ? "error" : "primary"}
                            onClick={() =>
                              setseccionActiveDatosL({
                                VinculacionSeccion: true,
                              })
                            }
                          >
                            Vinculación
                          </Button>
                        )}
                        <Button
                          variant={OtrosLaborales ? "contained" : "outlined"}
                          disableElevation
                          style={{
                            padding: "8px",
                            borderRadius: "20px 20px 0px 0px",
                          }}
                          color={
                            error.otrosDatosLaborales ? "error" : "primary"
                          }
                          onClick={() =>
                            setseccionActiveDatosL({ OtrosLaborales: true })
                          }
                        >
                          Experiencia
                        </Button>
                        <Button
                          variant={TurnosLaborales ? "contained" : "outlined"}
                          disableElevation
                          style={{
                            padding: "8px",
                            borderRadius: "20px 20px 0px 0px",
                          }}
                          perTurno
                          color={error.perTurno ? "error" : "primary"}
                          onClick={() =>
                            setseccionActiveDatosL({
                              TurnosLaborales: true,
                            })
                          }
                        >
                          Horarios y turnos
                        </Button>
                      </Box>
                    </Box>
                    <Divider style={{ width: "100%" }} />

                    <>
                      {DatosPrincipalesLaborales ? (
                        <>
                          {!editar && (
                            <TextField
                              type="date"
                              InputLabelProps={{ shrink: true }}
                              inputProps={{
                                min: FechaExacta,
                              }}
                              className={clases.formD}
                              size="small"
                              id="perFecVinculacion"
                              label="Fecha de vinculación"
                              value={perFecVinculacion}
                              name="perFecVinculacion"
                              error={error.perFecVinculacion}
                              onChange={(e) => handleChange(e)}
                            />
                          )}
                          {/* <TextField
                            disabled
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                              min: FechaExacta,
                            }}
                            className={clases.formD}
                            size="small"
                            id="perFecRetiro"
                            label="Fecha de Retiro"
                            value={perFecRetiro}
                            name="perFecRetiro"
                            error={error.perFecRetiro}
                            onChange={(e) => handleChange(e)}
                          /> */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="perFormaPago"
                            label="Forma de pago"
                            value={perFormaPago}
                            name="perFormaPago"
                            error={error.perFormaPago}
                            onChange={(e) => handleChange(e)}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {[
                              "EFECTIVO",
                              "CHEQUE",
                              "DEPOSITO",
                              "TRANSFERENCIA",
                            ].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                          {/* <TextField
                        type="number"
                         
                        className={clases.formD}
                        size="small"
                        id="perBonos"
                        label="Bonos / extras"
                        value={perBonos}
                        name="perBonos"
                        error={error.perBonos}
                        onChange={(e) => handleChange(e)}
                      /> */}
                          <TextField
                            type="number"
                            className={clases.formD}
                            size="small"
                            id="perRemuneracion"
                            label="Remuneración"
                            value={perRemuneracion}
                            name="perRemuneracion"
                            error={error.perRemuneracion}
                            onChange={(e) => handleChange(e)}
                          />
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="perLugarTrabajo"
                            label="Lugar de trabajo"
                            value={perLugarTrabajo}
                            name="perLugarTrabajo"
                            error={error.perLugarTrabajo}
                            onChange={(e) => handleChange(e)}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {lugaresTrabajoData.map((item) => {
                              return (
                                <MenuItem
                                  key={item.lugId}
                                  value={item.lugNombre}
                                >
                                  {item.lugNombre}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                          {/* <TextField
                         
                        className={clases.formD}
                        size="small"
                        id="perTurno"
                        label="Turno"
                        value={perTurno}
                        name="perTurno"
                        error={error.perTurno}
                        onChange={(e) => handleChange(e)}
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ displayEmpty: true }}
                      >
                        <MenuItem value="">Seleccione.</MenuItem>
                        {["DIA", "NOCHE"].map((item) => {
                          return <MenuItem value={item}>{item} </MenuItem>;
                        })}
                      </TextField> */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="perAreaDepartamento"
                            label="Area / Dept"
                            value={perAreaDepartamento}
                            name="perAreaDepartamento"
                            error={error.perAreaDepartamento}
                            onChange={(e) => handleChange(e)}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                            disabled={estadoRequest === "SUPERVISORES"}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>

                            {estadoRequest === "SUPERVISORES" ? (
                              <MenuItem value="OPERATIVO-S">
                                OPERATIVO-S
                              </MenuItem>
                            ) : null}
                            {estadoRequest === "GUARDIAS"
                              ? [
                                  "OPERATIVO-G",
                                  "SACA FRANCO",
                                  "SACA FRANCO G",
                                ].map((item) => {
                                  return (
                                    <MenuItem value={item}>{item}</MenuItem>
                                  );
                                })
                              : [
                                  "LOGISTICA",
                                  "ADMINISTRACION",
                                  "GERENTE",
                                  "ASISTENTE DE GERENCIA",
                                  "JEFE DE OPERACIONES",
                                  "COMPRAS PUBLICAS",
                                  "TALENTO HUMANO",
                                  "CONTADOR",
                                  "SECRETARIA",
                                  "SERVICIOS VARIOS",
                                  "OTROS",
                                ].map((item) => {
                                  return (
                                    <MenuItem value={item}>{item}</MenuItem>
                                  );
                                })}
                          </TextField>
                          <TextField
                            disabled
                            className={clases.formD}
                            size="small"
                            id="perCargo"
                            label="Cargo"
                            value={perCargo}
                            name="perCargo"
                            error={error.perCargo}
                            onChange={(e) => handleChange(e)}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {["ADMINISTRACION", "SUPERVISOR", "GUARDIA"].map(
                              (item) => {
                                return (
                                  <MenuItem value={item}>{item} </MenuItem>
                                );
                              }
                            )}
                          </TextField>
                          {/* <TextField
                     
                    className={clases.formD}
                    size="small"
                    id="perPuestoServicio"
                    label="Puesto de Servicio"
                    value={perPuestoServicio}
                    name="perPuestoServicio"
                    error={error.perPuestoServicio}
                    onChange={(e) => handleChange(e)}
                  /> */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="perTipoContrato"
                            label="Tipo de contrato"
                            value={perTipoContrato}
                            name="perTipoContrato"
                            error={error.perTipoContrato}
                            onChange={(e) => handleChange(e)}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {[
                              "TEMPORAL",
                              "PERMANENTE",
                              "GIRO DEL NEGOCIO",
                              "EMERGENTE",
                            ].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="perTipoCuenta"
                            label="Tipo de cuenta"
                            value={perTipoCuenta}
                            name="perTipoCuenta"
                            error={error.perTipoCuenta}
                            onChange={(e) => handleChange(e)}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {["AHORRO", "CORRIENTE"].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                          {/* <TextField
                     
                    className={clases.formD}
                    size="small"
                    id="perLicencias"
                    label="Licencias"
                    value={perLicencias}
                    name="perLicencias"
                    error={error.perLicencias}
                    onChange={(e) => handleChange(e)}
                  />  */}
                          <FormControl className={clases.formD}>
                            <InputLabel id="demo-mutiple-chip-label">
                              Licencia
                            </InputLabel>
                            <Select
                              inputProps={{}}
                              error={error.perLicencias}
                              labelId="demo-mutiple-chip-label"
                              id="demo-mutiple-chip"
                              multiple
                              name="perLicencias"
                              value={perLicencias}
                              onChange={(e) => {
                                setitem({
                                  ...item,
                                  [e.target.name]: e.target.value,
                                });
                              }}
                              input={<Input id="select-multiple-chip" />}
                              MenuProps={MenuProps}
                              renderValue={(selected) => (
                                <div className={clases.chips}>
                                  {selected.map((value, index) => {
                                    return (
                                      <Chip
                                        key={index}
                                        label={value}
                                        style={{
                                          margin: "0rem",
                                          padding: "0px",
                                        }}
                                      />
                                    );
                                  })}
                                </div>
                              )}
                            >
                              {["A", "B", "C", "D", "NINGUNA"].map(
                                (row, index) => (
                                  <MenuItem
                                    key={index}
                                    value={row}
                                    style={getStylesEspecialidades(
                                      row,
                                      perLicencias,
                                      theme
                                    )}
                                  >
                                    {row}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="perBancoRemuneracion"
                            label="Banco Remuneración"
                            value={perBancoRemuneracion}
                            name="perBancoRemuneracion"
                            error={error.perBancoRemuneracion}
                            onChange={(e) => handleChange(e)}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {bancos.map((item) => {
                              return (
                                <MenuItem
                                  key={item.novId}
                                  value={item.banNombre}
                                >
                                  {item.banNombre}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                          <TextField
                            type="number"
                            className={clases.formD}
                            size="small"
                            id="perCtaBancaria"
                            label="N. Cuenta Bancaria"
                            value={perCtaBancaria}
                            name="perCtaBancaria"
                            error={error.perCtaBancaria}
                            onChange={(e) => handleChange(e)}
                          />
                          <FormControl className={clases.formD}>
                            <Autocomplete
                              disableClearable={true}
                              options={clientesDataLaborales.map((item) => {
                                return (
                                  item.cliRazonSocial +
                                  "/+/" +
                                  item.cliDocNumero +
                                  "/+/" +
                                  item.cliId
                                );
                              })}
                              getOptionLabel={(option) => {
                                return option
                                  .replace("/+/", " ")
                                  .split("/+/")[0];
                              }}
                              id="controlled-demo"
                              value={
                                (
                                  perNombreCliente +
                                  " " +
                                  perDocuCliente
                                ).trim() === ""
                                  ? ""
                                  : (
                                      perNombreCliente +
                                      " " +
                                      perDocuCliente
                                    ).trim()
                              }
                              paperprops
                              onChange={async (event, newValue) => {
                                if (newValue) {
                                  const options = newValue.split("/+/");

                                  setitem({
                                    ...item,
                                    perNombreCliente: options[0],
                                    perDocuCliente: options[1],
                                    perIdCliente: options[2],
                                  });
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  inputProps={{}}
                                  {...params}
                                  size="small"
                                  required
                                  fullWidth
                                  label="Nómina de pago"
                                  error={error.perDocuCliente}
                                  onChange={async (e) => {
                                    if (e.target.value === "") {
                                      return mostrarAlerta(
                                        "Ingrese datos",
                                        "warning"
                                      );
                                    }
                                    try {
                                      if (e.target.value.length < 3) {
                                        return;
                                      }
                                      const res = await clienteAxios.get(
                                        `/clientes/filtrobusqueda/0?search=${e.target.value}`
                                      );

                                      setclientesDataLaborales(res.data.data);
                                    } catch (error) {
                                      setitem({
                                        ...item,
                                      });
                                      return mostrarAlerta(
                                        "No hay clientes",
                                        "error"
                                      );
                                    }
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                          <FormControl className={clases.formD}>
                            <Autocomplete
                              disableClearable={true}
                              options={clientesDataLaborales.map((item) => {
                                return (
                                  item.cliRazonSocial +
                                  "/+/" +
                                  item.cliDocNumero +
                                  "/+/" +
                                  item.cliId
                                );
                              })}
                              getOptionLabel={(option) => {
                                return option
                                  .replace("/+/", " ")
                                  .split("/+/")[0];
                              }}
                              id="controlled-demo"
                              value={
                                (
                                  perNombreClienteGop +
                                  " " +
                                  perDocuClienteGop
                                ).trim() === ""
                                  ? ""
                                  : (
                                      perNombreClienteGop +
                                      " " +
                                      perDocuClienteGop
                                    ).trim()
                              }
                              paperprops
                              onChange={async (event, newValue) => {
                                if (newValue) {
                                  const options = newValue.split("/+/");

                                  setitem({
                                    ...item,
                                    perNombreClienteGop: options[0],
                                    perDocuClienteGop: options[1],
                                    perIdClienteGop: options[2],
                                  });
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  inputProps={{}}
                                  {...params}
                                  size="small"
                                  required
                                  fullWidth
                                  label="Presentar en GOP"
                                  error={error.perDocuClienteGop}
                                  onChange={async (e) => {
                                    if (e.target.value === "") {
                                      return mostrarAlerta(
                                        "Ingrese datos",
                                        "warning"
                                      );
                                    }
                                    try {
                                      if (e.target.value.length < 3) {
                                        return;
                                      }
                                      const res = await clienteAxios.get(
                                        `/clientes/filtrobusqueda/0?search=${e.target.value}`
                                      );

                                      setclientesDataLaborales(res.data.data);
                                    } catch (error) {
                                      setitem({
                                        ...item,
                                      });
                                      return mostrarAlerta(
                                        "No hay clientes",
                                        "error"
                                      );
                                    }
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </FormControl>{" "}
                          <TextField
                            className={clases.formD}
                            size="small"
                            label="Referencia"
                            value={perReferencia}
                            name="perReferencia"
                            error={error.perReferencia}
                            onChange={(e) => handleChange(e)}
                          />
                          <TextField
                            className={clases.formAllW}
                            size="small"
                            id="perObservacion"
                            label="Notas"
                            value={perObservacion}
                            name="perObservacion"
                            multiline
                            rows={3}
                            variant="outlined"
                            error={error.perObservacion}
                            onChange={(e) => handleChange(e)}
                          />
                        </>
                      ) : null}
                    </>
                    <>
                      {TurnosLaborales ? (
                        <Turnos
                          estadoRequest={estadoRequest}
                          item={item}
                          setitem={setitem}
                          error={error}
                        />
                      ) : null}
                    </>
                    {VinculacionSeccion && (
                      <>
                        <Vinculacion
                          id={item.perId}
                          estadoRequest={estadoRequest}
                        />
                      </>
                    )}
                    <>
                      {OtrosLaborales ? (
                        <>
                          <FormControl className={clases.formAllW}>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent={"space-between"}
                            >
                              <Typography
                                variant="subtitle1"
                                color={
                                  error.otrosDatosLaborales
                                    ? "error"
                                    : "primary"
                                }
                              >
                                Dato:
                                <IconButton
                                  aria-label=""
                                  size="small"
                                  onClick={async () => {
                                    const existeVacio = item.otrosDatosLaborales.some(
                                      (item) => {
                                        if (item.tipo === "REFERENCIA") {
                                          return (
                                            item.tipo === "" ||
                                            item.nombre === "" ||
                                            item.telefono === ""
                                          );
                                        } else if (
                                          item.tipo === "INSTRUCCION"
                                        ) {
                                          return (
                                            item.tipo === "" ||
                                            item.nombre === "" ||
                                            item.nombreInstitucion === "" ||
                                            item.desde === "" ||
                                            item.hasta === ""
                                          );
                                        } else if (
                                          item.tipo === "EXPERIENCIA"
                                        ) {
                                          return (
                                            item.tipo === "" ||
                                            item.nombre === "" ||
                                            item.desde === "" ||
                                            item.hasta === ""
                                          );
                                        } else {
                                          return item.tipo === "";
                                        }
                                      }
                                    );

                                    if (existeVacio) {
                                      return mostrarAlerta(
                                        "Complete todos los campos.",
                                        "error"
                                      );
                                    }
                                    setitem({
                                      ...item,
                                      otrosDatosLaborales: [
                                        ...otrosDatosLaborales,
                                        {
                                          tipo: "",
                                          nombre: "",
                                          nombreInstitucion: "",
                                          desde: "",
                                          hasta: "",
                                          telefono: "",
                                          orden: 0,
                                        },
                                      ],
                                    });
                                  }}
                                >
                                  <AddCircle
                                    fontSize="small"
                                    color="secondary"
                                  />
                                </IconButton>
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                color={"secondary"}
                              >
                                T. Experiencia:
                                <Typography
                                  variant="subtitle1"
                                  component={"span"}
                                  color={"primary"}
                                >
                                  {String(SumaTExperiencia()).split(".")[0]}
                                  Años {" - "}
                                  {String(SumaTExperiencia()).split(".")[1]
                                    ? (
                                        String(SumaTExperiencia())
                                          .replace(".", "/0.")
                                          .split("/")[1] * 12
                                      ).toFixed()
                                    : "0 "}
                                  Meses
                                </Typography>
                              </Typography>
                            </Box>
                          </FormControl>

                          {otrosDatosLaborales
                            .sort((a, b) => a.orden - b.orden)
                            .map((dato, index) => {
                              return (
                                <>
                                  <Box
                                    display={"flex"}
                                    justifyContent="space-between"
                                    className={clases.formAllW}
                                    key={index}
                                  >
                                    <Typography
                                      key={index}
                                      variant="button"
                                      color="primary"
                                      fontWeight={"700"}
                                    >
                                      {`Dato #${index + 1}`}
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          setitem({
                                            ...item,
                                            otrosDatosLaborales: otrosDatosLaborales.filter(
                                              (item, indexItem) => {
                                                return indexItem !== index;
                                              }
                                            ),
                                          });
                                        }}
                                      >
                                        <Delete
                                          color="error"
                                          fontSize="small"
                                        />
                                      </IconButton>
                                    </Typography>
                                  </Box>
                                  {/* DATO */}
                                  <TextField
                                    className={clases.form20PC}
                                    InputProps={{
                                      style: {
                                        padding: "0",
                                        fontSize: ".9rem",
                                        fontWeight: "700",
                                        color:
                                          dato.tipo === "INSTRUCCION"
                                            ? "red"
                                            : dato.tipo === "REFERENCIA"
                                            ? "blue"
                                            : dato.tipo === "EXPERIENCIA"
                                            ? "green"
                                            : "black",
                                      },
                                    }}
                                    size="small"
                                    label="Tipo"
                                    value={dato.tipo}
                                    onChange={(e) => {
                                      setitem({
                                        ...item,
                                        otrosDatosLaborales: otrosDatosLaborales.map(
                                          (item, indexItem) => {
                                            if (indexItem === index) {
                                              return {
                                                ...item,
                                                tipo: e.target.value,
                                                orden:
                                                  e.target.value ===
                                                  "INSTRUCCION"
                                                    ? 1
                                                    : e.target.value ===
                                                      "EXPERIENCIA"
                                                    ? 3
                                                    : e.target.value ===
                                                      "REFERENCIA"
                                                    ? 2
                                                    : 0,
                                              };
                                            } else {
                                              return item;
                                            }
                                          }
                                        ),
                                      });
                                    }}
                                    select
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{ displayEmpty: true }}
                                  >
                                    <MenuItem disabled value="">
                                      Seleccione.
                                    </MenuItem>
                                    {[
                                      {
                                        color: "red",
                                        value: "INSTRUCCION",
                                      },
                                      {
                                        color: "blue",
                                        value: "REFERENCIA",
                                      },
                                      {
                                        color: "green",
                                        value: "EXPERIENCIA",
                                      },
                                    ].map((item) => {
                                      return (
                                        <MenuItem
                                          style={{ color: item.color }}
                                          value={item.value}
                                        >
                                          {item.value}
                                        </MenuItem>
                                      );
                                    })}
                                  </TextField>

                                  {dato.tipo === "REFERENCIA" ? (
                                    <>
                                      <TextField
                                        disabled={dato.tipo === ""}
                                        className={clases.form20PC}
                                        InputProps={{
                                          style: {
                                            padding: "0",
                                            fontSize: ".9rem",
                                          },
                                        }}
                                        size="small"
                                        label="Nombre"
                                        value={dato.nombre}
                                        onChange={(e) => {
                                          setitem({
                                            ...item,
                                            otrosDatosLaborales: otrosDatosLaborales.map(
                                              (item, indexItem) => {
                                                if (indexItem === index) {
                                                  return {
                                                    ...item,
                                                    nombre: e.target.value,
                                                  };
                                                } else {
                                                  return item;
                                                }
                                              }
                                            ),
                                          });
                                        }}
                                      />
                                      <TextField
                                        disabled={dato.tipo === ""}
                                        className={clases.form20PC}
                                        InputProps={{
                                          style: {
                                            padding: "0",
                                            fontSize: ".9rem",
                                          },
                                        }}
                                        size="small"
                                        label="Teléfono"
                                        value={dato.telefono}
                                        onChange={(e) => {
                                          setitem({
                                            ...item,
                                            otrosDatosLaborales: otrosDatosLaborales.map(
                                              (item, indexItem) => {
                                                if (indexItem === index) {
                                                  return {
                                                    ...item,
                                                    telefono: e.target.value,
                                                  };
                                                } else {
                                                  return item;
                                                }
                                              }
                                            ),
                                          });
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      {dato.tipo === "INSTRUCCION" ? (
                                        <>
                                          <TextField
                                            disabled={dato.tipo === ""}
                                            className={clases.form20PC}
                                            InputProps={{
                                              style: {
                                                padding: "0",
                                                fontSize: ".9rem",
                                              },
                                            }}
                                            size="small"
                                            label="Nombre institución"
                                            value={dato.nombre}
                                            onChange={(e) => {
                                              setitem({
                                                ...item,
                                                otrosDatosLaborales: otrosDatosLaborales.map(
                                                  (item, indexItem) => {
                                                    if (indexItem === index) {
                                                      return {
                                                        ...item,
                                                        nombre: e.target.value,
                                                      };
                                                    } else {
                                                      return item;
                                                    }
                                                  }
                                                ),
                                              });
                                            }}
                                            select
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            SelectProps={{
                                              displayEmpty: true,
                                            }}
                                          >
                                            <MenuItem value="">
                                              Seleccione.
                                            </MenuItem>
                                            {[
                                              "PRIMARIA",
                                              "SECUNDARIA",
                                              "TITULO OBTENIDO",
                                              "SUPERIOR",
                                              "CAPACITACIÓN NIVEL",
                                              "REENTRENAMIENTO",
                                              "SUPERVISOR",
                                              "OTROS",
                                            ].map((item) => {
                                              return (
                                                <MenuItem value={item}>
                                                  {item}
                                                </MenuItem>
                                              );
                                            })}
                                          </TextField>
                                        </>
                                      ) : (
                                        <>
                                          <TextField
                                            disabled={dato.tipo === ""}
                                            className={clases.form20PC}
                                            InputProps={{
                                              style: {
                                                padding: "0",
                                                fontSize: ".9rem",
                                              },
                                            }}
                                            size="small"
                                            label="Nombre"
                                            value={dato.nombre}
                                            onChange={(e) => {
                                              setitem({
                                                ...item,
                                                otrosDatosLaborales: otrosDatosLaborales.map(
                                                  (item, indexItem) => {
                                                    if (indexItem === index) {
                                                      return {
                                                        ...item,
                                                        nombre: e.target.value,
                                                      };
                                                    } else {
                                                      return item;
                                                    }
                                                  }
                                                ),
                                              });
                                            }}
                                          />
                                        </>
                                      )}

                                      {dato.tipo === "INSTRUCCION" ? (
                                        <TextField
                                          disabled={dato.tipo === ""}
                                          className={clases.form20PC}
                                          InputProps={{
                                            style: {
                                              padding: "0",
                                              fontSize: ".9rem",
                                            },
                                          }}
                                          size="small"
                                          label="Institución"
                                          value={dato.nombreInstitucion}
                                          onChange={(e) => {
                                            setitem({
                                              ...item,
                                              otrosDatosLaborales: otrosDatosLaborales.map(
                                                (item, indexItem) => {
                                                  if (indexItem === index) {
                                                    return {
                                                      ...item,
                                                      nombreInstitucion:
                                                        e.target.value,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                            });
                                          }}
                                        />
                                      ) : null}
                                      {navegadorCompatible ? (
                                        <TextField
                                          disabled={dato.tipo === ""}
                                          type="month"
                                          className={clases.form20PC}
                                          InputProps={{
                                            style: {
                                              padding: "0",
                                              fontSize: ".9rem",
                                            },
                                          }}
                                          size="small"
                                          label="Desde"
                                          value={dato.desde}
                                          InputLabelProps={{ shrink: true }}
                                          onChange={(e) => {
                                            setitem({
                                              ...item,
                                              otrosDatosLaborales: otrosDatosLaborales.map(
                                                (item, indexItem) => {
                                                  if (indexItem === index) {
                                                    return {
                                                      ...item,
                                                      desde: e.target.value,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                            });
                                          }}
                                        />
                                      ) : (
                                        <TextField
                                          disabled={dato.tipo === ""}
                                          type="month"
                                          className={clases.form20PC}
                                          InputProps={{
                                            style: {
                                              padding: "0",
                                              fontSize: ".9rem",
                                            },
                                          }}
                                          size="small"
                                          label="Desde"
                                          value={dato.desde}
                                          InputLabelProps={{ shrink: true }}
                                          onChange={(e) => {
                                            setitem({
                                              ...item,
                                              otrosDatosLaborales: otrosDatosLaborales.map(
                                                (item, indexItem) => {
                                                  if (indexItem === index) {
                                                    return {
                                                      ...item,
                                                      desde: e.target.value,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                            });
                                          }}
                                          select
                                          SelectProps={{
                                            displayEmpty: true,
                                          }}
                                        >
                                          <MenuItem value="" disabled>
                                            Seleccione.
                                          </MenuItem>
                                          {getMonths(false, 30, 0).map(
                                            (item) => {
                                              return (
                                                <MenuItem value={item}>
                                                  {item}{" "}
                                                </MenuItem>
                                              );
                                            }
                                          )}
                                        </TextField>
                                      )}
                                      {navegadorCompatible ? (
                                        <TextField
                                          disabled={dato.tipo === ""}
                                          type="month"
                                          className={clases.form20PC}
                                          InputProps={{
                                            style: {
                                              padding: "0",
                                              fontSize: ".9rem",
                                            },
                                          }}
                                          size="small"
                                          label="Hasta"
                                          value={dato.hasta}
                                          InputLabelProps={{ shrink: true }}
                                          onChange={(e) => {
                                            setitem({
                                              ...item,
                                              otrosDatosLaborales: otrosDatosLaborales.map(
                                                (item, indexItem) => {
                                                  if (indexItem === index) {
                                                    return {
                                                      ...item,
                                                      hasta: e.target.value,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                            });
                                          }}
                                        />
                                      ) : (
                                        <TextField
                                          disabled={dato.tipo === ""}
                                          type="month"
                                          className={clases.form20PC}
                                          InputProps={{
                                            style: {
                                              padding: "0",
                                              fontSize: ".9rem",
                                            },
                                          }}
                                          size="small"
                                          label="Hasta"
                                          value={dato.hasta}
                                          InputLabelProps={{ shrink: true }}
                                          onChange={(e) => {
                                            setitem({
                                              ...item,
                                              otrosDatosLaborales: otrosDatosLaborales.map(
                                                (item, indexItem) => {
                                                  if (indexItem === index) {
                                                    return {
                                                      ...item,
                                                      hasta: e.target.value,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                            });
                                          }}
                                          select
                                          SelectProps={{
                                            displayEmpty: true,
                                          }}
                                        >
                                          <MenuItem value="" disabled>
                                            Seleccione.
                                          </MenuItem>
                                          {getMonths(false, 30, 0).map(
                                            (item) => {
                                              return (
                                                <MenuItem value={item}>
                                                  {item}{" "}
                                                </MenuItem>
                                              );
                                            }
                                          )}
                                        </TextField>
                                      )}
                                    </>
                                  )}
                                  {/* DELETE */}
                                </>
                              );
                            })}
                        </>
                      ) : null}
                    </>
                  </>
                ) : null}

                {Bonos ? (
                  <>
                    <>
                      <Paper sx={{ width: "100%" }}>
                        <ToolBarTable
                          ocultarPaginacion={true}
                          botonesIzquierdos={[
                            {
                              tooltip: "Bonos",
                              texto: "Bonos",
                              funcion: () => {
                                setagregandoBodega(true);
                              },
                              disabled: perBonos.length === 1,
                              Icon: AddCircle,
                              color: "primary",
                              id: 1,
                              ocultar: false,
                              tipo: "boton",
                              variante: "contained",
                              size: "medium",
                              sx: {},
                            },
                          ]}
                          botonesDerechos={[]}
                        />
                        <TableContainer>
                          <Table
                            stickyHeader
                            aria-label="sticky table"
                            id="tabla"
                            size="small"
                          >
                            <caption
                              style={{
                                padding: "0px",
                                paddingRight: "1rem",
                                paddingLeft: "1rem",
                              }}
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <TablePagination
                                  labelRowsPerPage="Filas por pagina"
                                  rowsPerPageOptions={[10, 20, 50, 100]}
                                  component="div"
                                  count={perBonos.length}
                                  rowsPerPage={rowsPerPage}
                                  nextIconButtonText="Siguiente página"
                                  page={page}
                                  onPageChange={handleChangePage}
                                  onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                              </Box>
                            </caption>

                            <TableHead>
                              <TablaCabecera
                                input={{ id: "" }}
                                habilitarOrdenar={false}
                                columns={[
                                  {
                                    id: "ingreso",
                                    align: "center",
                                    minWidth: 50,
                                    tipo: "ingreso",
                                    label: "Ingreso",
                                  },
                                  {
                                    id: "valor",
                                    align: "center",
                                    minWidth: 50,
                                    tipo: "valor",
                                    label: "Valor",
                                  },
                                ]}
                              />
                            </TableHead>

                            <TableBody>
                              {agregandoBodega ? (
                                <RowAgregarBono
                                  setagregar={setagregandoBodega}
                                  setitem={setitem}
                                  item={item}
                                />
                              ) : null}
                              {perBonos.map((row, index) => {
                                return (
                                  <RowBono
                                    deshabilitarbotones={false}
                                    item={item}
                                    setitem={setitem}
                                    row={row}
                                    index={index}
                                    key={index}
                                  />
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </>
                    <Divider sx={{ width: "100%" }}>DESCUENTOS</Divider>
                    <>
                      <>
                        <Paper sx={{ width: "100%" }}>
                          <ToolBarTable
                            ocultarPaginacion={true}
                            botonesIzquierdos={[
                              {
                                tooltip: "Descuentos",
                                texto: "Descuentos",
                                funcion: () => {
                                  setagregandoDescuento(true);
                                },
                                disabled: descuentos.length === 1,
                                Icon: AddCircle,
                                color: "primary",
                                id: 1,
                                ocultar: false,
                                tipo: "boton",
                                variante: "contained",
                                size: "medium",
                                sx: {},
                              },
                            ]}
                            botonesDerechos={[]}
                          />
                          <TableContainer>
                            <Table
                              stickyHeader
                              aria-label="sticky table"
                              id="tabla"
                              size="small"
                            >
                              <caption
                                style={{
                                  padding: "0px",
                                  paddingRight: "1rem",
                                  paddingLeft: "1rem",
                                }}
                              >
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <TablePagination
                                    labelRowsPerPage="Filas por pagina"
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                    component="div"
                                    count={descuentos.length}
                                    rowsPerPage={rowsPerPage}
                                    nextIconButtonText="Siguiente página"
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={
                                      handleChangeRowsPerPage
                                    }
                                  />
                                </Box>
                              </caption>

                              <TableHead>
                                <TablaCabecera
                                  input={{ id: "" }}
                                  habilitarOrdenar={false}
                                  columns={[
                                    {
                                      id: "Egreso",
                                      align: "center",
                                      minWidth: 50,
                                      tipo: "Egreso",
                                      label: "Egreso",
                                    },
                                    {
                                      id: "valor",
                                      align: "center",
                                      minWidth: 50,
                                      tipo: "valor",
                                      label: "Valor",
                                    },
                                  ]}
                                />
                              </TableHead>

                              <TableBody>
                                {agregandoDescuento ? (
                                  <RowAgregarDescuento
                                    setagregar={setagregandoDescuento}
                                    setitem={setitem}
                                    item={item}
                                  />
                                ) : null}
                                {descuentos.map((row, index) => {
                                  return (
                                    <RowDescuento
                                      deshabilitarbotones={false}
                                      item={item}
                                      setitem={setitem}
                                      row={row}
                                      index={index}
                                      key={index}
                                    />
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      </>
                    </>
                  </>
                ) : null}

                {PuestoServicio ? (
                  <>
                    <Paper className={clases.root}>
                      <ToolBarTable
                        titulo={"Puesto Servicio"}
                        soloTitulo={true}
                        botonAdd={true}
                        deshabilitarbotonAdd={false}
                        funcionAdd={() => {
                          setagregandoBodega(true);
                        }}
                      />
                      <TableContainer className={clases.container}>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          id="tabla"
                          size="small"
                        >
                          <caption
                            style={{
                              padding: "0px",
                              paddingRight: "1rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <TablePagination
                                labelRowsPerPage="Filas por pagina"
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                component="div"
                                count={perPuestoServicio.length}
                                rowsPerPage={rowsPerPage}
                                nextIconButtonText="Siguiente página"
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                            </Box>
                          </caption>

                          <TableHead>
                            <TablaCabecera
                              input={{ id: "" }}
                              habilitarOrdenar={false}
                              columns={[
                                {
                                  id: "ruccliente",
                                  align: "center",
                                  minWidth: 50,
                                  tipo: "ruccliente",
                                  label: "ruccliente",
                                },
                                {
                                  id: "razonsocial",
                                  align: "center",
                                  minWidth: 50,
                                  tipo: "razonsocial",
                                  label: "razonsocial",
                                },
                                {
                                  id: "ubicacion",
                                  align: "center",
                                  minWidth: 50,
                                  tipo: "ubicacion",
                                  label: "ubicacion",
                                },
                                {
                                  id: "puesto",
                                  align: "center",
                                  minWidth: 50,
                                  tipo: "puesto",
                                  label: "puesto",
                                },
                              ]}
                            />
                          </TableHead>

                          <TableBody>
                            {agregandoBodega ? (
                              <RowAgregarPuesto
                                setagregar={setagregandoBodega}
                                setitem={setitem}
                                item={item}
                              />
                            ) : null}
                            {perPuestoServicio.map((row, index) => {
                              return (
                                <RowPuesto
                                  deshabilitarbotones={false}
                                  item={item}
                                  setitem={setitem}
                                  row={row}
                                  index={index}
                                  key={index}
                                />
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </>
                ) : null}

                {HorarioIncapaciad ? (
                  <>
                    {/* DOCUMENTOS */}
                    {documentosArray.map(
                      (documentoItem, index, arraydocumentoItem) => {
                        // extension: "PDF"
                        // fecExpira: "NO"
                        // nombre: "Fotos Instalacion"
                        // obligatorio: "SI"
                        // propiedad: "fotosinstalacion"
                        return (
                          <Box
                            key={index}
                            display="flex"
                            flexWrap="wrap"
                            alignItems="flex-end"
                            borderRadius="0px 50px 50px 0px"
                            p={1}
                            className={clases.formD}
                          >
                            <input
                              className={clases.ocultar}
                              type="file"
                              name="myImage"
                              onChange={(e) => {
                                onChangeDocumento(e, documentoItem);
                              }}
                              ref={objetoReferencias[documentoItem.propiedad]}
                            />
                            <TextField
                              size="small"
                              className={clases.formDDoc}
                              error={documentoItem.error}
                              inputProps={{}}
                              InputLabelProps={{
                                sx: { fontSize: ".8rem" },
                                shrink:
                                  documentosObject[documentoItem.propiedad] !==
                                    "" ||
                                  item.documentos[documentoItem.propiedad] !==
                                    ""
                                    ? true
                                    : false,
                              }}
                              InputProps={{
                                endAdornment: (
                                  <Box
                                    htmlFor="contained-button-documento"
                                    fullWidth
                                  >
                                    <InputAdornment>
                                      <Tooltip title={`Revisado`} arrow>
                                        <Box>
                                          <Switch
                                            color="success"
                                            checked={
                                              item.documentos[
                                                documentoItem.propiedad +
                                                  "_Tiene"
                                              ] === "SI"
                                            }
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                setitem({
                                                  ...item,
                                                  documentos: {
                                                    ...item.documentos,
                                                    [documentoItem.propiedad +
                                                    "_Tiene"]: "SI",
                                                  },
                                                });
                                              } else {
                                                setitem({
                                                  ...item,
                                                  documentos: {
                                                    ...item.documentos,
                                                    [documentoItem.propiedad +
                                                    "_Tiene"]: "NO",
                                                  },
                                                });
                                              }
                                            }}
                                          />
                                        </Box>
                                      </Tooltip>
                                      <Tooltip
                                        title={`Subir Archivo: ${documentoItem.nombre}`}
                                        arrow
                                      >
                                        <Box>
                                          <IconButton
                                            onClick={() =>
                                              focusInputDocumento(documentoItem)
                                            }
                                            aria-label=""
                                            size="small"
                                          >
                                            <CloudUpload
                                              color="secondary"
                                              fontSize="small"
                                            />
                                          </IconButton>
                                        </Box>
                                      </Tooltip>
                                      {item.documentos[
                                        documentoItem.propiedad
                                      ] === "" ? null : (
                                        <Tooltip
                                          title={`Descargar Archivo: ${documentoItem.nombre}`}
                                          arrow
                                        >
                                          <Box>
                                            <IconButton
                                              color="primary"
                                              onClick={async () => {
                                                window.open(
                                                  item.documentos[
                                                    documentoItem.propiedad
                                                  ]
                                                );
                                              }}
                                              aria-label=""
                                            >
                                              <Download />
                                            </IconButton>
                                          </Box>
                                        </Tooltip>
                                      )}
                                    </InputAdornment>
                                  </Box>
                                ),
                              }}
                              fullWidth
                              id={documentoItem.propiedad}
                              label={`${documentoItem.nombre}`}
                              value={
                                item.documentos[documentoItem.propiedad] &&
                                !documentosObject[documentoItem.propiedad].name
                                  ? item.documentos[
                                      documentoItem.propiedad
                                    ].split("/")[
                                      item.documentos[
                                        documentoItem.propiedad
                                      ].split("/").length - 1
                                    ]
                                  : documentosObject[documentoItem.propiedad]
                                      .name
                                  ? documentosObject[documentoItem.propiedad]
                                      .name
                                  : ""
                              }
                              name={documentoItem.propiedad}
                            />

                            {documentoItem.fecExpira === "SI" ? (
                              <TextField
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                  min: FechaExacta,
                                }}
                                className={clases.formExp}
                                size="small"
                                id="perFecExpdatospersonales"
                                label="Expira"
                                value={
                                  item.documentos[
                                    documentoItem.propiedad + "expira"
                                  ]
                                }
                                name="perFecExpdatospersonales"
                                error={error.perFecExpdatospersonales}
                                onChange={(e) =>
                                  setitem({
                                    ...item,
                                    documentos: {
                                      ...documentos,
                                      [documentoItem.propiedad + "expira"]: e
                                        .target.value,
                                    },
                                  })
                                }
                              />
                            ) : null}
                          </Box>
                        );
                      }
                    )}
                  </>
                ) : null}
                {FotografiasSeccion && (
                  <>
                    <Box
                      display="flex"
                      justifyContent="center"
                      width="100%"
                      flexDirection="column"
                      alignItems="center"
                      className={clases.formAllW}
                    >
                      <input
                        id="contained-button-fileFoto"
                        className={clases.ocultar}
                        type="file"
                        name="myImage"
                        onChange={onChangeFileFoto}
                      />

                      {/* //si un archivo exoste  */}
                      {archivoFoto ? (
                        <Box
                          display="flex"
                          flexDirection="column"
                          className={clases.center}
                        >
                          <label htmlFor="contained-button-fileFoto">
                            <Avatar
                              htmlFor="contained-button-fileFoto"
                              alt="Remy Sharp"
                              src={imagenMostradaFoto}
                              className={clases.avatar}
                            />
                          </label>
                          <Typography
                            variant="body1"
                            color="initial"
                            align="center"
                          >
                            Foto Perfil - {archivoFoto.name}
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          display="flex"
                          flexDirection="column"
                          className={clases.center}
                        >
                          <label htmlFor="contained-button-fileFoto">
                            <Avatar
                              htmlFor="contained-button-fileFoto"
                              alt="Logo"
                              src={perFoto}
                              className={clases.avatar}
                            />
                          </label>
                          <Typography
                            variant="body1"
                            color="initial"
                            align="center"
                          >
                            No se ha seleccionado ningun archivo (Foto Perfil)
                          </Typography>
                        </Box>
                      )}
                    </Box>{" "}
                    <Dropzone
                      textoDropzone="Arrastre aqui una imagen o foto de las INSTALACIONES"
                      imagenes={imagenes}
                      setimagenes={(e) => {
                        setmodificoImagenes(true);
                        setimagenes(e);
                      }}
                      fotosItem={perFotografiasInstalaciones}
                    />
                  </>
                )}
              </Box>
              {cargando && <LinearProgress />}
              {/* <Divider /> */}
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                {" "}
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box
                    component={"img"}
                    sx={{ width: "85px", cursor: "pointer" }}
                    onClick={() => {
                      window.open(
                        "https://www.iess.gob.ec/empleador-web/pages/principal.jsf"
                      );
                    }}
                    src={iess}
                    alt="iess"
                  />
                  <Box
                    component={"img"}
                    sx={{ width: "85px", cursor: "pointer" }}
                    onClick={() => {
                      window.open(
                        "https://sicosep.ministeriodelinterior.gob.ec/empresas/mdi/mdig/mdi1"
                      );
                    }}
                    src={sicosep}
                    alt="sicosep"
                  />
                  <Box
                    component={"img"}
                    sx={{ width: "85px", cursor: "pointer" }}
                    onClick={() => {
                      window.open(
                        "https://sut.trabajo.gob.ec/mrl/loginContratos.xhtml"
                      );
                    }}
                    src={sut}
                    alt="sut"
                  />
                </Box>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={submit() || cargando}
                    onClick={() => {
                      setopenConfirmDialog(true);
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalPersonas);

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import ToolBarTable from "../../../components/ToolBarTable";
import TablaCabecera from "../../../components/TablaCabecera";
import { useSimpleTable } from "../../../hooks/useSimpleTable";
import { AddCircle } from "@mui/icons-material";
import RowAgregarVestimenta from "./RowAgregarVestimenta";
import RowVestimenta from "./RowVestimenta";
import {
  columnsVestimenta,
  columnsArma,
  columnsMunicion,
  columnBienesClienteModal,
} from "../../data";
import { useTableStyles } from "../../../styles/styleTable";
import { useEffect } from "react";
import clienteAxios from "../../../../config/axios";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { TableContainer } from "@material-ui/core";
const reducer = (previousValue, currentValue) =>
  Number(previousValue) + Number(currentValue);
const TablaVestimenta = (props) => {
  const {
    setitem,
    item,
    bodegaData,
    editar,
    devolucion,
    vestimenta,
    armas,
    municion,
  } = props;
  const { mostrarAlerta } = useContext(AlertaContext);
  const { distri_productos: productosData } = item;
  const [cargando, setcargando] = useState(false);
  const distri_productos = useMemo(() => {
    return productosData.filter(
      (itemP) =>
        (municion && itemP.prodTipo === "MUNICIONES") ||
        (armas && itemP.prodTipo === "ARMAS") ||
        (vestimenta &&
          (itemP.prodTipo === "EQUIPOS" || itemP.prodTipo === "VESTIMENTAS"))
    );
  }, [vestimenta, armas, municion, productosData]);
  const totales = useMemo(() => {
    if (distri_productos.length === 0) {
      return {
        total: 0.0,
        cantidad: 0,
      };
    }
    const resultado = distri_productos
      .map((row) => {
        return Number(row.prodPrecioUnitario) * row.cantidad;
      })
      .reduce(reducer);
    const cantidad = distri_productos
      .map((row) => {
        return row.cantidad;
      })
      .reduce(reducer);
    return { total: resultado.toFixed(2), cantidad };
  }, [distri_productos]);
  const {
    agregandoVestimenta,
    setagregandoVestimenta,
    pageVestimenta,
    // setPageVestimenta,
    rowsPerPageVestimenta,
    // setRowsPerPageVestimenta,
    handleChangePageVestimenta,
    handleChangeRowsPerPageVestimenta,
  } = useSimpleTable("Vestimenta");
  const classesRow = useTableStyles();
  const getProductosUpdated = async () => {
    try {
      setcargando(true);
      const res = await clienteAxios.get(
        `/distribuciones/distri_productos_byId/${item.distri_Id}`
      );

      setitem({
        ...item,
        distri_productos: res.data.distri_productos,
      });
      setcargando(false);
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
    }
  };
  useEffect(() => {
    if (editar) {
      getProductosUpdated();
    }
  }, [editar]);
  if (cargando) {
    return (
      <Typography variant="h5" fontWeight={"bold"} textAlign={"center"}>
        Cargando...
      </Typography>
    );
  }
  return (
    <Paper sx={{ width: "100%" }}>
      <ToolBarTable
        // distri_Tipo={item.distri_Tipo}
        count={distri_productos.length}
        rowsPerPage={rowsPerPageVestimenta}
        page={pageVestimenta}
        handleChangePage={handleChangePageVestimenta}
        handleChangeRowsPerPage={handleChangeRowsPerPageVestimenta}
        ocultarPaginacion={false}
        simplePagination={true}
        botonesIzquierdos={[
          {
            tooltip: "Agregar",
            texto: "Agregar",
            funcion: () => {
              setagregandoVestimenta(true);
            },
            disabled: editar || devolucion,
            Icon: AddCircle,
            color: "primary",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
        botonesDerechos={[]}
      />

      <TableContainer>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TablaCabecera
              columns={
                editar
                  ? [
                      { id: "editar", label: "DEVUELTO", minWidth: 50 },
                      ...(item.distri_Tipo === "BIENES CLIENTE"
                        ? columnBienesClienteModal
                        : vestimenta
                        ? columnsVestimenta
                        : armas
                        ? columnsArma
                        : municion
                        ? columnsMunicion
                        : []),
                    ]
                  : [
                      ...(item.distri_Tipo === "BIENES CLIENTE"
                        ? columnBienesClienteModal
                        : vestimenta
                        ? columnsVestimenta
                        : armas
                        ? columnsArma
                        : municion
                        ? columnsMunicion
                        : []),
                    ]
              }
              habilitarOrdenar={false}
            />
          </TableHead>
          <TableBody component={"div"}>
            {agregandoVestimenta ? (
              <RowAgregarVestimenta
                distri_Tipo={item.distri_Tipo}
                bodegaData={bodegaData}
                agregar={agregandoVestimenta}
                setagregar={setagregandoVestimenta}
                setitem={setitem}
                item={item}
                vestimenta={vestimenta}
                armas={armas}
                municion={municion}
              />
            ) : null}
            {distri_productos
              .slice(
                pageVestimenta * rowsPerPageVestimenta,
                pageVestimenta * rowsPerPageVestimenta + rowsPerPageVestimenta
              )
              .map((row, index) => {
                return (
                  <RowVestimenta
                    bodegaData={bodegaData}
                    ver={editar}
                    devolucion={devolucion}
                    item={item}
                    setitem={setitem}
                    row={row}
                    index={index}
                    key={row.prod_distri_Id}
                    vestimenta={vestimenta}
                    armas={armas}
                    municion={municion}
                  />
                );
              })}
          </TableBody>
          <TableFooter>
            <TableRow>
              {item.distri_Tipo !== "BIENES CLIENTE" && (
                <TableCell
                  size="small"
                  align="center"
                  className={classesRow.celdaFooter}
                ></TableCell>
              )}
              <TableCell
                size="small"
                align="center"
                className={classesRow.celdaFooter}
              ></TableCell>
              <TableCell
                size="small"
                align="center"
                className={classesRow.celdaFooter}
              ></TableCell>
              <TableCell
                size="small"
                align="center"
                className={classesRow.celdaFooter}
              ></TableCell>
              <TableCell
                size="small"
                align="center"
                className={classesRow.celdaFooter}
              ></TableCell>
              {vestimenta && (
                <TableCell
                  size="small"
                  align="center"
                  className={classesRow.celdaFooter}
                ></TableCell>
              )}
              <TableCell
                size="small"
                align="center"
                className={classesRow.celdaFooter}
              >
                {totales.cantidad}
              </TableCell>
              {item.distri_Tipo !== "BIENES CLIENTE" && vestimenta && (
                <TableCell
                  size="small"
                  align="center"
                  className={classesRow.celdaFooter}
                ></TableCell>
              )}{" "}
              {(armas || vestimenta) && (
                <TableCell
                  size="small"
                  align="center"
                  className={classesRow.celdaFooter}
                ></TableCell>
              )}
              <TableCell
                size="small"
                align="center"
                className={classesRow.celdaFooter}
              >
                ${totales.total}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TablaVestimenta;
